import { Redirect, Route, Switch } from 'react-router';
import { LogoutPage } from './pages/LogoutPage';
import { JobsPage } from './pages/JobsPage';
import { TimesheetsPage } from './pages/TimesheetsPage';
import { BillsPage } from './pages/BillsPage';
import { AddDeveloperPage } from './pages/AddDeveloperPage';
import { JobPage } from './pages/JobPage';
import { MyDevsPage } from './pages/MyDevsPage';
import { BadgesPage } from './pages/BadgesPage';
import { ProfilePage } from './pages/ProfilePage';
import { PayoutMethodsPage } from './pages/PayoutMethodsPage';

export const Routing = () => {
  return (
    <Switch>
      <Route exact path="/">
        <Redirect to="/job-board" />
      </Route>
      <Route exact path="/interviews" component={BadgesPage} />
      <Route exact path="/bills" component={BillsPage} />
      <Route exact path="/bills/payouts" component={PayoutMethodsPage} />
      <Route exact path="/logout" component={LogoutPage} />
      <Route exact path="/job-board" component={JobsPage} />
      <Route exact path="/job-board/interested" render={() => <JobsPage interested={2} />} />
      <Route exact path="/job-board/not-interested" render={() => <JobsPage interested={3} />} />
      <Route exact path="/job-board/:uid/:developerUid" component={JobPage} />
      <Route exact path="/timesheets" component={TimesheetsPage} />
      <Route exact path="/my-devs" component={MyDevsPage} />
      <Route exact path="/add-developer" component={AddDeveloperPage} />
      <Route exact path="/profile/:section?" component={ProfilePage} />
    </Switch>
  );
};
