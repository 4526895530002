import { action, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import {
  defaultPayoutMethodErrors,
  IPayoutMethod,
  IPayoutMethodErrors,
  IServiceRedirect,
} from '../interfaces/payout-methods';
import paymentMethodsService from '../api/payout-methods';

export default class PayoutMethodsStore {
  root: RootStore;

  redirects: IServiceRedirect[] = [];

  errors: IPayoutMethodErrors = defaultPayoutMethodErrors;

  fetchingAvailablePayoutMethods: boolean = false;

  fetchingCurrentPayoutMethods: boolean = false;

  availablePayoutMethods: IPayoutMethod[] = [];

  currentPayoutMethods: IPayoutMethod[] = [];

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchAvailablePayoutMethods: action.bound,
      fetchCurrentPayoutMethods: action.bound,
    });
  }

  async fetchAvailablePayoutMethods() {
    this.fetchingAvailablePayoutMethods = true;
    this.errors.availablePayoutMethods = '';

    try {
      const res = await paymentMethodsService.getAvailablePayoutMethods();

      const serviceIds = res.payout_methods.map((service) => service.id);
      const redirectReqs = serviceIds.map((id) => paymentMethodsService.getRedirect(id));
      const redirectRes = await Promise.all(redirectReqs);

      runInAction(() => {
        this.availablePayoutMethods = res.payout_methods;
        this.fetchingAvailablePayoutMethods = false;
        this.redirects = redirectRes;
      });
    } catch (e: any) {
      runInAction(() => {
        this.availablePayoutMethods = [];
        this.fetchingAvailablePayoutMethods = false;
        this.errors.availablePayoutMethods = e?.message;
        this.redirects = [];
      });
    }
  }

  async fetchCurrentPayoutMethods() {
    this.fetchingCurrentPayoutMethods = true;
    this.errors.currentPayoutMethods = '';

    try {
      const res = await paymentMethodsService.getCurrentPayoutMethods();

      const serviceIds = res.payout_methods.map((service) => service.id);
      const redirectReqs = serviceIds.map((id) => paymentMethodsService.getRedirect(id));
      const redirectRes = await Promise.all(redirectReqs);

      runInAction(() => {
        this.currentPayoutMethods = res.payout_methods;
        this.fetchingCurrentPayoutMethods = false;
        this.redirects = redirectRes;
      });
    } catch (e: any) {
      runInAction(() => {
        this.currentPayoutMethods = [];
        this.fetchingCurrentPayoutMethods = false;
        this.errors.currentPayoutMethods = e?.message;
        this.redirects = [];
      });
    }
  }
}
