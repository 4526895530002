import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, InfoBox } from '@upstackhq/component-library';
import { ExperienceForm } from './Forms/ExperienceForm';
import { ExperienceInfoBox } from './InfoBoxes/ExperienceInfoBox';
import { IExperienceItem } from '../../interfaces/developers';

type props = {
  data?: IExperienceItem[];
  onComplete?: Function;
  onInvalid?: () => void;
  nextStep?: boolean;
  onUpdate?: Function;
};

export const ExperienceSection: FC<props> = observer((props) => {
  const { data, onComplete, nextStep, onUpdate } = props;
  const [newExperiences, setNewExperiences] = useState<{ key: number }[]>([]);
  const [experience, setExperience] = useState<IExperienceItem[]>([]);

  useEffect((): void => {
    if (data?.length) {
      setExperience(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextStep) {
      if (onComplete) {
        onComplete(experience);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  const onAddNewExperience = () => {
    setNewExperiences([
      ...newExperiences,
      { key: newExperiences.length ? newExperiences[newExperiences.length - 1].key + 1 : 1 },
    ]);
  };

  const onRemoveExperience = (index: number) => {
    setNewExperiences(newExperiences.filter(({ key: k }) => k !== index));
    setExperience(experience.filter((experience: IExperienceItem, i: number) => i !== index));
    onUpdate &&
      onUpdate(experience.filter((experience: IExperienceItem, i: number) => i !== index));
  };

  return (
    <div>
      {experience.length === 0 && (
        <InfoBox
          title="Enter experience"
          description="Please share developer's experience with previous companies and positions. Start by clicking on the add new experience button below, or Skip button to go to the next section."
          type="warning"
        />
      )}
      <div className="flex justify-end mb-3">
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewExperience}
        />
      </div>

      {experience.map((exp: IExperienceItem, index: number) => (
        <ExperienceInfoBox
          index={index}
          key={index}
          experience={exp}
          onRemove={onRemoveExperience}
          onEdit={(data: IExperienceItem, index: number) => {
            const updated = experience.map((experience: IExperienceItem, i: number) => {
              if (i === index) {
                return data;
              }
              return experience;
            });
            setExperience(updated);
            onUpdate && onUpdate(updated);
          }}
        />
      ))}

      {newExperiences.map(({ key }) => (
        <ExperienceForm
          key={key}
          onClose={() => setNewExperiences(newExperiences.filter(({ key: k }) => k !== key))}
          onComplete={(data: IExperienceItem) => {
            setNewExperiences(newExperiences.filter(({ key: k }) => k !== key));
            setExperience([...experience, data]);
            onUpdate && onUpdate([...experience, data]);
          }}
        />
      ))}
    </div>
  );
});
