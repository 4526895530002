import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import {
  IDevItem,
  IDevErrors,
  defaultNewDevErrors,
  IDevsPagination,
  defaultNewDevPagination,
  defaultGetNewDevsRequest,
  IGetDevsRequest,
  IUpdateAvailabilityRequest,
} from '../interfaces/mydevs';
import myDevsService from '../api/my-devs';
import { statusMap } from '../constants';

export default class myDevsStore {
  root: RootStore;

  fetchingNewDevs: boolean = false;

  fetchingWorkingDevs: boolean = false;

  fetchingAllDevs: boolean = false;

  errors: IDevErrors = defaultNewDevErrors;

  paginationNew: IDevsPagination = defaultNewDevPagination;

  paginationWorking: IDevsPagination = defaultNewDevPagination;

  newDevs: IDevItem[] = [];

  workingDevs: IDevItem[] = [];

  allDevs: IDevItem[] = [];

  selectedDev: IDevItem | null = null;

  availabiltyUpdatedDevs: string[] = [];

  showAll: boolean = true;

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchNewDevs: action.bound,
      fetchWorkingDevs: action.bound,
      fetchAllDevs: action.bound,
      selectableDevs: computed,
      setSelectedDev: action.bound,
      setShowAll: action.bound,
      updateAvailability: action.bound,
    });
  }

  get selectableDevs() {
    const { allDevs, showAll } = this;

    return allDevs
      .filter((dev) => (!showAll && dev.state === 1) || showAll)
      .map((dev) => ({
        value: dev.uid,
        label: dev.name,
        optionLabel: `${dev.name} (${
          statusMap.find((status) => status.value === dev.state)?.name
        })`,
      }));
  }

  async fetchAllDevs() {
    this.fetchingAllDevs = true;

    try {
      const res = await myDevsService.getSelectorDevs();
      runInAction(() => {
        this.allDevs = res.developers;
        this.fetchingAllDevs = false;
      });
    } catch (e) {
      runInAction(() => {
        this.fetchingAllDevs = false;
      });
    }
  }

  async fetchNewDevs(page: number = 1) {
    const payload: IGetDevsRequest = {
      ...defaultGetNewDevsRequest,
      ...{ page },
      hired: false,
    };
    const { currentPage } = this.paginationNew;

    this.fetchingNewDevs = true;
    this.paginationNew.currentPage = page;

    try {
      const res = await myDevsService.getDevs(payload);
      runInAction(() => {
        this.newDevs = res.developers;
        this.paginationNew.totalPages = res.pagination.pages;
        this.fetchingNewDevs = false;
        this.errors.fetchDevs = '';
      });
    } catch (e: any) {
      runInAction(() => {
        this.paginationNew.currentPage = currentPage;
        this.newDevs = [];
        this.fetchingWorkingDevs = false;
        this.errors.fetchDevs = e?.message;
      });
    }
  }

  async fetchWorkingDevs(page: number = 1) {
    const payload: IGetDevsRequest = {
      ...defaultGetNewDevsRequest,
      ...{ page },
      hired: true,
    };
    const { currentPage } = this.paginationWorking;

    this.fetchingWorkingDevs = true;
    this.paginationWorking.currentPage = page;

    try {
      const res = await myDevsService.getDevs(payload);
      runInAction(() => {
        this.workingDevs = res.developers;
        this.paginationWorking.totalPages = res.pagination.pages;
        this.fetchingWorkingDevs = false;
        this.errors.fetchDevs = '';
      });
    } catch (e: any) {
      runInAction(() => {
        this.paginationWorking.currentPage = currentPage;
        this.workingDevs = [];
        this.fetchingNewDevs = false;
        this.fetchingWorkingDevs = false;
        this.errors.fetchDevs = e?.message;
      });
    }
  }

  async updateAvailability(payload: IUpdateAvailabilityRequest, developerUid: string) {
    this.availabiltyUpdatedDevs = [...this.availabiltyUpdatedDevs, developerUid];

    try {
      const response = await myDevsService.updateDeveloperAvailability(developerUid, payload);
      runInAction(() => {
        this.newDevs = this.newDevs.map((dev) => {
          if (dev.uid === developerUid) {
            dev.available = payload.availability;
            dev.days_unavailable = payload.days_unavailable;
          }

          return dev;
        });
        this.availabiltyUpdatedDevs = this.availabiltyUpdatedDevs.filter(
          (devUid) => devUid !== developerUid
        );
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.availabiltyUpdatedDevs = this.availabiltyUpdatedDevs.filter(
          (devUid) => devUid !== developerUid
        );
      });
    }
  }

  setSelectedDev(uid: string) {
    this.selectedDev = this.allDevs.find((dev) => dev.uid === uid) || null;
  }

  setShowAll(showAll: boolean) {
    this.showAll = showAll;
  }
}
