import { action, makeAutoObservable, runInAction } from 'mobx';
import { v4 as uuid_v4 } from 'uuid';
import { RootStore } from './index';
import {
  defaultProfileData,
  IGetProfileDataResponse,
  IProfileExperienceItem,
  IProfileExperienceResponseItem,
  IRemoveEducationRequest,
  IRemoveExperienceRequest,
  IRemoveShowcaseRequest,
  IUpdateAboutSectionRequest,
  IUpdateEducationRequest,
  IUpdateExperiencePayload,
  IUpdateShowcaseRequest,
  IUpdateSkillsSectionRequest,
  IUpdateSocialSectionRequest,
  IUpdateWorkSectionRequest,
} from '../interfaces/profile';
import ProfileService from '../api/profile';
import UtilsService from '../api/utils';

export default class ProfileStore {
  root: RootStore;

  profileData = defaultProfileData;

  saving = {
    about: false,
    password: false,
    social: false,
    skills: false,
    experience: false,
    education: false,
    showcase: false,
    avatar: false,
    work: false,
  };

  loading = false;

  errorMessage = '';

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchProfileData: action.bound,
      updateAvatar: action.bound,
      updateAboutSection: action.bound,
      updateWorkSection: action.bound,
      updateSocialSection: action.bound,
      updateSkillsSection: action.bound,
      updateExperienceSection: action.bound,
      removeExperience: action.bound,
      updateEducationSection: action.bound,
      removeEducation: action.bound,
      updateShowcaseSection: action.bound,
      removeShowcase: action.bound,
      uploadShowcaseThumbnail: action.bound,
      resetErrorMessage: action.bound,
    });
  }

  resetErrorMessage() {
    this.errorMessage = '';
  }

  async fetchProfileData(developerUid: string) {
    this.loading = true;
    try {
      const response = await ProfileService.getProfileData(developerUid);
      runInAction(() => {
        this.profileData = serializeProfileData(response);
        this.loading = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.loading = false;
        this.errorMessage = e.message;
      });
    }
  }

  async updateAboutSection(payload: IUpdateAboutSectionRequest) {
    this.saving.about = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';
    try {
      const response = await ProfileService.updateProfileAboutSection(developerUid, payload);
      runInAction(() => {
        this.profileData = { ...this.profileData, about: response.about, needs_approval: true };
        this.saving.about = false;
      });

      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.about = false;
      });
    }
  }

  async updateAvatar(payload: Blob) {
    this.saving.avatar = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';
    try {
      const formData = new FormData();
      formData.append('file', payload, `${uuid_v4()}.jpeg`);
      const avatarUrl = await UtilsService.uploadMedia(formData);
      const response = await ProfileService.updateProfileAvatar(developerUid, {
        avatar: avatarUrl.url,
      });
      runInAction(() => {
        this.saving.avatar = false;
        this.profileData = { ...this.profileData, needs_approval: true };
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.avatar = false;
      });
    }
  }

  async updateWorkSection(payload: IUpdateWorkSectionRequest) {
    this.saving.work = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';
    try {
      const response = await ProfileService.updateProfileWorkSection(developerUid, payload);
      runInAction(() => {
        this.profileData = { ...this.profileData, work: response.work };
        this.saving.work = false;
      });

      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.work = false;
      });
    }
  }

  async updateSocialSection(payload: IUpdateSocialSectionRequest) {
    this.saving.social = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.updateProfileSocialSection(developerUid, payload);
      runInAction(() => {
        this.profileData = { ...this.profileData, social: response.social, needs_approval: true };
        this.saving.social = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.social = false;
      });
    }
  }

  async updateSkillsSection(payload: IUpdateSkillsSectionRequest) {
    this.saving.skills = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.updateProfileSkillsSection(developerUid, payload);
      runInAction(() => {
        this.profileData = { ...this.profileData, skills: response.skills, needs_approval: true };
        this.saving.skills = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.skills = false;
      });
    }
  }

  async updateExperienceSection(payload: IUpdateExperiencePayload) {
    this.saving.experience = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.updateProfileExperienceSection(developerUid, payload);
      const [experienceItem] = serializeExperience([response.experience]);
      runInAction(() => {
        let experienceList = [];
        if (payload.uid) {
          experienceList = [...this.profileData.experience];
          experienceList = experienceList.map((experience) => {
            if (experience.uid === experienceItem?.uid) {
              return experienceItem;
            }
            return experience;
          });
        } else {
          experienceList = [...this.profileData.experience, experienceItem];
        }
        this.profileData = {
          ...this.profileData,
          experience: experienceList,
          needs_approval: true,
        };
        this.saving.experience = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.experience = false;
      });
    }
  }

  async removeExperience(payload: IRemoveExperienceRequest) {
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.removeProfileExperience(developerUid, payload);
      runInAction(() => {
        let experienceList = [];
        experienceList = this.profileData.experience.filter(
          (experience) => experience.uid !== payload.uid
        );
        this.profileData = {
          ...this.profileData,
          experience: experienceList,
          needs_approval: true,
        };
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
      });
    }
  }

  async updateEducationSection(payload: IUpdateEducationRequest) {
    this.saving.education = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.updateProfileEducationSection(developerUid, payload);
      runInAction(() => {
        let educationList = [];
        if (payload.uid) {
          educationList = [...this.profileData.education];
          educationList = educationList.map((education) => {
            if (education.uid === response.education?.uid) {
              return response.education;
            }
            return education;
          });
        } else {
          educationList = [...this.profileData.education, response.education];
        }
        this.profileData = { ...this.profileData, education: educationList, needs_approval: true };
        this.saving.education = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.education = false;
      });
    }
  }

  async removeEducation(payload: IRemoveEducationRequest) {
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.removeProfileEducation(developerUid, payload);
      runInAction(() => {
        let educationList = [];
        educationList = this.profileData.education.filter(
          (education) => education.uid !== payload.uid
        );
        this.profileData = { ...this.profileData, education: educationList, needs_approval: true };
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
      });
    }
  }

  async updateShowcaseSection(payload: IUpdateShowcaseRequest) {
    this.saving.showcase = true;
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    try {
      const response = await ProfileService.updateProfileShowcaseSection(developerUid, payload);
      runInAction(() => {
        let showcaseList = [];
        if (payload.uid) {
          showcaseList = [...this.profileData.showcase];
          showcaseList = showcaseList.map((showcase) => {
            if (showcase.uid === response.showcase?.uid) {
              return response.showcase;
            }
            return showcase;
          });
        } else {
          showcaseList = [...this.profileData.showcase, response.showcase];
        }
        this.profileData = { ...this.profileData, showcase: showcaseList, needs_approval: true };
        this.saving.showcase = false;
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
        this.saving.showcase = false;
      });
    }
  }

  async uploadShowcaseThumbnail(file: File) {
    const formData = new FormData();
    formData.append('file', file, `${uuid_v4()}.${file.name.split('.').pop()}`);
    try {
      const imageUrl = await UtilsService.uploadMedia(formData);

      return imageUrl.url;
    } catch (e: any) {
      runInAction(() => (this.errorMessage = e.message));
    }
  }

  async removeShowcase(payload: IRemoveShowcaseRequest) {
    const developerUid = this.root.myDevsStore?.selectedDev?.uid || '';

    const response = await ProfileService.removeProfileShowcase(developerUid, payload);
    try {
      runInAction(() => {
        let showcaseList = [];
        showcaseList = this.profileData.showcase.filter((showcase) => showcase.uid !== payload.uid);
        this.profileData = { ...this.profileData, showcase: showcaseList, needs_approval: true };
      });
      return response;
    } catch (e: any) {
      runInAction(() => {
        this.errorMessage = e.message;
      });
    }
  }
}

const serializeProfileData = (data: IGetProfileDataResponse) => {
  const experience = serializeExperience(data.experience);
  return { ...data, experience };
};

const serializeExperience = (experience: IProfileExperienceResponseItem[]) => {
  return experience.map((exp) => {
    const [highlight1, highlight2, highlight3] = exp.highlights;
    const serializedExpItem = { ...exp, highlight1, highlight2, highlight3 } as any;
    delete serializedExpItem.highlights;

    return serializedExpItem as IProfileExperienceItem;
  });
};
