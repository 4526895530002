import { FC } from 'react';
import { format } from 'date-fns';
import ShowMoreText from 'react-show-more-text';
import { ITimesheetsObject } from '../../interfaces/timesheets';
import { InvoiceStatus } from './InvoiceStatus';

type TRowProps = {
  buildForm(): void;
  data: ITimesheetsObject;
  highlight: boolean;
};

export const Row: FC<TRowProps> = (props: TRowProps) => {
  const {
    buildForm,
    data: {
      customer_invoice_status: invoiceStatus,
      job_day: jobDay,
      note,
      status,
      worked_hours: workedHours,
    },
    highlight,
  } = props;

  return (
    <div
      className={`p-2 grid gap-x-3 col-span-full grid-rows-1 grid-cols-7 lg:grid-cols-12 border-solid border-b last:border-b-0 border-gray-300 roll-over-effect ${
        highlight && 'highlight'
      }`}
      key={jobDay}
    >
      <span className="col-span-2 inline-flex items-center">
        {format(new Date(jobDay.replace(/-/g, '/')), 'dd MMM')}
      </span>
      <span className="col-span-2 inline-flex items-center">
        {workedHours < 0 ? 0 : workedHours}h
      </span>
      <div className="hidden lg:block lg:col-span-5 break-words">
        {status === 'OFF' || note === 'Day off' ? (
          <p>&#127796; Vacation</p>
        ) : (
          <ShowMoreText
            expanded={false}
            less="less..."
            lines={1}
            more="more..."
            truncatedEndingComponent={' '}
            anchorClass="text-blue-500 font-bold"
            width={220}
          >
            <p
              dangerouslySetInnerHTML={{
                __html: note.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
          </ShowMoreText>
        )}
      </div>
      <div className="col-span-3 text-blue-500 font-bold flex w-full justify-between">
        <InvoiceStatus status={invoiceStatus?.status || null} buildForm={buildForm} />
      </div>
    </div>
  );
};
