import { defaultGeneralError, IGeneralError } from './general';

export interface IGetDeveloperBadgesResponse {
  error: IGeneralError;
  assigned: IDeveloperBadgeItem[];
  available: IDeveloperBadgeItem[];
}

export interface IDeveloperBadgeItem {
  uid: string;
  title: string;
  icon: string;
  content: string;
  data: string;
  tags: IBadgeTagItem[];
  skills: string[];
  type: number;
  category: number;
  is_completed: boolean;
  is_pending: boolean;
  can_start: boolean;
  earned_on: number;
  state: number;
  button_text: string;
}

export interface IBadgeTagItem {
  key: string;
  name: string;
}

export interface IStartDeveloperBadgePayload {
  badge_uid?: string | null;
  calendly_event_id?: string;
  video_ask_question_id?: string;
  video_ask_contact_id?: string;
}

/** * Empties ** */
export const defaultGetDeveloperBadgesResponse: IGetDeveloperBadgesResponse = {
  error: defaultGeneralError,
  assigned: [],
  available: [],
};
