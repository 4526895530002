import React, { FC, useState } from 'react';
import { Transition } from '@headlessui/react';
import Avatar from 'react-avatar-edit';
import { Button } from '@upstackhq/component-library';
import useWindowDimensions from '../../utils/Hooks/useWindowDimensions';

type AvatarConfigPopupProps = {
  image: string;
  onCrop?: (preview: string) => void;
  onComplete: (preview: string) => void;
  onClose?: Function;
};

export const AvatarConfig: FC<AvatarConfigPopupProps> = (props) => {
  const [isShowing, setIsShowing] = useState<boolean>(false);
  const [preview, setPreview] = useState<string>('');
  const { width } = useWindowDimensions();
  const toggleOff = () => {
    setTimeout(() => {
      setIsShowing(false);
    }, 100);
  };
  setTimeout(() => {
    setIsShowing(true);
  }, 100);

  const handleCrop = (preview: string) => {
    if (props.onCrop) {
      props.onCrop(preview);
    }
    setPreview(preview);
  };

  const handleClose = () => {
    toggleOff();
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleComplete = () => {
    props.onComplete(preview);
    toggleOff();
  };
  return (
    <Transition
      as={React.Fragment}
      show={isShowing}
      enter="transform transition duration-400 ease-out"
      enterFrom="opacity-0 scale-95"
      enterTo="opacity-100 scale-100"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-95"
    >
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-40 sm:pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
            onClick={handleClose}
          />
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
            <div className="bg-white">
              <div className="sm:flex sm:items-start">
                <div className="">
                  <Avatar
                    onCrop={handleCrop}
                    onClose={handleClose}
                    src={props.image}
                    height={width < 500 ? width : 500}
                    width={width < 500 ? width - 32 : 500}
                    cropRadius={width < 500 ? 170 : 200}
                    exportMimeType="image/jpeg"
                    exportAsSquare
                  />
                </div>
              </div>
            </div>
            <div className="py-3 px-5 sm:flex">
              <Button
                type="button"
                customClasses="w-full"
                onClick={handleComplete}
                content="Save new photo"
                backgroundColor="blue"
                textColor="white"
              />
            </div>
          </div>
        </div>
      </div>
    </Transition>
  );
};
