import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { InformationCircleIcon } from '@heroicons/react/outline';
import { IDevItem, FULL_TIME, PART_TIME } from '../../interfaces/mydevs';
import { statusMap } from '../../constants';
import { Tooltip } from '../Tooltip/Tooltip';
import { DeveloperAvailability } from './DeveloperAvailability';

type TNewDevRowProps = {
  developer: IDevItem;
  highlight: boolean;
  selectDev: () => void;
};

export const NewDevRow: FC<TNewDevRowProps> = (props: TNewDevRowProps) => {
  const history = useHistory();
  const { developer, highlight, selectDev } = props;
  const { name, availability, rate, state } = developer;

  const availableTracker = (availability: string) => {
    if (availability === FULL_TIME) {
      return 'Full Time';
    }
    if (availability === PART_TIME) {
      return 'Part Time';
    }

    return '-';
  };

  const sendToProfile = () => {
    selectDev();
    history.push('/profile');
  };

  return (
    <div
      className={`grid grid-cols-4 sm:grid-cols-12 p-1 border-b border-gray-200 py-2 hover:bg-blue-100 ${
        highlight ? 'highlight' : ''
      }`}
    >
      <div
        className="col-span-2 sm:col-span-3 align-middle ml-4 text-blue-500 cursor-pointer"
        onClick={sendToProfile}
      >
        {name}
      </div>
      <div className="sm:col-span-3 align-middle">
        <DeveloperAvailability developer={developer} />
      </div>
      <div className="sm:col-span-2 align-middle">{availableTracker(availability)}</div>
      <div className="sm:col-span-2">
        {rate > 0 ? `$${rate}` : <b className="text-red-600">Not set</b>}
      </div>
      <div className="sm:col-span-2 justify-items-start ml-5 flex items-center">
        <span className="font-bold">{statusMap.find((s) => s.value === state)?.name}</span>
        <Tooltip
          tooltipBackground="bg-gray-800 opacity-100"
          tooltipText={statusMap.find((s) => s.value === state)?.description}
        >
          <InformationCircleIcon className=" ml-2 w-5 h-5" />
        </Tooltip>
      </div>
    </div>
  );
};
