import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import { IMenuItem } from '../interfaces/menu';
import menuService from '../api/menu';

export default class MenuStore {
  root: RootStore;

  menuItems: IMenuItem[] = [];

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      firstPage: computed,
      getMenuItems: action.bound,
    });
  }

  get firstPage() {
    let defaultStub = '/';
    this.menuItems.forEach((item: IMenuItem) => {
      if (item.first_page === 1) {
        defaultStub = item.stub;
      }
    });
    return defaultStub;
  }

  async getMenuItems() {
    const menuItems = await menuService.getItems();
    runInAction(() => {
      this.menuItems = menuItems;
    });
  }
}
