import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Textarea, Button, Input } from '@upstackhq/component-library';
import { toast } from 'react-toastify';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IProfileShowcaseItem, IUpdateShowcaseInitialRequest } from '../../../interfaces/profile';
import { linkRegex } from '../../../constants';

type props = {
  showcase?: IProfileShowcaseItem;
  onClose: Function;
  onComplete?: Function;
};
export const ShowcaseForm: FC<props> = observer(({ showcase, onClose, onComplete }) => {
  const {
    profileStore: {
      updateShowcaseSection,
      removeShowcase,
      uploadShowcaseThumbnail,
      loading,
      saving,
    },
  } = useRootStore();

  const imageRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<any>(showcase ? showcase?.thumb : '');
  const scrollRef = useRef<HTMLDivElement>(null);

  const validationSchema = yup.object().shape({
    title: yup.string().required('Title is required'),
    location: yup.string().optional(),
    url: yup
      .string()
      .required('Link is required')
      .matches(linkRegex, 'Enter a valid URL! (Example: https://www.upstack.co)'),
    description: yup.string().required('Description is required'),
    thumb: yup
      .mixed()
      .test('image', 'Thumbnail is required', (value) => {
        return !!showcase?.thumb || value.length !== 0;
      })
      .test('image', 'The file is too large', (value) => {
        if (!showcase?.thumb && value[0]) {
          return value[0].size < 200000;
        }
        return true;
      })
      .test('image', 'The file must be an image', (value) => {
        if (!showcase?.thumb && value[0]) {
          return value[0].type.includes('image');
        }
        return true;
      }),
  });

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const thumb = register('thumb');
  const { onChange: onDescriptionRefChange, ref: descriptionRef } = register('description');

  const onSubmitHandler = async (payload: IUpdateShowcaseInitialRequest) => {
    let newPayload;
    if (payload?.thumb && payload.thumb.length > 0) {
      newPayload = { ...payload, thumb: await uploadShowcaseThumbnail(payload.thumb[0]) };
    } else {
      newPayload = { ...payload, thumb: showcase?.thumb };
    }
    updateShowcaseSection({ ...newPayload, uid: showcase?.uid }).then(() => {
      toast.success('Profile successfully updated.');
      if (onComplete) {
        onComplete();
      }
    });
  };

  const handleImageUpload = (event: any) => {
    thumb.onChange(event);
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const { onChange: onTitleChange, ref: titleRef } = register('title');
  const { onChange: onLocationChange, ref: locationRef } = register('location');
  const { onChange: onUrlChange, ref: urlRef } = register('url');

  return (
    <div className="p-3 mb-p5 border-2 border-blue-100 bg-gray-100 rounded-md" ref={scrollRef}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Input
          onChange={onTitleChange}
          inputRef={titleRef}
          name="title"
          label="Title"
          defaultValue={showcase?.title}
          error={errors.title?.message}
        />
        <Input
          onChange={onLocationChange}
          inputRef={locationRef}
          name="location"
          label="Location"
          defaultValue={showcase?.location}
          error={errors.location?.message}
        />
        <Input
          onChange={onUrlChange}
          inputRef={urlRef}
          name="url"
          type="url"
          label="Link"
          defaultValue={showcase?.url}
          error={errors.url?.message}
        />
        <div className="mb-7">
          <h4 className="font-bold text-base mb-3">Thumbnail</h4>
          <div className="flex flex-col md:flex-row items-center">
            {image && (
              <img
                className="inline object-cover w-full h-full md:w-32 md:h-40 mb-10 md:mb-0 md:mr-5 rounded-sm"
                src={image}
                alt="Thumbnail"
              />
            )}
            <input
              type="file"
              onChange={handleImageUpload}
              style={{ display: 'none' }}
              name={thumb.name}
              accept="image/jpg, image/jpeg, image/png"
              ref={(instance) => {
                thumb.ref(instance);
                // @ts-ignore
                imageRef.current = instance;
              }}
            />
            <Button
              content="Upload a new photo"
              uppercase
              backgroundColor="green"
              textColor="white"
              bold
              onClick={(e: MouseEvent) => {
                e.preventDefault();
                // @ts-ignore
                imageRef.current.click();
              }}
            />
          </div>
          <p
            className={`text-xs mb-1 mt-2 pl-1 text-red-500 overflow-hidden transition-all ${
              errors.thumb?.message ? 'max-h-10' : 'max-h-0'
            }`}
          >
            {errors.thumb?.message}
          </p>
        </div>
        <Textarea
          name="description"
          label="Description"
          onChange={onDescriptionRefChange}
          textareaRef={descriptionRef}
          defaultValue={showcase?.description}
          error={errors.description?.message}
        />
        <div className="flex justify-between mt-5">
          <div>
            {showcase?.uid && (
              <Button
                type="button"
                content="Remove"
                customClasses="pl-0 pr-0"
                backgroundColor="transparent"
                textColor="red"
                link
                onClick={() => removeShowcase({ uid: showcase.uid })}
              />
            )}
          </div>
          <div>
            <Button
              type="button"
              content="Cancel"
              onClick={() => onClose()}
              customClasses="mr-2"
              backgroundColor="transparent"
              textColor="red"
              link
              bold
            />
            <Button
              type="submit"
              content="Save"
              disabled={loading || saving.showcase}
              isLoading={saving.showcase}
              noLockIcon
              uppercase
              backgroundColor="green"
              textColor="white"
              bold
            />
          </div>
        </div>
      </form>
    </div>
  );
});
