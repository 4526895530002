import { FC, Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { MenuIcon, XIcon } from '@heroicons/react/outline';
import { Link } from 'react-router-dom';
import { BiPowerOff } from 'react-icons/all';
import { Routing } from '../Routing';
import upstackLogo from '../assets/img/upstack_logo.png';
import { useRootStore } from '../providers/RootStoreProvider';
import { ROUTE_WRAPPER_ID, menuMap } from '../constants';
import { Tooltip } from '../components/Tooltip/Tooltip';

const toggleCaptchaBadge = (show: boolean) => {
  const badge = document.getElementsByClassName('grecaptcha-badge')[0];
  if (badge && badge instanceof HTMLElement) {
    badge.style.visibility = show ? 'visible' : 'hidden';
  }
};

export const MetaPage: FC = () => {
  const { menuStore } = useRootStore();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentStub, setCurrentStub] = useState<string>('');
  const cleanCurrentStub = window.location.pathname.replace('/', '');

  useEffect(() => {
    menuStore.getMenuItems();
  }, [menuStore]);

  useEffect(() => {
    menuStore.menuItems.forEach((value) => {
      if (value.sub === '' && value.stub === cleanCurrentStub) {
        setCurrentStub(cleanCurrentStub);
      } else if (value.sub !== '' && value.stub === cleanCurrentStub) {
        setCurrentStub(value.sub);
      }
    });
  }, [menuStore.menuItems, cleanCurrentStub]);

  useEffect(() => {
    setTimeout(() => {
      toggleCaptchaBadge(false);
    }, 500);
  }, []);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 flex z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full bg-blue-100">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                <div className="flex-shrink-0 flex items-center justify-between px-4">
                  <img className="h-10 w-auto" src={upstackLogo} alt="Upstack Developer Portal" />
                  <Link to="/logout" className="text-black hover:text-blue-500">
                    <svg
                      className="h6 hover:text-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path d="M12 21c4.411 0 8-3.589 8-8 0-3.35-2.072-6.221-5-7.411v2.223A6 6 0 0 1 18 13c0 3.309-2.691 6-6 6s-6-2.691-6-6a5.999 5.999 0 0 1 3-5.188V5.589C6.072 6.779 4 9.65 4 13c0 4.411 3.589 8 8 8z" />
                      <path d="M11 2h2v10h-2z" />
                    </svg>
                  </Link>
                </div>
                {/*  <DeveloperAvailability /> */}
                <nav className="mt-5 px-2 space-y-1">
                  {menuStore.menuItems.map((item) => {
                    if (item.sub === '') {
                      return (
                        <Link
                          key={`mobile-/${item.stub}`}
                          to={`/${item.stub}`}
                          className="text-black hover:text-blue-500 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="pr-3">{menuMap(item.stub)}</span> {item.title}
                        </Link>
                      );
                    }
                    if (item.sub !== '' && currentStub === item.sub) {
                      return (
                        <Link
                          key={`mobile-/${item.stub}`}
                          to={`/${item.stub}`}
                          className="text-black hover:text-blue-500 group flex items-center px-2 py-2 text-base font-medium rounded-md"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="pr-3">{menuMap(item.stub)}</span> {item.title}
                        </Link>
                      );
                    }
                    return <Fragment key={`mobile-/${item.stub}`} />;
                  })}
                  {/* { partnerProgramLink } */}
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true" />
        </Dialog>
      </Transition.Root>

      <div className="hidden bg-blue-100 md:flex md:flex-shrink-0">
        <div className="flex flex-col w-64">
          <div className="flex flex-col h-0 flex-1">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center justify-between flex-shrink-0 px-4">
                <img className="h-10 w-auto" src={upstackLogo} alt="Upstack Developer Portal" />
                <Tooltip tooltipText="Logout">
                  <Link to="/logout" className="text-black hover:text-blue-500">
                    <BiPowerOff className="hover:text-blue-500" size={24} />
                  </Link>
                </Tooltip>
              </div>
              {/*  <DeveloperAvailability /> */}
              <nav className="mt-5 px-2 space-y-1">
                {menuStore.menuItems.map((item) => {
                  if (item.sub === '') {
                    return (
                      <Link
                        key={`/${item.stub}`}
                        to={`/${item.stub}`}
                        className={` hover:text-blue-500 group flex items-center px-2 py-2 text-base font-medium rounded-md ${
                          currentStub === item.stub ? 'text-blue-500' : 'text-black'
                        }`}
                      >
                        <span className="pr-3">{menuMap(item.stub)}</span> {item.title}
                      </Link>
                    );
                  }
                  if (item.sub !== '' && currentStub === item.sub) {
                    return (
                      <Link
                        key={`/${item.stub}`}
                        to={`/${item.stub}`}
                        className={`text-black hover:text-blue-500 group flex items-center text-sm font-normal rounded-md ml-6 ${
                          currentStub === item.stub ? 'text-blue-500' : 'text-black'
                        }`}
                      >
                        <span className="pr-3">{menuMap(item.stub)}</span> {item.title}
                      </Link>
                    );
                  }
                  return <Fragment key={`/${item.stub}`} />;
                })}
                {/* { partnerProgramLink } */}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-0 flex-1 overflow-hidden">
        <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
          <button
            className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <main
          id={ROUTE_WRAPPER_ID}
          className="flex-1 relative z-0 overflow-y-auto focus:outline-none"
        >
          <div className="py-4 px-4 sm:px-6 md:px-8">
            <Routing />
          </div>
        </main>
      </div>
    </div>
  );
};
