import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';

import {
  IGetDevsRequest,
  IGetDevsResponse,
  defaultGetNewDevsRequest,
  IUpdateAvailabilityRequest,
  IUpdateAvailabilityResponse,
} from '../interfaces/mydevs';

export const FETCH_NEWDEVS_ERROR = 'FetchNewDevsError';

class FetchDevsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = FETCH_NEWDEVS_ERROR;
  }
}

const myDevsService = {
  getDevs: async (payload: IGetDevsRequest = defaultGetNewDevsRequest) => {
    try {
      const res: AxiosResponse<IGetDevsResponse> = await axios.post(
        `${API_URL}/agency/developers`,
        payload
      );
      return res.data;
    } catch (e) {
      throw new FetchDevsError('Could not get new devs');
    }
  },
  getSelectorDevs: async () => {
    try {
      const res: AxiosResponse<IGetDevsResponse> = await axios.get(
        `${API_URL}/agency/selectors/developers`
      );
      return res.data;
    } catch (e) {
      throw new FetchDevsError('Could not get new devs');
    }
  },
  updateDeveloperAvailability: async (
    developerUid: string,
    payload: IUpdateAvailabilityRequest
  ) => {
    try {
      const res: AxiosResponse<IUpdateAvailabilityResponse> = await axios
        .post(
          `${API_URL}/agency/profile/update-availability?developer_uid=${developerUid}`,
          payload
        )
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw new Error('Could not update developer availability');
    }
  },
};

export default myDevsService;
