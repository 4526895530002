import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { v4 as uuid_v4 } from 'uuid';
import { Textarea, Button, Input } from '@upstackhq/component-library';
import { linkRegex } from '../../../constants';
import UtilsService from '../../../api/utils';
import { IPortfolio } from '../../../interfaces/developers';

type props = {
  portfolio?: IPortfolio;
  onClose: Function;
  onComplete?: Function;
  onRemove?: Function;
  index?: number;
};
export const PortfolioForm: FC<props> = observer(
  ({ portfolio, onClose, onComplete, onRemove, index }) => {
    const imageRef = useRef<HTMLInputElement>(null);
    const [image, setImage] = useState<any>(portfolio ? portfolio?.image : '');
    const scrollRef = useRef<HTMLDivElement>(null);

    const validationSchema = yup.object().shape({
      title: yup.string().required('Title is required'),
      role: yup.string().required('Role is required'),
      // location: yup.string().optional(),
      link: yup
        .string()
        .required('Link is required')
        .matches(linkRegex, 'Enter a valid URL! (Example: https://www.upstack.co)'),
      description: yup.string().required('Description is required'),
      image: yup
        .mixed()
        .test('image', 'Thumbnail is required', (value) => !!portfolio?.image || value.length !== 0)
        .test('image', 'The file is too large', (value) => {
          if (!portfolio?.image && value[0]) {
            return value[0].size < 200000;
          }
          return true;
        })
        .test('image', 'The file must be an image', (value) => {
          if (!portfolio?.image && value[0]) {
            return value[0].type.includes('image');
          }
          return true;
        }),
    });

    useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(validationSchema),
    });
    const thumb = register('image');
    const { onChange: onDescriptionRefChange, ref: descriptionRef } = register('description');

    const onSubmitHandler = async (payload: IPortfolio) => {
      let newPayload;
      if (payload?.image && payload.image.length > 0) {
        // @ts-ignore
        const thumb = await uploadImage(payload.image[0]);
        newPayload = { ...payload, image: thumb.url };
      } else {
        newPayload = { ...payload, image: portfolio?.image };
      }

      if (onComplete) {
        onComplete(newPayload);
      }
    };

    const uploadImage = async (file: File) => {
      try {
        const formData = new FormData();
        formData.append('file', file, `${uuid_v4()}.${file.name.split('.').pop()}`);
        return await UtilsService.uploadMedia(formData);
      } catch (e: any) {}
    };

    const handleImageUpload = (event: any) => {
      thumb.onChange(event);
      const reader = new FileReader();
      const file = event.target.files[0];
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    };

    const { onChange: onTitleChange, ref: titleRef } = register('title');
    const { onChange: onRoleChange, ref: roleRef } = register('role');
    const { onChange: onLinkChange, ref: linkRef } = register('link');

    return (
      <div className="p-3 mb-p5 border-2 border-blue-100 bg-gray-100 rounded-md" ref={scrollRef}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            onChange={onTitleChange}
            inputRef={titleRef}
            name="title"
            label="Title"
            defaultValue={portfolio?.title}
            error={errors.title?.message}
          />
          <Input
            onChange={onRoleChange}
            inputRef={roleRef}
            name="role"
            label="Role"
            defaultValue={portfolio?.role}
            error={errors.role?.message}
          />
          <Input
            onChange={onLinkChange}
            inputRef={linkRef}
            name="link"
            type="url"
            label="Link"
            defaultValue={portfolio?.link}
            error={errors.link?.message}
          />
          <div className="mb-7">
            <h4 className="font-bold text-base mb-3">Thumbnail</h4>
            <div className="flex flex-col md:flex-row items-center">
              {image && (
                <img
                  className="inline object-cover w-full h-full md:w-32 md:h-40 mb-10 md:mb-0 md:mr-5 rounded-sm"
                  src={image}
                  alt="Thumbnail"
                />
              )}
              <input
                type="file"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
                name={thumb.name}
                accept="image/jpg, image/jpeg, image/png"
                ref={(instance) => {
                  thumb.ref(instance);
                  // @ts-ignore
                  imageRef.current = instance;
                }}
              />
              <Button
                content="Upload a new photo"
                uppercase
                backgroundColor="green"
                textColor="white"
                bold
                onClick={(e: MouseEvent) => {
                  e.preventDefault();
                  // @ts-ignore
                  imageRef.current.click();
                }}
              />
            </div>
            <p
              className={`text-xs mb-1 mt-2 pl-1 text-red-500 overflow-hidden transition-all ${
                errors.image?.message ? 'max-h-10' : 'max-h-0'
              }`}
            >
              {errors.image?.message}
            </p>
          </div>
          <Textarea
            name="description"
            label="Description"
            onChange={onDescriptionRefChange}
            textareaRef={descriptionRef}
            defaultValue={portfolio?.description}
            error={errors.description?.message}
          />
          <div className="flex justify-between mt-5">
            <div>
              {index !== undefined && (
                <Button
                  type="button"
                  content="Remove"
                  customClasses="pl-0 pr-0"
                  backgroundColor="transparent"
                  textColor="red"
                  link
                  onClick={() => {
                    if (onRemove) {
                      onRemove(index);
                    }
                  }}
                />
              )}
            </div>
            <div>
              <Button
                type="button"
                content="Cancel"
                onClick={() => onClose()}
                customClasses="mr-2"
                backgroundColor="transparent"
                textColor="red"
                link
                bold
              />
              <Button
                type="submit"
                content="Save"
                noLockIcon
                uppercase
                backgroundColor="green"
                textColor="white"
                bold
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
);
