import { cloneElement, FC, ReactElement, useEffect, useState } from 'react';
import { WizardHeader } from './WizardHeader';
import { WizardBottomNavigation } from './WizardBottomNavigation';

type Props = {
  steps: Step[];
  onComplete: Function;
  finalStepButtonText?: string;
  saving?: boolean;
};

type Step = {
  title: string;
  component: ReactElement;
  required: boolean;
  updateContent: Function;
  data: any;
};

export const WizardForm: FC<Props> = (props) => {
  const { steps, finalStepButtonText, onComplete, saving } = props;
  const [nextStep, setNextStep] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const Content = steps[currentStep - 1]?.component;

  useEffect(() => {
    setNextStep(false);
  }, [currentStep]);

  return (
    <div className="relative">
      <WizardHeader currentStep={currentStep} steps={steps} />
      <div className="pt-5">
        {cloneElement(Content, {
          onComplete: (data: any) => {
            setNextStep(false);
            if (currentStep === steps.length) {
              onComplete();
              return;
            }
            steps[currentStep - 1]?.updateContent(data);
            setCurrentStep(currentStep + 1);
          },
          onInvalid: () => {
            setNextStep(false);
          },
          onUpdate: (data: any) => {
            steps[currentStep - 1]?.updateContent(data);
          },
          nextStep,
        })}
      </div>
      <WizardBottomNavigation
        saving={saving}
        onNext={() => {
          setNextStep(true);
        }}
        onPrevious={() => {
          setCurrentStep(currentStep - 1);
        }}
        onSkip={() => {
          setCurrentStep(currentStep + 1);
        }}
        onComplete={() => {
          setNextStep(true);
        }}
        currentStep={currentStep}
        steps={steps}
        finalStepButtonText={finalStepButtonText}
      />
    </div>
  );
};
