import { defaultGeneralError, IGeneralError } from './general';

export interface IJobTag {
  type: string;
  value: string;
}

export interface IGetJobsRequest {
  limit: number;
  page: number;
  per_page: number;
  badges: string[];
  interest?: number;
  agency_developer_uid?: string;
}

export interface IGetJobsResponse {
  error: IGeneralError;
  jobs: IJobItem[];
  total: number;
  hidden: number;
}

export interface IGetSingleJobResponse {
  error: IGeneralError;
  job: IJobItem;
}

export interface IJobItem {
  uid: string;
  is_new: boolean;
  title: string;
  short_description: string;
  long_description: string;
  age: string;
  company_size: string;
  skills: string[];
  optional_skills: string[];
  hidden_skill_count: number;
  tags: IJobTag[];
  user_state: number;
  rate: number;
  video_ask?: string;
  can_apply: boolean;
  pre_vetting_answers?: IPreVettingAnswer;
  apply_warning?: string;
}

export interface IJobInterestedRequest {
  uid: string;
  user_uid?: string;
  interest: number;
  available?: string;
  can_start?: string;
  good_fit?: string;
  question_uid?: string;
  contact_uid?: string;
  experience?: { skill: string; years: string }[];
  overlap?: string[];
  rate?: number;
  answer?: string;
  available_from?: string;
}

export interface IPreVettingAnswer {
  availability: string;
  can_start: string;
  contact_uid: string;
  experience: { skill: string; years: string }[];
  good_fit: string;
  interview_times: string | null;
  overlap: string[];
  question_uid: string;
  rate: number;
  not_interested_answer?: string;
}

export const emptyJobItem = {
  uid: '',
  is_new: false,
  title: '',
  short_description: '',
  long_description: '',
  age: '',
  company_size: '',
  skills: [],
  optional_skills: [],
  hidden_skill_count: 0,
  tags: [],
  user_state: 0,
  rate: 0,
  video_ask: '',
  can_apply: false,
  apply_warning: '',
};
/** * Empties ** */
export const defaultGetJobsResponse: IGetJobsResponse = {
  error: defaultGeneralError,
  jobs: [],
  total: 0,
  hidden: 0,
};

export const defaultGetSingleJobResponse: IGetSingleJobResponse = {
  error: defaultGeneralError,
  job: emptyJobItem,
};
