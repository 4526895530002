import { defaultGeneralError, IGeneralError, IPagination } from './general';

export const NULL_JOB_ID = -1;
export const SUBMITTED_STATUS = 'Submitted';
export const READY_STATUS = 'Ready to Bill';
export const SENT_STATUS = 'Bill Sent';
export const PAID_STATUS = 'Paid';

export type TInvoiceStatus =
  | null
  | typeof SUBMITTED_STATUS
  | typeof READY_STATUS
  | typeof SENT_STATUS
  | typeof PAID_STATUS;

export interface ITimesheetsResponse {
  error: IGeneralError;
  jobs: ITimesheetsJob[];
  pagination: IPagination;
  pending_time_sheets: ITimesheetsObject[];
  time_sheets: ITimesheetsObject[];
}

export interface ITimesheetUpdateResponse {
  error: IGeneralError;
  pending_time_sheets: ITimesheetsObject[];
  time_sheet: ITimesheetsObject;
}

export interface ITimesheetsObject {
  customer_invoice_status: ICustomerInvoiceStatus;
  job_day: string;
  job_id: number;
  note: string;
  status: string;
  worked_hours: number;
}

export interface ICustomerInvoiceStatus {
  created_at: string;
  id: number;
  paid_at: string;
  status: TInvoiceStatus;
  uid: string;
}

export interface ITimesheetsJob {
  company_name: string;
  id: number;
  title: string;
}

export interface ITimesheetsPayloadUpload {
  developer_uid: string;
  job_id: number | undefined;
  note: string;
  worked_day: string;
  worked_hours: number;
}

export interface ITimesheetsGetPayload {
  developer_uid: string;
  job_id?: number;
  page: number;
  per_page: number;
}

export const defaultTimesheetsResponse = {
  error: defaultGeneralError,
  pending_time_sheets: [],
  time_sheets: [],
  jobs: [],
  pagination: {
    pages: 0,
    total_results: 0,
  },
};

export const emptyTimesheetJob = {
  company_name: '',
  id: -1,
  title: '',
};

export const emptyTimesheetObject = {
  customer_invoice_status: {
    created_at: '',
    id: -1,
    paid_at: '',
    status: null,
    uid: '',
  },
  job_id: -1,
  job_day: '',
  note: '',
  status: '',
  worked_hours: 0,
};
