import { IGeneralError, IPagination } from './general';

export const DEVS_PER_PAGE = 10;

export const FULL_TIME = 'fulltime';

export const PART_TIME = 'parttime';

export const STATUS = undefined;

export interface IDevsPagination {
  currentPage: number;
  totalPages: number;
}

export interface IGetDevsRequest {
  per_page: number;
  page: number;
  hired?: boolean;
}

export interface IGetDevsResponse {
  error: IGeneralError;
  pagination: IPagination;
  developers: IDevItem[];
}

export interface IDevErrors {
  fetchDevs: string;
}

export interface IDevItem {
  uid: string;
  name: string;
  availability: string;
  rate: number;
  state: number;
  job_name: string;
  job_status: string;
  badges?: string[];
  available?: number;
  days_unavailable?: number;
}

export const defaultNewDevErrors = {
  fetchDevs: '',
};

export const defaultNewDevPagination = {
  currentPage: 1,
  totalPages: 1,
};

export const defaultGetNewDevsRequest = {
  per_page: DEVS_PER_PAGE,
  page: 1,
};

export interface IUpdateAvailabilityRequest {
  availability: number;
  available_from?: string;
  days_unavailable?: number;
}

export interface IUpdateAvailabilityResponse {
  error?: IGeneralError;
}
