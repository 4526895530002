import React, { FC, useEffect, useState } from 'react';
import { format } from 'date-fns';

import { Transition } from '@headlessui/react';
import { Button } from '@upstackhq/component-library';
import { ITimesheetsObject, ITimesheetsPayloadUpload } from '../../interfaces/timesheets';
import { ROUTE_WRAPPER_ID } from '../../constants';

const MAX_NOTE_LENGTH = 840;
const MAX_HOURS = 15;
const DEFAULT_HOURS = 8;
const TRANSITION_MS = 500;

type TNewTimesheetFormProps = {
  cancel(): void;
  developerUid: string;
  isLoading: boolean;
  isPending: boolean;
  postTimesheets(payload: ITimesheetsPayloadUpload): Promise<void>;
  setRowHighlightDay(day: string): void;
  timesheet: ITimesheetsObject;
};

export const NewTimesheetForm: FC<TNewTimesheetFormProps> = (props: TNewTimesheetFormProps) => {
  const [isValid, setIsValid] = useState<boolean>(true);
  const [touchedSubmit, setTouchedSubmit] = useState<boolean>(false);
  const {
    developerUid,
    cancel,
    isLoading,
    isPending,
    postTimesheets,
    setRowHighlightDay,
    timesheet,
  } = props;

  const { worked_hours: hours, job_day: jobDay, job_id: jobId, note } = timesheet;

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSending, setIsSending] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [workNote, setWorkNote] = useState<string>('');
  const [workedHours, setWorkedHours] = useState<number>(DEFAULT_HOURS);
  const [workedHoursOff, setWorkedHoursOff] = useState<boolean>(false);

  const init = () => {
    const hoursValue = isPending ? DEFAULT_HOURS : hours < 0 ? 0 : hours;
    const workedHoursOffValue = isPending ? false : hours <= 0;

    const description = note === 'PENDING' || note === 'Day off' ? '' : note;

    setWorkNote(description);
    setWorkedHours(hoursValue);
    setWorkedHoursOff(workedHoursOffValue);
    setTimeout(() => setShow(true), TRANSITION_MS);
  };

  useEffect(() => {
    document.getElementById(ROUTE_WRAPPER_ID)?.scroll({
      behavior: 'smooth',
      top: 0,
    });

    setShow(false);

    if (jobDay.length) {
      init();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobDay, isPending]);

  useEffect(() => {
    let errorText = '';
    let canSubmit = true;

    if (!workedHours && !workedHoursOff) {
      errorText += 'Please select how many hours you worked.\n';
      canSubmit = false;
    }

    if (!workNote && !workedHoursOff) {
      errorText += 'Please describe what you did.\n';
      canSubmit = false;
    }

    setErrorMessage(errorText);
    setIsValid(canSubmit);
    if (touchedSubmit && canSubmit) {
      setTouchedSubmit(false);
    }
  }, [touchedSubmit, workedHours, workedHoursOff, workNote]);

  const disableWorkedHours = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.currentTarget;

    if (value === 'Time OFF') {
      setWorkedHoursOff(true);
      setWorkedHours(0);
      setWorkNote('');
    } else {
      setWorkedHoursOff(false);
    }
  };

  const createPayload = () => ({
    developer_uid: developerUid,
    job_id: jobId,
    note: workedHoursOff ? 'Time OFF' : workNote,
    worked_hours: workedHours === 0 ? -2 : workedHours,
    worked_day: jobDay,
  });

  const submitWorkedDay = async (e: SubmitEvent) => {
    e.preventDefault();
    setTouchedSubmit(true);

    if (!isValid) return;

    setIsSending(true);
    setErrorMessage('');

    const payload = createPayload();

    await postTimesheets(payload);
    setRowHighlightDay(payload.worked_day);
    setIsSending(false);
  };

  return (
    <Transition
      as="section"
      className="mb-4 overflow-hidden relative lg:w-9/12"
      show={show}
      enter={`transition-all duration-${TRANSITION_MS}`}
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave={`transition-all duration-${TRANSITION_MS}`}
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      {isLoading && (
        <div className="animate-pulse text-xl font-bold rounded-lg bg-opacity-90 bg-blue-50 flex items-center justify-center absolute bottom-0 left-0 right-0 top-0">
          Loading...
        </div>
      )}
      <form className="border border-gray-300 w-full p-4 rounded-lg">
        <h3 className="font-bold text-lg">
          {jobDay && format(new Date(jobDay.replace(/-/g, '/')), 'EEEE dd LLLL yyyy')}
          {isPending && <em className="ml-1">&mdash; Pending</em>}
        </h3>

        <label className="flex mt-3 flex-col md:flex-row flex-wrap">
          <select
            onChange={(e) => disableWorkedHours(e)}
            className="w-40 font-bold tracking-wide text-gray-500 border-gray-300 rounded mb-3 mx-auto lg:mb-0 md:ml-0 md:mr-3"
            value={workedHoursOff ? 'Time OFF' : 'Worked'}
          >
            <option defaultValue="Worked">Worked</option>
            <option value="Time OFF">Time OFF</option>
          </select>

          <div className="flex flex-wrap content-center justify-center flex-col sm:flex-row mb-3 lg:mb-0">
            <select
              disabled={workedHoursOff}
              className="w-40 font-bold tracking-wide text-gray-500 border-gray-300 rounded mb-3 sm:mb-0 sm:mr-3"
              onChange={(e) =>
                setWorkedHours(
                  Number(e.currentTarget.value) + (workedHours - Math.floor(workedHours))
                )
              }
              value={Math.floor(workedHours)}
            >
              <option key={0} value={0}>
                0 hours
              </option>
              {Array.from(Array(MAX_HOURS).keys()).map((item) => (
                <option key={item} value={item + 1}>
                  {`${workedHoursOff ? 0 : item + 1} hours`}
                </option>
              ))}
            </select>
            <select
              disabled={workedHoursOff}
              className="w-40 font-bold tracking-wide text-gray-500 border-gray-300 rounded"
              onChange={(e) =>
                setWorkedHours(Math.floor(workedHours) + Number(e.currentTarget.value))
              }
              value={workedHours - Math.floor(workedHours)}
            >
              <option key={0} value={0}>{`${0} minutes`}</option>
              <option key={0.25} value={0.25}>{`${workedHoursOff ? 0 : 15} minutes`}</option>
              <option key={0.5} value={0.5}>{`${workedHoursOff ? 0 : 30} minutes`}</option>
              <option key={0.75} value={0.75}>{`${workedHoursOff ? 0 : 45} minutes`}</option>
            </select>
          </div>
        </label>

        <label className="flex flex-col mb-4">
          <span className="font-bold text-lg mb-2">What I worked on...</span>

          <textarea
            className={`border-gray-300 rounded ${workedHoursOff && 'bg-gray-100'}`}
            disabled={workedHoursOff}
            maxLength={MAX_NOTE_LENGTH}
            onChange={(e) => setWorkNote(e.target.value)}
            placeholder={[
              'What did you work on today?',
              'What problems or slowdowns did you run into?',
              "What's the plan for tomorrow?",
            ].join('\n')}
            rows={3}
            style={{ resize: 'none' }}
            value={workNote}
          />

          {workNote.split('').length >= MAX_NOTE_LENGTH && (
            <span className="font-bold text-sm text-yellow-600 text-right">
              Maximum character limit reached
            </span>
          )}
        </label>

        {errorMessage && touchedSubmit && <p className="my-5 text-red-500">{errorMessage}</p>}

        <div className="flex flex-wrap justify-around">
          <Button
            customClasses="w-44 sm:w-60 mb-3 font-bold text-center justify-center rounded-lg tracking-wider"
            content="Submit"
            textColor="white"
            backgroundColor="green"
            onClick={submitWorkedDay}
            disabled={isSending || (touchedSubmit && !isValid)}
            noLockIcon={isSending}
            isLoading={isSending}
          />

          {!isPending && (
            <Button
              customClasses="w-40 text-center justify-center"
              content="Cancel"
              backgroundColor="transparent"
              textColor="red"
              onClick={cancel}
              link
            />
          )}
        </div>
      </form>
    </Transition>
  );
};
