import React, { FormEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import upstackLogo from '../assets/img/upstack_logo.png';
import { Error } from '../components/Notifications/Error';
import { useRootStore } from '../providers/RootStoreProvider';
import forgotPasswordGFX from '../assets/img/forgot-password.svg';

export function ForgotPasswordPage() {
  const {
    userStore: { performForgotPassword },
  } = useRootStore();
  const [username, setUsername] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSent, setIsSent] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const forgotPassword = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (username === '') return;
    setIsLoading(true);
    performForgotPassword({ username, section: 'agency' })
      .then(() => {
        setIsLoading(false);
        setIsSent(true);
        setHasError(false);
        setErrMessage('');
      })
      .catch((err) => {
        setIsLoading(false);
        setIsSent(false);
        setHasError(true);
        setErrMessage(err.response?.data.error.message || 'Unknown error! Please try again later.');
      });
  };
  return (
    <div className="min-h-screen bg-white flex">
      <div className="hidden lg:block relative w-0 flex-1 flex-col align-center">
        <img className="h-screen mx-auto" src={forgotPasswordGFX} alt="Upstack Developer Portal" />
      </div>
      <div
        className={`
          flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24
        `}
      >
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={upstackLogo} alt="Upstack Developer Portal" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
              {isSent ? 'Password reset request sent!' : 'Reset your password'}
            </h2>
            {!isSent && (
              <p className="mt-2 text-sm text-gray-600">
                or (
                <Link className="font-medium text-blue-500 hover:text-blue-700" to="/login">
                  Login
                </Link>
                )
              </p>
            )}
            {isSent && (
              <p className="mt-2 text-sm text-gray-600">
                We'll send instructions on how to reset your password on the provided email address.
                <br />
                <br />(
                <Link className="font-medium text-blue-500 hover:text-blue-700" to="/login">
                  Back to Login
                </Link>
                )
              </p>
            )}
          </div>
          <div className="mt-8">
            <div className="mt-6">
              {!isSent && (
                <form
                  action="#"
                  method="POST"
                  className="space-y-6"
                  onSubmit={(e) => forgotPassword(e)}
                >
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className={`
                        appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md
                        shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500
                        focus:border-blue-500 sm:text-sm
                      `}
                      />
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className={`
                      w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm
                      text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-blue-500"
                    `}
                    >
                      {!isLoading && <LockClosedIcon className="h-5 w-5" aria-hidden="true" />}
                      {isLoading && (
                        <svg
                          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0
                          014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                      )}
                      Reset password
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      {hasError && (
        <Error
          title="Password reset"
          mainMessage="An error occurred during resetting your password!"
          subMessage={errMessage}
          onClick={(e) => setHasError(false)}
        />
      )}
    </div>
  );
}
