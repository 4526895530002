import { FC, useEffect } from 'react';
import { UserIcon } from '@heroicons/react/outline';
import { observer } from 'mobx-react';
import { Switch } from '@headlessui/react';
import { Autocomplete } from '@upstackhq/component-library';
import { useRootStore } from '../../providers/RootStoreProvider';

type props = {
  onChange?: Function;
  selected?: string;
};

export const DeveloperSelector: FC<props> = observer((props) => {
  const { onChange, selected } = props;
  const developerIcon = <UserIcon className="w-6 h-6 text-blue-400" />;
  const {
    myDevsStore: {
      allDevs,
      selectableDevs,
      setSelectedDev,
      setShowAll,
      selectedDev,
      showAll,
      fetchAllDevs,
      fetchingAllDevs,
    },
  } = useRootStore();

  useEffect(() => {
    if (!allDevs.length && !fetchingAllDevs) {
      fetchAllDevs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!allDevs.length) return;
    if (selectedDev) return;

    const selectedIsSelectable =
      selectableDevs.filter((dev) => dev.value === selected).length === 0;

    if (selected && selectedIsSelectable) {
      setShowAll(true);
    }

    setSelectedDev(selected || selectableDevs[0]?.value);
  }, [allDevs, showAll]);

  const handleDeveloperChange = (uid: string) => {
    setSelectedDev(uid);
    if (onChange) {
      onChange();
    }
  };

  return (
    <div className="flex items-center">
      <Autocomplete
        icon={developerIcon}
        value={selectedDev?.uid || null}
        loading={fetchingAllDevs}
        data={selectableDevs}
        onChange={handleDeveloperChange}
        borderColor="blue-500"
      />
      {/* <Switch.Group> */}
      {/*   <div className="flex items-center"> */}
      {/*     <Switch */}
      {/*       checked={showAll} */}
      {/*       onChange={setShowAll} */}
      {/*       className={`${showAll ? 'bg-blue-600' : 'bg-blue-400'} */}
      {/*     relative inline-flex flex-shrink-0 h-7 w-16 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 ml-4 mb-4`} */}
      {/*     > */}
      {/*       <span className="sr-only">Show all statuses</span> */}
      {/*       <span */}
      {/*         aria-hidden="true" */}
      {/*         className={`${showAll ? 'translate-x-9' : 'translate-x-0'} */}
      {/*       pointer-events-none inline-block h-6 w-6 rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`} */}
      {/*       /> */}
      {/*     </Switch> */}
      {/*     <Switch.Label className="ml-2 mb-4 text-sm">Show all statuses</Switch.Label> */}
      {/*   </div> */}
      {/* </Switch.Group> */}
    </div>
  );
});
