import { defaultGeneralError, IGeneralError } from './general';

export interface ICareersResponse {
  error: IGeneralError;
  job_openings: ICareerItem[];
}

export interface ICareerItem {
  title: string;
  uid: string;
  skill: string;
  category: string;
  location: string;
  description: string;
}

export const defaultCareerResponse = {
  error: defaultGeneralError,
  job_openings: [],
};
