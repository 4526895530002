import { action, computed, makeAutoObservable } from 'mobx';
import axios from 'axios';
import { RootStore } from './index';
import { API_URL, STORAGE_NAME } from '../constants';
import { IAuthResponse } from '../interfaces/user';

export default class UtilityStore {
  root: RootStore;

  source: any = {};

  isRefreshing: boolean = false;

  failedQueue: any = [];

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      apiHeaders: computed,
      addInterceptor: action.bound,
      processQueue: action.bound,
    });
    this.addInterceptor();
  }

  get apiHeaders() {
    const userData: IAuthResponse = this.root.userStore.authData;
    if (localStorage.getItem(STORAGE_NAME) || userData) {
      return {
        headers: {
          'Content-Type': 'application/json',
        },
        cancelToken: this.source.token,
      };
    }

    return { cancelToken: this.source.token };
  }

  processQueue = (error: any, token: any = null) => {
    this.failedQueue.forEach((prom: any) => {
      if (error || token === null) {
        prom.reject(error);
      } else {
        prom.resolve(token);
      }
    });

    this.failedQueue = [];
  };

  addInterceptor() {
    const cancelToken = axios.CancelToken;
    this.source = cancelToken.source();

    axios.interceptors.request.use(
      (config) => {
        const user = this.getUser();
        if (user) {
          config.headers['X-API-Token'] = user.token;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        const originalRequest = error.config;

        if (
          error.response.status === 401 &&
          !originalRequest._retry &&
          originalRequest.url.indexOf('auth/refresh') === -1 &&
          originalRequest.url.indexOf('auth/otp') === -1
        ) {
          if (this.isRefreshing) {
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            })
              .then((token) => {
                originalRequest._queued = true;
                originalRequest.headers['X-API-Token'] = token;
                return axios(originalRequest);
              })
              .catch((err) => Promise.reject(err));
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          const user = this.getUser();
          if (user) {
            return new Promise((resolve, reject) => {
              axios
                .post(
                  `${API_URL}/auth/refresh`,
                  {},
                  {
                    headers: {
                      'content-type': 'multipart/form-data',
                      'X-API-Token': user.token,
                      'X-Refresh-Token': user.refresh_token,
                    },
                  }
                )
                .then((response: any) => {
                  this.root.userStore.authData.token = response.data.token;
                  this.root.userStore.authData.refresh_token = response.data.refresh_token;
                  localStorage.setItem(STORAGE_NAME, JSON.stringify(this.root.userStore.authData));
                  axios.defaults.headers.common['X-API-Token'] = response.data.token;
                  originalRequest.headers['X-API-Token'] = response.data.token;
                  this.processQueue(null, response.data.token);
                  resolve(axios(originalRequest));
                })
                .catch((err) => {
                  this.root.userStore.logout();
                  window.location.reload();
                  reject(err);
                })
                .finally(() => {
                  this.isRefreshing = false;
                });
            });
          }
        }
        return Promise.reject(error);
      }
    );
  }

  private getUser() {
    let user: any = localStorage.getItem(STORAGE_NAME);

    const { authData } = this.root.userStore;
    if (user || authData) {
      user = user ? JSON.parse(user) : authData;
    }
    return user;
  }
}
