import { action, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import badgesService from '../api/badges';
import { IDeveloperBadgeItem, IStartDeveloperBadgePayload } from '../interfaces/badge';

export default class BadgesStore {
  root: RootStore;

  developerAssignedBadges: IDeveloperBadgeItem[] = [];

  developerAvailableBadges: IDeveloperBadgeItem[] = [];

  startedBadgeUid: string | null = null;

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchDeveloperBadges: action.bound,
      setStartedBadgeUid: action.bound,
      startDeveloperBadge: action.bound,
    });
  }

  async fetchDeveloperBadges(developerUid: string) {
    const res = await badgesService.getDeveloperBadges(developerUid);
    runInAction(() => {
      this.developerAssignedBadges = res.assigned;
      this.developerAvailableBadges = res.available;
    });
  }

  setStartedBadgeUid(uid: string | null) {
    this.startedBadgeUid = uid;
  }

  async startDeveloperBadge(payload: IStartDeveloperBadgePayload) {
    return await badgesService.startDeveloperBadge(payload);
  }
}
