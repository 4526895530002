import React, { FC } from 'react';

import { Pagination } from '@upstackhq/component-library';
import { BILLS_PER_PAGE, IBillsPagination } from '../../interfaces/bills';
import { BillsLoadingRows } from './BillsLoadingRows';

type TProps = {
  error: string;
  fetchBills(page?: number): void;
  handleRetry(): void;
  loading: boolean;
  pagination: IBillsPagination;
  rows: JSX.Element[];
};

export const BillsTable: FC<TProps> = (props: TProps) => {
  const { error, fetchBills, handleRetry, loading, pagination, rows } = props;

  const isFirstPage = pagination.currentPage === 1;
  const isLastPage = pagination.currentPage === pagination.totalPages;

  return (
    <section className="max-w-md w-full md:w-9/12 flex flex-col">
      <h3 className="mb-2 text-2xl font-bold">All Your Bills</h3>
      <div className="w-full grid border border-gray-300 rounded">
        <div className="grid grid-cols-4 p-1 text-lg font-bold border-b border-gray-400">
          <div className="">Date</div>
          <div className="">Total</div>
          <div className="">Status</div>
        </div>

        {loading ? (
          <BillsLoadingRows count={BILLS_PER_PAGE} />
        ) : error.length ? (
          <div className="p-3 inline-flex font-bold bg-red-50 items-center justify-center">
            {error}
          </div>
        ) : rows.length ? (
          rows
        ) : (
          <div className="p-3 inline-flex font-bold bg-gray-100 items-center justify-center">
            No bills to display
          </div>
        )}
      </div>
      {!loading && error.length ? (
        <button className="bg-transparent text-red-600 font-bold self-end" onClick={handleRetry}>
          Retry
        </button>
      ) : null}

      {pagination.totalPages > 1 && (
        <Pagination
          isFirst={isFirstPage}
          isLast={isLastPage}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          handleChangePage={fetchBills}
        />
      )}
    </section>
  );
};
