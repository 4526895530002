import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, InfoBox } from '@upstackhq/component-library';
import { PortfolioForm } from './Forms/PortfolioForm';
import { PortfolioInfoBox } from './InfoBoxes/PortfolioInfoBox';
import { IPortfolio } from '../../interfaces/developers';

type props = {
  data?: IPortfolio[];
  onComplete?: Function;
  onInvalid?: () => void;
  nextStep?: boolean;
  onUpdate?: Function;
};
export const PortfolioSection: FC<props> = observer((props) => {
  const { data, onComplete, nextStep, onUpdate } = props;
  const [newPortfolios, setNewPortfolios] = useState<{ key: number }[]>([]);
  const [portfolio, setPortfolio] = useState<IPortfolio[]>([]);

  useEffect((): void => {
    if (data?.length) {
      setPortfolio(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddNewPortfolio = () => {
    setNewPortfolios([
      ...newPortfolios,
      { key: newPortfolios.length ? newPortfolios[newPortfolios.length - 1].key + 1 : 1 },
    ]);
  };

  const onRemovePortfolio = (index: number) => {
    setNewPortfolios(newPortfolios.filter(({ key: k }) => k !== index));
    setPortfolio(portfolio.filter((portfolio: IPortfolio, i: number) => i !== index));
    onUpdate && onUpdate(portfolio.filter((portfolio: IPortfolio, i: number) => i !== index));
  };

  useEffect(() => {
    if (nextStep) {
      if (onComplete) {
        onComplete(portfolio);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  return (
    <div>
      {portfolio.length === 0 && (
        <InfoBox
          title="Enter showcase information"
          description="You can add as many entries as you want. Start by clicking add new showcase button below."
          type="warning"
        />
      )}
      <div className="flex justify-end mb-3">
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewPortfolio}
        />
      </div>

      {portfolio.map((show: IPortfolio, index: number) => (
        <PortfolioInfoBox
          index={index}
          key={index}
          portfolio={show}
          onRemove={onRemovePortfolio}
          onEdit={(data: IPortfolio, index: number) => {
            const updated = portfolio.map((portfolio: IPortfolio, i: number) => {
              if (i === index) {
                return data;
              }
              return portfolio;
            });
            setPortfolio(updated);
            onUpdate && onUpdate(updated);
          }}
        />
      ))}

      {newPortfolios.map(({ key }) => (
        <PortfolioForm
          key={key}
          onClose={() => setNewPortfolios(newPortfolios.filter(({ key: k }) => k !== key))}
          onComplete={(data: IPortfolio) => {
            setNewPortfolios(newPortfolios.filter(({ key: k }) => k !== key));
            setPortfolio([...portfolio, data]);
            onUpdate && onUpdate([...portfolio, data]);
          }}
        />
      ))}
    </div>
  );
});
