import { FC } from 'react';
import { MdCloudDone, MdPendingActions } from 'react-icons/md';

import PayoneerLogo from '../../assets/img/logos/payoneer-logo.svg';
import { Tooltip } from '../Tooltip/Tooltip';

const LOGO_MAP: { [key: string]: string } = {
  payoneer: PayoneerLogo,
};

const PENDING_TOOLTIP = 'Please complete the configuration for the payout service';

type TProps = {
  active?: boolean;
  available?: boolean;
  name: string;
  redirect?: string;
};

export const PayoutMethod: FC<TProps> = (props) => {
  const { active, available, name, redirect } = props;

  if ((active || !active) && available) {
    throw new Error('Cannot have both `available` and `active` props');
  }

  const Icon = active ? MdCloudDone : MdPendingActions;

  const iconClassName = `
    p-1 mr-3 rounded-full text-2xl
    ${active ? 'text-green-500' : 'text-gray-800'}
    ${active ? 'bg-transparent' : 'bg-yellow-400'}
  `;

  const containerClassName = `
    p-4 last:mr-0 flex items-center relative rounded h-20
    transition-all duration-200 filter drop-shadow bg-gray-50 transform
    hover:drop-shadow-md hover:translate-x-px hover:-translate-y-px
  `;

  const methodLink = redirect?.length ? redirect : '#';

  return (
    <div className="mb-3 mr-4 last:mr-0">
      <Tooltip tooltipText={!active ? PENDING_TOOLTIP : ''} tooltipBackground="bg-gray-700">
        <div className={containerClassName}>
          {available ? null : (
            <span className={iconClassName}>
              <Icon />
            </span>
          )}
          <img
            className="object-contain h-full w-24"
            src={LOGO_MAP[name.toLowerCase()]}
            alt={`${name} Logo`}
          />
          <a className="link-box" href={methodLink} target="_blank" rel="noreferrer">
            <span className="sr-only">{name}</span>
          </a>
        </div>
      </Tooltip>
    </div>
  );
};
