import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { WizardForm } from '../components/WizardForm';
import { GeneralSection } from '../components/AddDeveloper/GeneralSection';
import { SkillsSection } from '../components/AddDeveloper/SkillsSection';
import { EducationSection } from '../components/AddDeveloper/EducationSection';
import { ExperienceSection } from '../components/AddDeveloper/ExperienceSection';
import { PortfolioSection } from '../components/AddDeveloper/PortfolioSection';
import { AvailabilitySection } from '../components/AddDeveloper/AvailabilitySection';
import {
  emptyAddDeveloperPayload,
  IAddDeveloperRequestPayload,
  IDeveloperData,
  IEducation,
  IExperienceItem,
  IPortfolio,
  ISkill,
  IUserData,
} from '../interfaces/developers';
import developerService from '../api/developer';
import { useRootStore } from '../providers/RootStoreProvider';

export const AddDeveloperPage: FC = observer(() => {
  const history = useHistory();
  const [sectionsData, setSectionsData] =
    useState<IAddDeveloperRequestPayload>(emptyAddDeveloperPayload);
  const [saving, setSaving] = useState(false);
  const {
    myDevsStore: { fetchAllDevs },
  } = useRootStore();

  const steps = [
    {
      title: 'General info',
      required: true,
      component: <GeneralSection data={sectionsData?.user_data} />,
      data: sectionsData?.user_data,
      updateContent: (data: IUserData) => {
        setSectionsData({ ...sectionsData, user_data: data });
      },
    },
    {
      title: 'Availability',
      required: true,
      component: <AvailabilitySection data={sectionsData?.developer_data} />,
      data: sectionsData?.developer_data,
      updateContent: (data: IDeveloperData) => {
        setSectionsData({ ...sectionsData, developer_data: data });
      },
    },
    {
      title: 'Skills',
      required: true,
      component: (
        <SkillsSection
          data={sectionsData?.skills}
          developerPositionId={sectionsData.user_data.applied_for.uid}
        />
      ),
      data: sectionsData?.skills,
      updateContent: (data: ISkill[]) => {
        setSectionsData({ ...sectionsData, skills: data });
      },
    },
    {
      title: 'Experience',
      required: false,
      component: <ExperienceSection data={sectionsData?.employment} />,
      data: sectionsData?.employment,
      updateContent: (data: IExperienceItem[]) => {
        setSectionsData({ ...sectionsData, employment: data });
      },
    },
    {
      title: 'Education',
      required: false,
      component: <EducationSection data={sectionsData?.education} />,
      data: sectionsData?.education,
      updateContent: (data: IEducation[]) => {
        setSectionsData({ ...sectionsData, education: data });
      },
    },
    {
      title: 'Showcase',
      required: false,
      component: <PortfolioSection data={sectionsData?.portfolio} />,
      data: sectionsData?.portfolio,
      updateContent: (data: IPortfolio[]) => {
        setSectionsData({ ...sectionsData, portfolio: data });
      },
    },
  ];

  const preparePayload = () => {
    return {
      ...sectionsData,
      education: sectionsData?.education.map((item: IEducation) => {
        delete item.current;
        return item;
      }),
      employment: sectionsData?.employment.map((item: IExperienceItem) => {
        delete item.current_role;
        return item;
      }),
    };
  };

  const handleAddNewDeveloper = () => {
    setSaving(true);
    const payload = preparePayload();
    developerService
      .addDeveloper(payload)
      .then((response) => {
        toast.success('Developer added');
        setSaving(false);
        fetchAllDevs();
        history.push({
          pathname: '/my-devs',
          state: {
            added_dev_uid: response?.uid,
          },
        });
      })
      .catch((e) => {
        setSaving(false);
        toast.error(e.message);
      });
  };

  return (
    <div className="w-full md:w-full lg:w-2/3 xl:w-6/12 2xl:5/12 lg:max-w-xl">
      <h2 className="text-3xl text-bold mb-3">Add new developer</h2>
      <p className="mb-3 text-sm">Add new developer</p>
      <WizardForm
        onComplete={handleAddNewDeveloper}
        saving={saving}
        steps={steps}
        finalStepButtonText="Submit"
      />
    </div>
  );
});
