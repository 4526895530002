import { BrowserRouter, Route } from 'react-router-dom';
import { Redirect, Switch } from 'react-router';
import React from 'react';
import { observer } from 'mobx-react';
import { IPrivateRouteProps, PrivateRoute } from './components/Utils/PrivateRoute';

import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
// Routing pages
import { LoginPage } from './pages/LoginPage';
import { MetaPage } from './pages/MetaPage';
import { ResetPasswordPage } from './pages/ResetPasswordPage';
import { useRootStore } from './providers/RootStoreProvider';
import { Impersonate } from './pages/Impersonate';

export const App = observer(() => {
  const { userStore, menuStore } = useRootStore();
  const privateRouteProps: IPrivateRouteProps = {
    isAuthenticated: userStore.isAuthenticated,
    authenticationPath: '/login',
  };

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login">
          {userStore.isAuthenticated ? <Redirect push to={menuStore.firstPage} /> : <LoginPage />}
        </Route>

        <Route exact path="/forgot-password">
          {userStore.isAuthenticated ? (
            <Redirect push to={menuStore.firstPage} />
          ) : (
            <ForgotPasswordPage />
          )}
        </Route>

        <Route exact path="/reset-password/:token">
          {userStore.isAuthenticated ? (
            <Redirect push to={menuStore.firstPage} />
          ) : (
            <ResetPasswordPage />
          )}
        </Route>

        <Route exact path="/otp/:token">
          <Impersonate />
        </Route>

        <PrivateRoute {...privateRouteProps} path="*" component={MetaPage} />
      </Switch>
    </BrowserRouter>
  );
});
export default App;
