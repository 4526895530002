import { FC, useCallback } from 'react';
import { observer } from 'mobx-react';
import { CodeIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Dropdown } from '@upstackhq/component-library';
import { Select } from '../../../common/Select/Select';
import { ISkillItem } from '../../../interfaces/selector';
import { uniqBy } from '../../../utils';

type props = {
  onRemove: Function;
  onChange: Function;
  skills: ISkillItem[];
  index?: number;
  data?: SkillData;
  clean?: boolean;
};

type SkillData = {
  name: string;
  years: number;
};
export const SkillForm: FC<props> = observer(
  ({ onRemove, onChange, skills, index, data, clean }) => {
    const yearOptions = [...Array(20).keys()].map((i) => {
      const yearsText = `${i + 1} year${i > 1 + 1 ? 's' : ''}`;

      return (
        <option key={yearsText} value={i + 1}>
          {yearsText}
        </option>
      );
    });

    const handleChangeYears = useCallback(
      (years) => {
        onChange(index || 0, { years: parseInt(years) });
      },
      [index, onChange]
    );

    const handleChangeSkill = useCallback(
      (items: { title: string }[]) => {
        onChange(index || 0, { name: items.length ? items[0]?.title : '' });
      },
      [index, onChange]
    );

    const uniqSkills = uniqBy(
      skills.map((skill) => ({ title: skill.name })),
      'title'
    );

    return (
      <div className="flex flex-row relative w-full">
        <div className="flex flex-col md:flex-row items-start w-full mb-5 md:mb-0">
          <Dropdown
            limit={1}
            icon={<CodeIcon className="w-6 h-6 text-blue-400" />}
            items={uniqSkills}
            customClasses="w-full mb-4 md-mb-0 md:w-8/12 mr-3"
            selected={data && data.name ? [{ title: data.name }] : []}
            onChange={handleChangeSkill}
          />
          <Select
            name="experience"
            placeholder="Select years"
            customClasses="md:w-4/12"
            onChange={handleChangeYears}
            defaultValue={data?.years}
          >
            <option value="">Select years</option>
            {yearOptions}
          </Select>
        </div>
        <XIcon
          className={`${
            clean ? 'invisible' : ''
          } self-center ml-5 md:absolute md:-right-7 md:top-3.5 md:mt-1 text-gray-500 w-4 h-4 cursor-pointer`}
          onClick={() => {
            !clean && onRemove(index || 0);
          }}
        />
      </div>
    );
  }
);
