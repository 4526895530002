import { IGeneralError } from './general';

interface IProfileDataCommon {
  error?: IGeneralError;
  about?: IProfileAboutSection;
  work?: IProfileWorkSection;
  social?: IProfileSocialSection;
  skills: IProfileSkillItem[];
  education: IProfileEducationItem[];
  showcase: IProfileShowcaseItem[];
  needs_approval: boolean;
}

export interface IGetProfileDataResponse extends IProfileDataCommon {
  experience: IProfileExperienceResponseItem[];
}

export interface IProfileData extends IProfileDataCommon {
  experience: IProfileExperienceItem[];
}

export interface IProfileAboutSection {
  first_name: string;
  last_name: string;
  email: string;
  headline?: string;
  city?: string;
  country?: string;
  avatar?: string;
}

export interface IProfileWorkSection {
  available: string;
  availability: string;
  rate: string;
}

export interface IProfileSocialSection {
  linked_in: string;
  github?: string;
  twitter?: string;
}

export interface IProfileSkillItem {
  name: string;
  years: number;
}

interface IProfileExperienceItemCommon {
  uid: string;
  company: string;
  logo: string;
  url: string;
  location: string;
  title: string;
  start_date: number;
  end_date: number;
  current_role: boolean;
  skills: string[];
}

export interface IProfileExperienceResponseItem extends IProfileExperienceItemCommon {
  highlights: [string, string, string];
}

export interface IProfileExperienceItem extends IProfileExperienceItemCommon {
  highlight1: string;
  highlight2: string;
  highlight3: string;
}

export interface IProfileEducationItem {
  uid: string;
  degree: string;
  institution: string;
  location: string;
  start_date: number;
  end_date: number;
  current: boolean;
}

export interface IProfileShowcaseItem {
  uid: string;
  title: string;
  url: string;
  thumb: string;
  location: string;
  description: string;
}

export interface IUpdateAvatarRequest {
  avatar: string;
}

export interface IUpdateAboutSectionRequest {
  first_name: string;
  last_name: string;
  email: string;
  headline: string;
  location: string;
}

export interface IUpdateWorkSectionRequest {
  available: number;
  availability: number;
  rate: number;
}

export interface IUpdateAboutSectionResponse {
  about: IProfileAboutSection;
  error?: IGeneralError;
}

export interface IUpdateWorkSectionResponse {
  work: IProfileWorkSection;
  error?: IGeneralError;
}

export interface IUpdateSocialSectionRequest {
  linked_in: string;
  github: string;
  twitter: string;
}

export interface IUpdateAboutSocialSectionResponse {
  social: IProfileSocialSection;
  error?: IGeneralError;
}

export interface IUpdateSkillsSectionRequest {
  skills: ISkillItem[];
}

export interface IUpdateSkillsSectionResponse {
  skills: IProfileSkillItem[];
  error?: IGeneralError;
}

type ISkillItem = {
  name: string;
  years: number;
};

interface IUpdateExperienceCommon {
  uid?: string;
  company: string;
  logo?: string;
  url: string;
  location: string;
  title: string;
  start_date: string;
  end_date: string;
  current_role: boolean;
  details: string;
  skills: string[];
}

export interface IUpdateExperienceFormPayload extends IUpdateExperienceCommon {
  highlight1?: string;
  highlight2?: string;
  highlight3?: string;
}

export interface IUpdateExperiencePayload extends IUpdateExperienceCommon {
  highlights?: string[];
}

export interface IUpdateExperienceResponse {
  experience: IProfileExperienceResponseItem;
  error?: IGeneralError;
}

export interface IRemoveExperienceRequest {
  uid: string;
}

export interface IUpdateEducationRequest {
  uid?: string;
  degree: string;
  institution: string;
  location: string;
  start_date: number;
  end_date: number;
  current: boolean;
}

export interface IUpdateEducationResponse {
  education: IProfileEducationItem;
  error?: IGeneralError;
}

export interface IRemoveEducationRequest {
  uid: string;
}

export interface IUpdateShowcaseInitialRequest {
  uid?: string;
  title: string;
  url: string;
  thumb: FileList;
  location: string;
  description: string;
}

export interface IUpdateShowcaseRequest {
  uid?: string;
  title: string;
  url: string;
  thumb: string;
  location: string;
  description: string;
}

export interface IUpdateShowcaseResponse {
  showcase: IProfileShowcaseItem;
  error?: IGeneralError;
}

export interface IRemoveShowcaseRequest {
  uid: string;
}

export const defaultProfileData: IProfileData = {
  about: undefined,
  work: undefined,
  social: undefined,
  skills: [],
  experience: [],
  education: [],
  showcase: [],
  needs_approval: false,
};
