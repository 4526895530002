import { defaultGeneralError, IGeneralError } from './general';

export interface IMenuResponse {
  error: IGeneralError;
  items: IMenuItem[];
}

export interface IMenuItem {
  title: string;
  stub: string;
  icon: string;
  section: string;
  state: number;
  first_page: number;
  sub: string;
}

/** * Empties ** */
export const defaultMenuResponse = {
  error: defaultGeneralError,
  items: [],
};
