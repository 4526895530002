import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@upstackhq/component-library';
import { ShowcaseForm } from './Forms/ShowcaseForm';
import { ShowcaseInfoBox } from './InfoBoxes/ShowcaseInfoBox';
import { useRootStore } from '../../providers/RootStoreProvider';

type props = {};
export const ShowcaseSection: FC<props> = observer(() => {
  const {
    profileStore: {
      profileData: { showcase },
    },
  } = useRootStore();
  const [newShowcases, setNewShowcases] = useState<{ key: number }[]>([]);

  const onAddNewShowcase = () => {
    setNewShowcases([
      ...newShowcases,
      { key: newShowcases.length ? newShowcases[newShowcases.length - 1].key + 1 : 1 },
    ]);
  };

  return (
    <div>
      <div className="flex justify-between mb-3">
        <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Showcase</h2>
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewShowcase}
        />
      </div>

      {showcase.map((showcase) => (
        <ShowcaseInfoBox key={showcase.uid} showcase={showcase} />
      ))}

      {newShowcases.map(({ key }) => (
        <ShowcaseForm
          key={key}
          onClose={() => setNewShowcases(newShowcases.filter(({ key: k }) => k !== key))}
          onComplete={() => setNewShowcases(newShowcases.filter(({ key: k }) => k !== key))}
        />
      ))}
    </div>
  );
});
