import { FC, useEffect, useState } from 'react';
import { CodeIcon } from '@heroicons/react/outline';
import { observer } from 'mobx-react';
import { Dropdown } from '@upstackhq/component-library';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useRootStore } from '../providers/RootStoreProvider';
import { Job } from '../components/Job/Job';
import { IGetJobsRequest } from '../interfaces/job';
import filteredEye from '../assets/img/filtered-eye.svg';
import { NoResults } from '../components/Notifications/NoResults';
import { LoadingItems } from '../components/Notifications/LoadingItems';
import { ROUTE_WRAPPER_ID } from '../constants';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';

type props = {
  interested?: number;
};

type TBadge = {
  title: string;
};

export const JobsPage: FC<props> = observer((props: props) => {
  const {
    jobStore: {
      fetchJobs,
      jobsList,
      totalJobs,
      hiddenJobs,
      hasMoreResults,
      setHasMoreResults,
      isLoadingMore,
      setIsLoadingMore,
      page,
      setPage,
      perPage,
      setPerPage,
    },
    selectorStore: { fetchBadges, badges },
    myDevsStore: { selectedDev },
  } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentBadges, setCurrentBadges] = useState<TBadge[]>([]);
  const [videoAskContactUid, setVideoAskContactUid] = useState<string>('');
  const [videoAskQuestionUid, setVideoAskQuestionUid] = useState<string>('');

  const skillDropdownIcon = <CodeIcon className="w-6 h-6 text-blue-400" />;

  useEffect(() => {
    setHasMoreResults(true);
    setIsLoadingMore(false);
    setPage(1);
    setPerPage(10);
    // fetchBadges();
    const payload = buildPayload([], 0, props.interested);
    setIsLoadingMore(true);
    fetchJobs(payload, false).then(() => {
      setIsLoadingMore(false);
    });

    const isVideoAskMessage = (message: any) =>
      message.origin === 'https://www.videoask.com' &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith('videoask_');

    window.addEventListener('message', (message) => {
      if (!isVideoAskMessage(message)) {
        return;
      }

      if (message.data?.type === 'videoask_question_submitted') {
        setVideoAskContactUid(message.data?.contactId);
        setVideoAskQuestionUid(message.data?.questionId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDev?.badges?.length && !props.interested) {
      badgeChange(
        selectedDev.badges.map((badge) => ({
          title: badge,
        }))
      );
    } else {
      badgeChange([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDev, props.interested]);

  const handleScroll = () => {
    if (!hasMoreResults) {
      // return if we don't need to scroll anymore (end of data)
      return;
    }
    if (isLoadingMore) {
      // if we're fetching new jobs, return
      return;
    }

    const nextPage = page + 1;
    setPage(nextPage);
    const payload = buildPayload(currentBadges, nextPage, props.interested);
    setIsLoadingMore(true);
    fetchJobs(payload, false).then(() => {
      setIsLoadingMore(false);
    });
  };
  // -- Load more at the bottom of scroll -- //

  const buildPayload = (badges: TBadge[], page: number, interest?: number) => {
    const payload: IGetJobsRequest = {
      limit: 0,
      page,
      per_page: perPage,
      badges: [],
      interest,
      agency_developer_uid: selectedDev?.uid,
    };
    badges.forEach((badge: TBadge) => {
      payload.badges.push(badge.title);
    });
    return payload;
  };

  // badges are added or removed from the search bar
  const badgeChange = (badges: TBadge[]) => {
    setCurrentBadges(badges);
    if (selectedDev) {
      setIsLoading(true);
      setPage(1);
      fetchJobs(buildPayload(badges, 1, props.interested), true).then(() => {
        setIsLoading(false);
      });
    }
  };
  return (
    <div className="w-full">
      <h2 className="text-3xl text-bold mb-5">
        Jobs Board{' '}
        <small>
          {props.interested === 2 && '(Interested)'} {props.interested === 3 && '(Not interested)'}
        </small>
      </h2>
      <DeveloperSelector />
      {/* {!props.interested && ( */}
      {/*   <Dropdown */}
      {/*     icon={skillDropdownIcon} */}
      {/*     items={badges} */}
      {/*     selected={currentBadges} */}
      {/*     onChange={badgeChange} */}
      {/*     customClasses="mb-3 border-blue-500" */}
      {/*     inputDisabled={isLoading} */}
      {/*   /> */}
      {/* )} */}
      {isLoading && <LoadingItems count={3} />}
      {!isLoading && jobsList.length === 0 && <NoResults />}
      {!isLoading && jobsList.length > 0 && (
        <div id="jobsList">
          <p className="text-xl font-bold mt-3 mb-1">
            {totalJobs} result
            {totalJobs === 1 ? '' : 's'}
          </p>
          {!props.interested && hiddenJobs > 0 && (
            <p className="mb-3 flex flex-row items-center">
              <img src={filteredEye} className="w-4 h-4 mr-2" alt="" />
              <small className="text-gray-500">
                {hiddenJobs} job
                {hiddenJobs === 1 ? '' : 's'} hidden based on your badge selection.
              </small>
              &nbsp;
            </p>
          )}

          <InfiniteScroll
            dataLength={jobsList.length}
            next={handleScroll}
            hasMore={hasMoreResults}
            loader={<LoadingItems count={1} />}
            endMessage={
              <p style={{ textAlign: 'center' }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
            scrollableTarget={ROUTE_WRAPPER_ID}
          >
            {jobsList.map((job) => (
              <Job
                videoAskContactUid={videoAskContactUid}
                videoAskQuestionUid={videoAskQuestionUid}
                key={job.uid}
                data={job}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
});
