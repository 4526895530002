import axios, { AxiosResponse } from 'axios';
import { defaultMenuResponse, IMenuResponse } from '../interfaces/menu';
import { API_URL } from '../constants';

const menuService = {
  getItems: async () => {
    try {
      const res: AxiosResponse<IMenuResponse> = await axios.get(`${API_URL}/selectors/menu?new=1`);
      return res.data.items;
    } catch (e) {
      // TODO: Error handling
      return defaultMenuResponse.items;
    }
  },
};

export default menuService;
