import { ArrowSmDownIcon, ArrowSmUpIcon } from '@heroicons/react/solid';
import { ClipboardIcon, UserGroupIcon } from '@heroicons/react/outline';
import { FC, useEffect, useRef, useState, Fragment } from 'react';
import { FiShare2 } from 'react-icons/all';
import { Menu, Transition } from '@headlessui/react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Tag, Button, Selector, RadioButtons } from '@upstackhq/component-library';
import jobsService from '../../api/job';
import { IJobInterestedRequest, IJobItem, emptyJobItem } from '../../interfaces/job';
import { Tooltip } from '../Tooltip/Tooltip';
import { colorMap, iconMap } from '../../constants';
import { useRootStore } from '../../providers/RootStoreProvider';
import { DEFAULT_DAYS_UNAVAILABLE, DAYS_UNAVAILABLE } from '../../interfaces/user';

export interface IJobProps {
  data: IJobItem;
  videoAskQuestionUid?: string;
  videoAskContactUid?: string;
  singleJobVersion?: boolean;
  onSubmit?: Function;
  interested?: boolean;
}

const INTERESTED = 2;
const NOT_INTERESTED = 3;

const NOT_AVAILABLE = 'I am currently not available';
const NOT_MATCHED_SKILLS = 'The job does not match my skill';
const OTHER = 'Other';

const NOT_INTERESTED_QUESTIONS = [
  { value: NOT_AVAILABLE, text: NOT_AVAILABLE },
  {
    value: NOT_MATCHED_SKILLS,
    text: NOT_MATCHED_SKILLS,
  },
  { value: OTHER, text: OTHER },
];

export const Job: FC<IJobProps> = observer((props) => {
  const [localData, setLocalData] = useState<IJobItem>(emptyJobItem);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPreVetting, setShowPreVetting] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);
  const [available, setAvailable] = useState<string[]>([]);
  const [canStart, setCanStart] = useState<string[]>([]);
  const [goodFit, setGoodFit] = useState<string>('');
  const [experience, setExperience] = useState<{ [key: string]: string[] }>({});
  const [overlap, setOverlap] = useState<string[]>([]);
  const [rate, setRate] = useState<string[]>([]);
  const [questionUid, setQuestionUid] = useState<string>('');
  const [contactUid, setContactUid] = useState<string>('');
  const [interestedSubmitted, setInterestedSubmitted] = useState<boolean>(false);
  const [notInterestedSubmitted, setNotInterestedSubmitted] = useState<boolean>(false);
  const [showNotInterestedPreVetting, setNotInterestedShowPreVetting] = useState<boolean>(false);
  const [notInterestedAnswer, setNotInterestedAnswer] = useState<string>('');
  const [notInterestedOtherDesc, setNotInterestedOtherDesc] = useState<string>('');
  const [notInterestedPreVettingOpen, setNotInterestedPreVettingOpen] = useState<boolean>(false);
  const [daysUnavailable, setDaysUnavailable] = useState<number>(DEFAULT_DAYS_UNAVAILABLE.days);

  const {
    jobStore: { setPreVettingOpenUid, preVettingOpenUid },
    myDevsStore: { selectedDev },
  } = useRootStore();
  const history = useHistory();
  const jobRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLocalData(props.data);
  }, [props.data]);

  useEffect(() => {
    if (!notInterestedPreVettingOpen && localData && localData.uid === preVettingOpenUid) {
      setShowPreVetting(true);
    } else if (notInterestedPreVettingOpen && localData && localData.uid === preVettingOpenUid) {
      setNotInterestedShowPreVetting(true);
    } else {
      setShowPreVetting(false);
      setNotInterestedShowPreVetting(false);
    }
  }, [preVettingOpenUid, notInterestedPreVettingOpen, localData]);

  useEffect(() => {
    if (localData.uid === preVettingOpenUid) {
      setQuestionUid(props.videoAskQuestionUid || '');
      setContactUid(props.videoAskContactUid || '');
    }
  }, [localData.uid, preVettingOpenUid, props.videoAskContactUid, props.videoAskQuestionUid]);

  useEffect(() => {
    let experience = {};

    if (props.singleJobVersion && localData.uid && localData.can_apply) {
      if (!props.interested || localData.user_state === 3) {
        setNotInterestedPreVettingOpen(true);
      }
      setPreVettingOpenUid(localData.uid);
    }

    if (localData.pre_vetting_answers?.not_interested_answer) {
      const possibleAnswers = ['I am not available currently', 'The Job does not match my skill'];
      setNotInterestedAnswer(
        possibleAnswers.includes(localData.pre_vetting_answers.not_interested_answer)
          ? localData.pre_vetting_answers.not_interested_answer
          : 'Other'
      );
      setNotInterestedOtherDesc(
        !possibleAnswers.includes(localData.pre_vetting_answers.not_interested_answer)
          ? localData.pre_vetting_answers.not_interested_answer
          : ''
      );

      return;
    }

    if (!localData.pre_vetting_answers?.availability) return;

    localData.pre_vetting_answers.experience.forEach((skill) => {
      experience = {
        ...experience,
        [skill.skill]: [skill.years],
      };
    });

    setAvailable([localData.pre_vetting_answers.availability]);
    setCanStart([localData.pre_vetting_answers.can_start]);
    setGoodFit(localData.pre_vetting_answers.good_fit);
    setExperience(experience);

    if (localData.rate !== localData.pre_vetting_answers.rate) {
      setRate(['Other', localData.pre_vetting_answers.rate.toString()]);
    } else {
      setRate([localData.pre_vetting_answers.rate.toString()]);
    }

    setOverlap(localData.pre_vetting_answers.overlap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.singleJobVersion,
    localData.uid,
    localData.can_apply,
    localData.pre_vetting_answers,
    localData.rate,
    setPreVettingOpenUid,
  ]);

  const copyUrlToClipboard = (agency: boolean = false) => {
    if (agency) {
      navigator.clipboard
        .writeText(`${window.location.host}/job-board/${localData.uid}/${selectedDev?.uid}`)
        .then(() => {
          toast.info('Agency Job URL copied to clipboard!', { autoClose: 1500 });
        });
      return;
    }
    navigator.clipboard
      .writeText(
        `${
          process.env.REACT_APP_HOST_TYPE === 'dev'
            ? 'https://beta-developer.upstack.co'
            : 'https://developer.upstack.co'
        }/job-board/${localData.uid}`
      )
      .then(() => {
        toast.info('Developer Job URL copied to clipboard!', { autoClose: 1500 });
      });
  };

  const notInterestedPreVet = () => {
    setNotInterestedAnswer('');
    setNotInterestedOtherDesc('');
    setNotInterestedPreVettingOpen(true);
    setPreVettingOpenUid(localData.uid);
  };

  const applyNotInterested = () => {
    const notInterestedDescription = notInterestedAnswer === 'Other' && !notInterestedOtherDesc;

    if (!notInterestedAnswer || notInterestedDescription) {
      toast.error('Please specify or enter why you are not interested!');
      return;
    }
    setIsLoading(true);

    const res = jobsService.setInterest({
      interest: NOT_INTERESTED,
      uid: localData.uid,
      answer: notInterestedAnswer !== 'Other' ? notInterestedAnswer : notInterestedOtherDesc,
      user_uid: selectedDev?.uid,
      available_from:
        DAYS_UNAVAILABLE.get(daysUnavailable)?.label ?? DEFAULT_DAYS_UNAVAILABLE.label,
    });
    res
      .then(() => {
        setIsLoading(false);
        setNotInterestedSubmitted(true);
        setNotInterestedPreVettingOpen(false);
        setNotInterestedShowPreVetting(false);
        setPreVettingOpenUid(null);
        toast.success('Interest saved!');
        if (props.singleJobVersion && !props.onSubmit) {
          history.push('/job-board');
        } else if (props.onSubmit) {
          props.onSubmit(0);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const interestedPreVet = () => {
    setAvailable([]);
    setCanStart([]);
    setGoodFit('');
    setExperience({});
    setRate([]);
    setOverlap([]);
    setQuestionUid('');
    setContactUid('');
    setNotInterestedPreVettingOpen(false);
    setPreVettingOpenUid(localData.uid);
  };

  const applyInterested = () => {
    if (available.length === 0) {
      toast.error('Please specify your full or part time availability!');
      return;
    }
    if (canStart.length === 0) {
      toast.error('Please specify when will you be available to start!');
      return;
    }

    if (!overlap.length) {
      toast.error('Please specify overlap with job timezone!');
      return;
    }

    if (!rate.length || (rate[0] === 'Other' && !rate[1])) {
      toast.error('Please enter desired hourly rate for this job!');
      return;
    }

    setIsLoading(true);
    const payload: IJobInterestedRequest = {
      uid: localData.uid,
      interest: INTERESTED,
      available: available[0],
      can_start: canStart[0],
      good_fit: goodFit,
      experience: Object.keys(experience).map((skill) => ({
        skill,
        years: experience[skill][0],
      })),
      overlap,
      rate: parseFloat(rate[0]) === localData.rate ? parseFloat(rate[0]) : parseFloat(rate[1]),
      contact_uid: contactUid,
      question_uid: questionUid,
      user_uid: selectedDev?.uid,
    };
    jobsService.setInterest(payload).then(() => {
      toast.success('Interest saved!');
      setIsLoading(false);
      setInterestedSubmitted(true);
      setPreVettingOpenUid(null);
      if (props.singleJobVersion && !props.onSubmit) {
        history.push('/job-board');
      } else if (props.onSubmit) {
        props.onSubmit(1);
      }
    });
  };

  const handleNotAvailableOptionClick = (day: number) => () => {
    setDaysUnavailable(day);
  };

  const notAvailableOptions = [...DAYS_UNAVAILABLE].map(([days, dayObject]) => (
    <Menu.Item key={`days-${days}`} disabled={isLoading}>
      {({ active }) => (
        <button
          type="button"
          className={`
            py-1 px-2 rounded text-left w-full
            ${active && 'bg-blue-300'}
            ${isLoading ? 'text-gray-500' : ''}
          `}
          onClick={handleNotAvailableOptionClick(days)}
        >
          {dayObject?.label}
        </button>
      )}
    </Menu.Item>
  ));

  return (
    <div className="w-full border border-gray-100 rounded-md p-4 mb-4" ref={jobRef}>
      <div className="flex flex-row justify-between align-center">
        {/* Title */}
        <h3 className="mb-2 text-xl">
          {localData.is_new ? '🆕 ' : ''}
          <strong>{localData.title}</strong>
          <small className="text-xs text-green-600 uppercase ml-4">{localData.age}</small>
        </h3>
        <div className="flex">
          <Tooltip tooltipText="Copy Developer portal URL">
            <FiShare2
              className="w-5 h-5 cursor-pointer text-blue-400 text-xl hover:text-blue-600"
              onClick={() => copyUrlToClipboard(false)}
            />
          </Tooltip>
          <Tooltip tooltipText="Copy Agency portal URL">
            <ClipboardIcon
              className="w-5 h-5 ml-2 cursor-pointer text-blue-400 text-xl hover:text-blue-600"
              onClick={() => copyUrlToClipboard(true)}
            />
          </Tooltip>
        </div>
      </div>
      {/* Short description - Faked to the first line of the long description if it doesn't exist */}
      {localData.short_description !== '' ? (
        <p className="mb-2">{localData.short_description}</p>
      ) : (
        <p
          className="mb-2 h-6 overflow-hidden"
          dangerouslySetInnerHTML={{ __html: localData.long_description }}
        />
      )}
      {/* Company size */}
      {localData.company_size && (
        <p className="text-sm text-gray-400 flex flex-row flex-wrap mb-3 items-center">
          <UserGroupIcon className="h-4 w-4 left" />
          <span className="uppercase font-bold">
            &nbsp;
            {localData.company_size}
          </span>
        </p>
      )}
      {/* Job Essential Skills */}
      {localData.skills.length > 0 && (
        <>
          <p className="text-gray-400 text-sm font-bold uppercase pb-1">Essential skills</p>
          <div className="flex flex-row flex-wrap mb-2 items-center">
            {localData.skills.map((value) => (
              <span className="mt-1" key={`blue-${value}`}>
                <Tag textColor="blue" backgroundColor="blue" content={value} uppercase bold />
              </span>
            ))}
          </div>
        </>
      )}
      {/* Job Optional skills */}
      {localData.optional_skills.length > 0 && (
        <>
          <p className="text-gray-400 text-sm font-bold uppercase pb-1">Optional skills</p>
          <div className="flex flex-row flex-wrap mb-2 items-center">
            {localData.optional_skills.map((value) => (
              <span className="mt-1" key={`blue-${value}`}>
                <Tag textColor="blue" backgroundColor="blue" content={value} uppercase bold />
              </span>
            ))}
          </div>
        </>
      )}
      {/* Tags */}
      {localData.tags.length > 0 && (
        <div className="flex flex-row flex-wrap items-center mb-2">
          {localData.tags.map((tag) => (
            <span className="mt-1" key={`${tag.type}-${tag.value}`}>
              <Tag
                textColor={colorMap(tag.type)}
                backgroundColor={colorMap(tag.type)}
                leftIcon={iconMap(tag.type, 'w-4 h-4')}
                content={tag.value}
                uppercase
                bold
              />
            </span>
          ))}
        </div>
      )}
      {/* Long description */}
      {localData.long_description && (
        <div className="mb-4 flex flex-col">
          <div
            style={expanded ? { maxHeight: '5000px' } : { maxHeight: '3rem' }}
            className={`${
              expanded ? 'overflow-y-auto overflow-x-hidden' : 'overflow-hidden'
            } transition-all ease-linear duration-1000`}
            dangerouslySetInnerHTML={{ __html: localData.long_description }}
          />
          <div
            className="text-blue-500 cursor-pointer flex flex-row flex-wrap items-center mt-2"
            onClick={(e) => setExpanded(!expanded)}
          >
            {expanded ? (
              <ArrowSmUpIcon className="w-4 h-4" />
            ) : (
              <ArrowSmDownIcon className="w-4 h-4 animate-bounce" />
            )}
            {expanded ? 'Less' : 'More...'}
          </div>
        </div>
      )}
      {/* Radios */}
      <Transition
        show={showPreVetting && localData.can_apply && localData.user_state !== 3}
        enter="transition-all ease-in duration-150"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-96 opacity-100"
        leave="transition-all ease-out duration-500"
        leaveFrom="max-h-96 opacity-100"
        leaveTo="max-h-0 opacity-0"
        beforeLeave={() => jobRef?.current?.scrollIntoView({ behavior: 'smooth' })}
        className="overflow-hidden"
      >
        <div className="w-full flex flex-col">
          <p className="text-sm uppercase font-bold text-green-600 mb-2">
            {localData.user_state === 2 ? 'Your answers' : 'A few more questions'}
          </p>
          <div className="flex flex-col xl:flex-row mt-2 mb-2 xl:items-center">
            <div className="text-sm font-bold flex mr-0 md:mr-2 w-auto md:w-36 mb-2 xl:mb-0">
              I am available
            </div>
            <Selector
              options={['Full Time', 'Part Time']}
              values={['Full Time', 'Part Time']}
              selected={available}
              onSelect={(selectedValues: string[]) => setAvailable(selectedValues)}
              uppercase
              bold
              textSize="xs"
              color="blue"
              radio
              disabled={localData.user_state > 1}
            />
          </div>
          <div className="flex flex-col xl:flex-row mt-2 mb-2 xl:items-center">
            <div className="text-sm font-bold flex mr-0 md:mr-2 w-auto md:w-36 mb-2 xl:mb-0">
              I can start
            </div>
            <Selector
              options={['Now', '1 Week', '2 Weeks', '4 Weeks', 'More than 4 weeks']}
              values={['Now', '1 Week', '2 Weeks', '4 Weeks', 'More than 4 weeks']}
              selected={canStart}
              onSelect={(selectedValues: string[]) => setCanStart(selectedValues)}
              uppercase
              bold
              textSize="xs"
              color="blue"
              radio
              disabled={localData.user_state > 1}
            />
          </div>
          {localData.skills.map((skill: string) => (
            <div
              className="flex flex-col xl:flex-row mt-2 mb-2 xl:items-center"
              key={`experience-${skill}`}
            >
              <div className="text-sm font-bold flex mr-0 md:mr-2 w-auto xl:w-36 mb-2 xl:mb-0">
                Experience with {skill}
              </div>
              <Selector
                options={['None', '< 1 Year', '1-3 Year', '3-5 years', '5+ Years']}
                values={['None', '< 1 Year', '1-3 Year', '3-5 years', '5+ Years']}
                selected={experience[skill]}
                onSelect={(selectedValues: string[]) =>
                  setExperience({
                    ...experience,
                    [skill]: selectedValues,
                  })
                }
                uppercase
                bold
                textSize="xs"
                color="blue"
                radio
                disabled={localData.user_state > 1}
              />
            </div>
          ))}
          <div className="flex flex-col xl:flex-row mt-2 mb-2 xl:items-center">
            <div className="text-sm font-bold flex mr-0 md:mr-2 w-auto xl:w-36 mb-2 xl:mb-0">
              Overlap with job timezone
            </div>
            <Selector
              options={['2 hours', '3 hours', '6 hours', '8 hours', 'None']}
              values={['2 hours', '3 hours', '6 hours', '8 hours', 'None']}
              selected={overlap}
              onSelect={(selectedValues: string[]) => setOverlap(selectedValues)}
              uppercase
              bold
              textSize="xs"
              color="blue"
              radio={false}
              disabled={localData.user_state > 1}
            />
          </div>
          <div className="flex flex-col xl:flex-row mt-2 mb-2 xl:items-center">
            <div className="text-sm font-bold flex mr-0 md:mr-2 w-auto md:w-36 mb-2 xl:mb-0">
              Confirm your rate
            </div>
            <Selector
              options={[`${localData.rate.toString()} / hour`, 'Other']}
              values={[localData.rate.toString(), 'Other']}
              selected={rate}
              onSelect={(selectedValues: string[]) => setRate(selectedValues)}
              uppercase
              bold
              textSize="xs"
              color="blue"
              radio
              disabled={localData.user_state > 1}
            />
            {rate.length > 0 && rate[0] !== localData.rate.toString() && (
              <input
                className="px-2 py-1 border-2 rounded-lg m-1 border-blue-500 font-bold text-blue-500 text-xs"
                onChange={(e) => setRate(['Other', e.target.value])}
                name="rate"
                type="number"
                placeholder="Enter desired rate"
                disabled={localData.user_state > 1}
                defaultValue={rate[1]}
              />
            )}
          </div>
          <div className="text-sm font-bold mt-2 mb-2 ">
            Tell us why you are a good fit for this job:
          </div>
          <textarea
            disabled={localData.user_state > 1}
            className="border-blue-500 border-2 rounded"
            rows={4}
            onChange={(e) => setGoodFit(e.target.value)}
            value={goodFit}
          />

          {localData?.video_ask && localData.user_state <= 1 && (
            <>
              <div className="text-sm font-bold mt-2 mb-2 ">
                BONUS: Record a video and get noticed by top hiring managers:
              </div>
              {/* VideoAsk iframe */}
              <iframe
                title="VideoAsk question"
                src={`https://www.videoask.com/${localData.video_ask}`}
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
                width="100%"
                height="400px"
                style={{ border: 'none', borderRadius: '24px' }}
                className="mt-4 mb-4 sm:ml-0 sm:mr-0 md:ml-4 md:mr-4"
              />
            </>
          )}
          {localData.user_state <= 1 && localData.can_apply && (
            <div className="flex flex-col">
              {localData.apply_warning && (
                <div
                  className="p-2 mb-2 mt-5 bg-yellow-100 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex cursor-normal"
                  role="alert"
                >
                  <span className="hidden md:inline-flex flex rounded-full bg-yellow-500 uppercase px-2 py-1 text-xs font-bold mr-3 text-white">
                    Warning
                  </span>
                  <span className="md:hidden uppercase px-2 py-1 text-normal font-bold mr-3 text-yellow-600 md:text-white">
                    ⚠️
                  </span>
                  <span className="font-semibold mr-2 text-left flex-auto text-yellow-600 text-xs md:text-sm">
                    {localData.apply_warning}
                  </span>
                </div>
              )}
              <div className="flex flex-row items-center mt-3">
                <Button
                  content="Apply"
                  backgroundColor="green"
                  textColor="white"
                  onClick={applyInterested}
                  disabled={isLoading}
                  uppercase
                  bold
                  customClasses={`${isLoading ? 'animate-pulse' : ''}`}
                />
                <Button
                  content="Cancel"
                  backgroundColor="white"
                  textColor="red"
                  onClick={() => setPreVettingOpenUid(null)}
                  link
                  disabled={isLoading}
                  customClasses={`ml-3 text-sm ${isLoading ? 'animate-pulse' : ''}`}
                />
              </div>
            </div>
          )}
          {localData.user_state === 2 && (
            <div className="flex flex-row items-center mt-3">
              <Button
                content="Hide answers"
                backgroundColor="white"
                textColor="green"
                onClick={() => setPreVettingOpenUid(null)}
                disabled={isLoading}
                uppercase
                bold
                link
                customClasses={`${isLoading ? 'animate-pulse' : ''}`}
              />
            </div>
          )}
        </div>
      </Transition>

      <Transition
        show={showNotInterestedPreVetting && localData.can_apply && localData.user_state !== 2}
        enter="transition-all ease-in duration-150"
        enterFrom="max-h-0 opacity-0"
        enterTo="max-h-96 opacity-100"
        leave="transition-all ease-out duration-500"
        leaveFrom="max-h-96 opacity-100"
        leaveTo="max-h-0 opacity-0"
        beforeLeave={() => jobRef?.current?.scrollIntoView({ behavior: 'smooth' })}
      >
        <div className="w-full">
          <p className="text-sm uppercase font-bold text-red-500 mb-2">
            {localData.user_state === 3
              ? 'Your answer'
              : 'Please let us know why you are not interested'}
          </p>
          <div className="flex flex-col mt-2 mb-2">
            <RadioButtons
              groupName="not-interested-question"
              questions={NOT_INTERESTED_QUESTIONS}
              selectedValue={notInterestedAnswer}
              onSelect={(selectedValues: string) => setNotInterestedAnswer(selectedValues)}
              disabled={localData.user_state > 2}
            />
            {notInterestedAnswer === 'Other' && (
              <textarea
                disabled={localData.user_state > 1}
                className="px-2 py-1 border-2 rounded-lg m-1 border-blue-500 font-bold text-blue-500"
                rows={4}
                onChange={(e) => {
                  setNotInterestedOtherDesc(e.target.value);
                }}
                value={notInterestedOtherDesc}
              />
            )}
            {notInterestedAnswer === NOT_AVAILABLE && (
              <>
                <span className="px-2 mt-5 text-base font-medium text-black">
                  I'll be available in
                </span>
                <Menu as="div" className="relative m-1 w-48 z-10">
                  {({ open }) => (
                    <>
                      <Menu.Button className="inline-flex bg-white rounded border border-solid p-3 px-5 hover:bg-gray-50 justify-between w-full">
                        📅{' '}
                        {DAYS_UNAVAILABLE.get(daysUnavailable)?.label ??
                          DEFAULT_DAYS_UNAVAILABLE.label}
                        <span className="text-xl ml-4">
                          {open ? <FiChevronDown /> : <FiChevronUp />}
                        </span>
                      </Menu.Button>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          className={`
                          flex flex-col
                          absolute top-full left-0
                          my-2 border rounded bg-white
                          w-full
                        `}
                        >
                          {notAvailableOptions}
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              </>
            )}
          </div>

          {localData.user_state <= 1 && localData.can_apply && (
            <div className="flex flex-row items-center mt-3">
              <Button
                content="Submit"
                backgroundColor="green"
                textColor="white"
                onClick={applyNotInterested}
                disabled={isLoading}
                uppercase
                bold
                customClasses={`${isLoading ? 'animate-pulse' : ''}`}
              />
              <Button
                content="Cancel"
                backgroundColor="white"
                textColor="red"
                onClick={() => setPreVettingOpenUid(null)}
                link
                disabled={isLoading}
                customClasses={`ml-3 text-sm ${isLoading ? 'animate-pulse' : ''}`}
              />
            </div>
          )}
          {localData.user_state === 3 && (
            <div className="flex flex-row items-center mt-3">
              <Button
                content="Hide answer"
                backgroundColor="white"
                textColor="green"
                onClick={() => {
                  setPreVettingOpenUid(null);
                  setNotInterestedPreVettingOpen(false);
                }}
                disabled={isLoading}
                uppercase
                bold
                link
                customClasses={`${isLoading ? 'animate-pulse' : ''}`}
              />
            </div>
          )}
        </div>
      </Transition>

      {/* Actions */}
      {!showPreVetting && !showNotInterestedPreVetting && (
        <div className="flex flex-row items-center mt-3">
          {/* No answer given yet */}
          {localData.user_state <= 1 && !interestedSubmitted && !notInterestedSubmitted && (
            <div className="flex flex-col">
              {localData.apply_warning && (
                <div
                  className="p-2 mb-3 bg-yellow-100 items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex cursor-normal"
                  role="alert"
                >
                  <span className="hidden md:inline-flex flex rounded-full bg-yellow-500 uppercase px-2 py-1 text-xs font-bold mr-3 text-white">
                    Warning
                  </span>
                  <span className="md:hidden uppercase px-2 py-1 text-normal font-bold mr-3 text-yellow-600 md:text-white">
                    ⚠️
                  </span>
                  <span className="font-semibold mr-2 text-left flex-auto text-yellow-600 text-xs md:text-sm">
                    {localData.apply_warning}
                  </span>
                </div>
              )}
              <div>
                <Button
                  content={"I'm Interested"}
                  backgroundColor="green"
                  textColor="white"
                  onClick={interestedPreVet}
                  disabled={isLoading || !localData.can_apply || !selectedDev?.uid}
                  noLockIcon={false}
                  uppercase
                  bold
                  customClasses={`${isLoading ? 'animate-pulse' : ''}`}
                />
                {localData.can_apply && (
                  <Button
                    content="Not interested"
                    backgroundColor="white"
                    textColor="red"
                    onClick={notInterestedPreVet}
                    link
                    disabled={isLoading || !localData.can_apply || !selectedDev?.uid}
                    customClasses={`ml-3 text-sm ${isLoading ? 'animate-pulse' : ''}`}
                  />
                )}
              </div>
            </div>
          )}
          {(localData.user_state === 2 || localData.user_state === 3) &&
            localData.pre_vetting_answers && (
              <Button
                content="Show my answers"
                backgroundColor="green"
                textColor="white"
                onClick={() => {
                  setPreVettingOpenUid(localData.uid);
                  if (localData.user_state === 3) {
                    setNotInterestedPreVettingOpen(true);
                  }
                }}
                disabled={
                  isLoading ||
                  !localData.can_apply ||
                  !localData.pre_vetting_answers ||
                  !selectedDev?.uid
                }
                uppercase
                bold
                customClasses={`${isLoading ? 'animate-pulse' : ''}`}
              />
            )}

          {localData.user_state <= 1 && (interestedSubmitted || notInterestedSubmitted) && (
            <Button
              content={interestedSubmitted ? 'Applied' : 'Not interested'}
              backgroundColor={interestedSubmitted ? 'green' : 'red'}
              disabled
              noLockIcon
              textColor="white"
              uppercase
              bold
            />
          )}
        </div>
      )}
    </div>
  );
});
