import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../constants';
import {
  defaultGetJobsResponse,
  defaultGetSingleJobResponse,
  IGetJobsRequest,
  IGetJobsResponse,
  IGetSingleJobResponse,
  IJobInterestedRequest,
} from '../interfaces/job';
import { defaultEmptyResponse, IDefaultEmptyResponse } from '../interfaces/general';

const jobsService = {
  getJobs: async (payload: IGetJobsRequest) => {
    try {
      const res: AxiosResponse<IGetJobsResponse> = await axios.post(
        `${API_URL}/agency/jobs`,
        payload
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultGetJobsResponse;
    }
  },
  getSingleJob: async (uid: string, developer_uid: string) => {
    try {
      const res: AxiosResponse<IGetSingleJobResponse> = await axios.get(
        `${API_URL}/agency/jobs/${developer_uid}/${uid}`
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultGetSingleJobResponse;
    }
  },
  setInterest: async (payload: IJobInterestedRequest) => {
    try {
      const res: AxiosResponse<IDefaultEmptyResponse> = await axios.post(
        `${API_URL}/agency/jobs/save-interest`,
        payload
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultEmptyResponse;
    }
  },
};

export default jobsService;
