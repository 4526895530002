import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';

import {
  IGetTimePeriodsResponse,
  IGetBillsRequest,
  IGetBillsResponse,
  ISubmitBillRequest,
  ISubmitBillResponse,
  ITimePeriodResponseItem,
  defaultGetBillsRequest,
  ICustomInvoiceResponseItem,
  IJobTimePeriodResponse,
  TJobTimePeriodMap,
  ITimePeriodJob,
  IBillResponseItem,
} from '../interfaces/bills';
import { sortJobs } from '../utils';

export const BILL_SUBMIT_ERROR = 'BillSubmitError';
export const FETCH_TIME_PERIOD_ERROR = 'FetchTimePeriodError';
export const FETCH_BILLS_ERROR = 'FetchBillsError';

class BillSubmitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = BILL_SUBMIT_ERROR;
  }
}

class FetchBillsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = FETCH_BILLS_ERROR;
  }
}

class FetchTimePeriodError extends Error {
  constructor(message: string) {
    super(message);
    this.name = FETCH_TIME_PERIOD_ERROR;
  }
}

const billsService = {
  getTimePeriods: async (developerUid: string) => {
    try {
      const res: AxiosResponse<IGetTimePeriodsResponse> = await axios.get(
        `${API_URL}/agency/bills/create?developer_uid=${developerUid}`
      );

      const time_periods = serializeJobTimePeriods(res.data.time_periods);
      const custom_items = serializeCustomItems(res.data.custom_items ?? []);

      return { ...res.data, ...time_periods, custom_items };
    } catch (e) {
      throw new FetchTimePeriodError('Could not retrieve time period data');
    }
  },
  getBills: async (payload: IGetBillsRequest = defaultGetBillsRequest) => {
    try {
      const res: AxiosResponse<IGetBillsResponse> = await axios.post(
        `${API_URL}/agency/bills`,
        payload
      );

      const list = serializeBills(res.data.list);

      return { ...res.data, list };
    } catch (e) {
      throw new FetchBillsError('Could not get bills');
    }
  },
  submitBill: async (payload: ISubmitBillRequest) => {
    try {
      const res: AxiosResponse<ISubmitBillResponse> = await axios.post(
        `${API_URL}/agency/bills/create`,
        payload
      );

      return res;
    } catch (e) {
      throw new BillSubmitError('Could not submit bill');
    }
  },
};

const serializeBills = (bills: IBillResponseItem[]) => {
  const serializedBills = bills.map((bill) => {
    const billCopy = { ...bill };
    const createdAt = new Date(0);
    createdAt.setUTCSeconds(billCopy.created_at);
    billCopy.number = billCopy.number.replace(/(BILL-)?/g, '');

    return { ...billCopy, created_at: createdAt };
  });

  return serializedBills;
};

const serializeCustomItems = (customItems: ICustomInvoiceResponseItem[]) => {
  const serializedItems = new Map(customItems.map((item) => [item.id, item]));

  return serializedItems;
};

const serializeJobTimePeriods = (jobTimePeriods: IJobTimePeriodResponse[]) => {
  const jobTimePeriodMap: TJobTimePeriodMap = new Map();
  let jobs: ITimePeriodJob[] = [];

  jobTimePeriods.forEach((rawTimePeriod) => {
    const { company_name, job_uid, title } = rawTimePeriod;
    const job = { company_name, job_uid, title };
    const timePeriod = serializeTimePeriods(rawTimePeriod.time_periods);

    jobs.push(job);
    jobTimePeriodMap.set(job.job_uid, timePeriod);
  });

  jobs = sortJobs(jobs) as ITimePeriodJob[];

  return { jobs, jobTimePeriodMap };
};

const serializeTimePeriods = (timePeriods: ITimePeriodResponseItem[]) => {
  const serializedPeriods = timePeriods.map((period) => {
    const periodCopy = { ...period };

    periodCopy.worked = periodCopy.worked.replace(/\n\s?,?/g, '<br>');
    periodCopy.total = Math.floor(periodCopy.total * 100);
    periodCopy.total /= 100;

    const {
      can_bill: canBill,
      period_status: { label: status },
      quantity: hours,
      rate,
      total,
      uid,
      worked,
    } = periodCopy;

    return {
      canBill,
      hours,
      rate,
      status,
      total,
      uid,
      worked,
    };
  });

  return serializedPeriods.reverse();
};

export default billsService;
