import { observer } from 'mobx-react';
import { FC, useEffect } from 'react';
import { PayoutMethod } from '../components/Payout/Method';

import { ACTIVE_STATUS } from '../interfaces/payout-methods';
import { useRootStore } from '../providers/RootStoreProvider';

export const PayoutMethodsPage: FC = observer(() => {
  const {
    payoutMethodsStore: {
      errors,
      fetchingAvailablePayoutMethods,
      fetchingCurrentPayoutMethods,
      fetchAvailablePayoutMethods,
      fetchCurrentPayoutMethods,
      redirects,
      availablePayoutMethods,
      currentPayoutMethods,
    },
  } = useRootStore();

  useEffect(() => {
    fetchCurrentPayoutMethods();
    fetchAvailablePayoutMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className="max-w-3xl w-full text-sm pb-14 space-y-8">
      <header className="mb-5">
        <h2 className="text-3xl font-bold mb-5">Payment Methods</h2>
        <p className="text-black translate">Your place to setup and receive payments</p>
      </header>

      <section>
        <h3 className="font-bold text-xl mb-4">Current Payout Methods</h3>
        <div className="flex flex-wrap">
          {fetchingCurrentPayoutMethods ? (
            <>
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
            </>
          ) : errors?.currentPayoutMethods?.length ? (
            <div className="relative rounded font-bold bg-red-300 bg-opacity-30 p-10 max-w-lg">
              <span className="absolute top-3 left-3"> ⚠️ </span>
              <p>
                Sorry, we could not retreive your current payout methods.
                <br />
                Please try again.
              </p>
            </div>
          ) : currentPayoutMethods.length ? (
            currentPayoutMethods?.map(({ status, name, id }) => (
              <PayoutMethod
                key={name}
                active={status === ACTIVE_STATUS}
                name={name}
                redirect={redirects.filter((service) => service.id === id)[0]?.redirect_to}
              />
            ))
          ) : (
            <p>No current payout methods</p>
          )}
        </div>
      </section>

      <section>
        <h3 className="font-bold text-xl mb-4">Available Payout Methods</h3>
        <div className="flex">
          {fetchingAvailablePayoutMethods ? (
            <>
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
              <div className="animate-pulse rounded bg-gray-300 mr-8 h-20 w-40" />
            </>
          ) : errors?.availablePayoutMethods.length ? (
            <div className="relative rounded font-bold bg-red-300 bg-opacity-30 p-10 max-w-lg">
              <span className="absolute top-3 left-3"> ⚠️ </span>
              <p>
                Sorry, we could not retreive the available payout methods.
                <br />
                Please try again.
              </p>
            </div>
          ) : availablePayoutMethods.length ? (
            availablePayoutMethods?.map(({ name }) => (
              <PayoutMethod key={name} available name={name} />
            ))
          ) : (
            <p>No available payout methods</p>
          )}
        </div>
      </section>
    </main>
  );
});
