import { useLocation } from 'react-router';
import { ITimePeriodJob } from '../interfaces/bills';
import { ITimesheetsJob } from '../interfaces/timesheets';

export const useQuery: any = () => new URLSearchParams(useLocation().search);

type TJob = ITimesheetsJob | ITimePeriodJob;
export const sortJobs = (jobs: TJob[]) => {
  if (!jobs?.length) return [];

  return jobs.sort((a, b) => {
    const nameA = a.company_name.toUpperCase();
    const nameB = b.company_name.toUpperCase();

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;

    return 0;
  });
};

export const capitalize = (s: string) =>
  s.charAt(0).toUpperCase() + s.slice(1).replaceAll('-', ' ');
export const base64ToBlob = (base64: string) => {
  const block = base64.split(';');
  const type = block[0].split(':')[1];
  const realData = block[1].split(',')[1];
  const sliceSize = 512;

  const byteCharacters = atob(realData);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type });
};

export const publicRecaptchaKey = '6Lc69cYcAAAAAATwy8d_Bzux-kOBb9Rq52BX_zmE';

export const numToCurrency = (num?: number) => {
  if (num === undefined) return '';

  return num.toLocaleString(undefined, { style: 'currency', currency: 'USD' });
};

export const uniqBy = (array: Array<{ [key: string]: any }>, key: string) => {
  return [...new Map(array.map((item) => [item[key], item])).values()];
};
