import * as yup from 'yup';

import { FC, useRef, useState, useEffect, useCallback } from 'react';

import Avatar from 'react-avatar';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Autocomplete, Input } from '@upstackhq/component-library';
import { AvatarConfig } from '../AvatarConfig/AvatarConfig';
import { IUpdateAboutSectionRequest } from '../../interfaces/profile';
import { base64ToBlob } from '../../utils';
import { countries } from '../../constants';
import { useRootStore } from '../../providers/RootStoreProvider';

type props = {};
export const AboutSection: FC<props> = observer(() => {
  const {
    profileStore: {
      profileData: { about },
      updateAboutSection,
      updateAvatar,
      saving,
      loading,
    },
  } = useRootStore();
  const avatarInput = useRef<HTMLInputElement>(null);
  const [uploadedAvatar, setUploadedAvatar] = useState<any>('');
  const [avatar, setAvatar] = useState<string>('');
  const validationSchema = yup.object().shape({
    first_name: yup.string().required('First name is required'),
    last_name: yup.string().required('Last name is required'),
    email: yup.string().email('Email is invalid').required('Email is required'),
    headline: yup.string().required('Headline is required'),
    country: yup.string().required('Country is required'),
    city: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const handleCountrySelect = useCallback(
    (country: string) => {
      setValue('country', country, { shouldValidate: true });
    },
    [setValue]
  );

  useEffect(() => {
    if (about?.country) {
      handleCountrySelect(about.country);
    }
  }, []);

  const onSubmitHandler = (payload: IUpdateAboutSectionRequest) => {
    updateAboutSection(payload).then(() => {
      toast.success('Profile successfully updated.');
    });
  };

  const handleAvatarChange = (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setUploadedAvatar(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleAvatarUpload = (source: string) => {
    updateAvatar(base64ToBlob(source)).then(() => {
      toast.success('Avatar successfully updated.');
    });
  };

  const { onChange: onFirstNameChange, ref: firstNameRef } = register('first_name');
  const { onChange: onLastNameChange, ref: lastNameRef } = register('last_name');
  const { onChange: onEmailChange, ref: emailRef } = register('email');
  const { onChange: onHeadlineChange, ref: headlineRef } = register('headline');
  const { onChange: onCityChange, ref: cityRef } = register('city');

  return (
    <div>
      <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">About</h2>
      {/* Profile image */}
      <div className="mb-7">
        <h4 className="font-bold text-base mb-3">Profile pic *</h4>
        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start">
          <Avatar
            className="w-32 h-32 sm:w-24 sm:h-24 sm:mr-10 rounded-full mb-5 sm:mb-0"
            name={about?.first_name ? `${about?.first_name} ${about?.last_name}` : 'N N'}
            src={avatar || about?.avatar}
            color="#018FF4"
            alt="Avatar"
            round
          />
          <input
            ref={avatarInput}
            onChange={handleAvatarChange}
            type="file"
            style={{ display: 'none' }}
            accept="image/jpg, image/jpeg, image/png"
          />
          <Button
            content="Upload a new photo"
            uppercase
            backgroundColor="green"
            textColor="white"
            bold
            isLoading={saving.avatar}
            disabled={saving.avatar}
            noLockIcon
            onClick={() => {
              // @ts-ignore
              avatarInput.current.click();
            }}
          />
          {uploadedAvatar && (
            <AvatarConfig
              image={uploadedAvatar}
              onComplete={(source) => {
                setAvatar(source);
                setUploadedAvatar('');
                // @ts-ignore
                avatarInput.current.value = '';
                handleAvatarUpload(source);
              }}
              onClose={() => {
                setAvatar('');
                setUploadedAvatar('');
                // @ts-ignore
                avatarInput.current.value = '';
              }}
            />
          )}
        </div>
      </div>
      <div className="mb-7">
        {about && (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Input
              onChange={onFirstNameChange}
              inputRef={firstNameRef}
              error={errors.first_name?.message}
              defaultValue={about?.first_name}
              name="first_name"
              label="First Name"
              required
            />
            <Input
              onChange={onLastNameChange}
              inputRef={lastNameRef}
              error={errors.last_name?.message}
              defaultValue={about?.last_name}
              name="last_name"
              label="Last Name"
              required
            />
            <Input
              onChange={onEmailChange}
              inputRef={emailRef}
              error={errors.email?.message}
              defaultValue={about?.email}
              name="email"
              label="Email"
              required
            />
            <Input
              onChange={onHeadlineChange}
              inputRef={headlineRef}
              error={errors.headline?.message}
              defaultValue={about?.headline}
              name="headline"
              label="Headline"
              required
            />
            <Autocomplete
              data={countries.map((country) => ({
                value: country,
                label: country,
              }))}
              value={about?.country}
              error={errors.country?.message}
              onChange={handleCountrySelect}
              label="Country *"
              innerBackgroundColor="white"
              noOuterPadding
            />
            <Input
              onChange={onCityChange}
              inputRef={cityRef}
              error={errors.city?.message}
              defaultValue={about?.city}
              name="city"
              label="City"
              required
            />
            <div className="flex justify-end mt-5">
              <Button
                type="submit"
                content="Save"
                uppercase
                backgroundColor="green"
                textColor="white"
                bold
                isLoading={saving.about}
                noLockIcon
                disabled={loading || saving.about}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
});
