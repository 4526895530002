import React, { FC, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Button } from '@upstackhq/component-library';
import { EducationForm } from '../Forms/EducationForm';

type props = {
  education: {
    uid: string;
    institution: string;
    degree: string;
    location: string;
    start_date: number;
    end_date: number;
    current: boolean;
  };
};
export const EducationInfoBox: FC<props> = (props) => {
  const [editEducation, setEditEducation] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (editEducation) {
    return (
      <div className="mb-5">
        <EducationForm
          education={props.education}
          onClose={() => {
            setEditEducation(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onComplete={() => {
            setEditEducation(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
        />
      </div>
    );
  }

  return (
    <div className="p-3 border-2 border-blue-100 rounded-md mb-5" ref={scrollRef}>
      <div className="flex justify-between items-center">
        <h4 className="font-bold text-lg">{props.education?.degree}</h4>
        <Button
          content="Edit"
          backgroundColor="transparent"
          textColor="gray"
          link
          customClasses="pb-0 pt-0 pl-0 pr-0"
          onClick={() => {
            setEditEducation(true);
          }}
        />
      </div>
      <p className="text-sm pb-2">
        {props.education?.institution} • {props.education?.location}
      </p>
      <p className="text-blue-muted text-sm pb-2">
        {props.education?.start_date
          ? format(new Date(props.education?.start_date * 1000), 'MMM yyyy')
          : 'Unknown'}{' '}
        to{' '}
        {props.education?.end_date
          ? format(new Date(props.education.end_date * 1000), 'MMM yyyy')
          : !props.education.current
          ? 'Unknown'
          : 'Present'}
      </p>
    </div>
  );
};
