import * as yup from 'yup';

import { FC, useEffect, useRef, useState, useMemo } from 'react';

import Avatar from 'react-avatar';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';
import { useForm } from 'react-hook-form';
import { v4 as uuid_v4 } from 'uuid';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDropzone } from 'react-dropzone';
import { Button, Autocomplete, Input } from '@upstackhq/component-library';

import { AvatarConfig } from '../AvatarConfig/AvatarConfig';
import { ICareerItem } from '../../interfaces/careers';
import { IUserData } from '../../interfaces/developers';
import { Select } from '../../common/Select/Select';
import Selector from '../../api/selector';
import UtilsService from '../../api/utils';
import { base64ToBlob } from '../../utils';
import { countries } from '../../constants';

const EXPERIENCE_MAP = [
  { time: 'Less than 3 years', value: 2 },
  { time: '3 years', value: 3 },
  { time: '4 years', value: 4 },
  { time: '5 years', value: 5 },
  { time: 'More than 5 years', value: 6 },
];

type props = {
  data?: IUserData;
  nextStep?: boolean;
  onComplete?: Function;
  onInvalid?: () => void;
};
export const GeneralSection: FC<props> = observer((props) => {
  const { nextStep, onInvalid, onComplete, data } = props;
  const [careers, setCareers] = useState<ICareerItem[]>([]);
  const avatarInput = useRef<HTMLInputElement>(null);
  const [uploadedAvatar, setUploadedAvatar] = useState<any>('');
  const [avatar, setAvatar] = useState<string>(props.data?.photo || '');
  const validationSchema = yup.object().shape({
    name: yup.string().required('First name is required'),
    personal_email: yup.string().email('Email is invalid').required('Email is required'),
    phone: yup.string(),
    city: yup.string(),
    country: yup.string().required('Country is required'),
    linked_in: yup.string(),
    github: yup.string(),
    applied_for: yup.string().required('Applied for is required'),
    yearsOfExp: yup.string().required('Years of experience is required'),
    hasResume: yup.boolean().required('Resume is required'),
  });

  const onDrop = <T extends File>(acceptedFiles: T[]) => {
    if (!acceptedFiles.length) return;

    setValue('hasResume', true);
    clearErrors('hasResume');
  };

  const { acceptedFiles, getInputProps, getRootProps } = useDropzone({
    accept: 'application/pdf',
    maxFiles: 1,
    onDrop,
  });

  useEffect(() => {
    getSkills();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    if (data?.country) {
      setValue('country', data.country);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (data?.resume) {
      setValue('hasResume', true);
    }
  }, [data, setValue]);

  useEffect(() => {
    if (nextStep) {
      handleSubmit(onSubmitHandler, onInvalid)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  const files = useMemo(() => {
    const previewFiles =
      acceptedFiles.length > 0 ? acceptedFiles : data?.resume ? [data.resume] : [];

    return previewFiles.map((file) => (
      <span key={file.name}>
        {file.name} - {file.size} bytes
      </span>
    ));
  }, [acceptedFiles, data?.resume]);

  const getSkills = () => {
    try {
      Selector.careers().then(({ job_openings }) => {
        setCareers(job_openings);
      });
    } catch (e) {}
  };

  const extractPreviousResumeFields = () => {
    if (!data) return {};
    const { resume, resume_url, resume_name } = data;

    return { resume, resume_url, resume_name };
  };

  const onSubmitHandler = async (payload: any) => {
    if (onComplete && (acceptedFiles.length || data?.resume)) {
      let resumeUrl = null;
      if (acceptedFiles.length) {
        const file = acceptedFiles[0];
        const formData = new FormData();

        formData.append('file', file, `CV-${file.name}`);
        ({ url: resumeUrl } = await UtilsService.uploadMedia(formData));
      }

      onComplete({
        ...payload,
        yearsOfExp: undefined,
        photo: avatar,
        applied_for: {
          uid: careers?.find((item) => item.uid === payload.applied_for)?.uid,
          title: careers?.find((item) => item.uid === payload.applied_for)?.title,
          years: payload.yearsOfExp,
        },
        ...(resumeUrl && acceptedFiles?.[0]
          ? {
              resume: acceptedFiles[0],
              resume_url: resumeUrl,
              resume_name: `CV-${acceptedFiles[0].name}`,
            }
          : extractPreviousResumeFields()),
      });
    }
  };

  const handleAvatarChange = (event: any) => {
    const reader = new FileReader();
    const file = event.target.files[0];
    reader.onloadend = () => {
      setUploadedAvatar(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleAvatarUpload = async (source: string) => {
    try {
      const formData = new FormData();
      formData.append('file', base64ToBlob(source), `${uuid_v4()}.jpeg`);
      const avatarUrl = await UtilsService.uploadMedia(formData);
      runInAction(() => {
        setAvatar(avatarUrl.url);
      });
    } catch (e: any) {}
  };

  const { onChange: onNameChange, ref: nameRef } = register('name');
  const { onChange: onPersonalEmailChange, ref: personalEmailRef } = register('personal_email');
  const { onChange: onPhoneChange, ref: phoneRef } = register('phone');
  const { onChange: onCityChange, ref: cityRef } = register('city');
  const { onChange: onLinkedinChange, ref: linkedinRef } = register('linked_in');
  const { onChange: onGithubChange, ref: githubRef } = register('github');

  return (
    <div>
      <div className="mb-7">
        <div className="flex flex-col sm:flex-row items-center justify-center sm:justify-start">
          <Avatar
            className="w-32 h-32 sm:w-24 sm:h-24 sm:mr-10 rounded-full mb-5 sm:mb-0"
            src={avatar || data?.photo}
            name={getValues('name')}
            color="#018FF4"
            alt="Avatar"
            round
          />
          <input
            ref={avatarInput}
            onChange={handleAvatarChange}
            type="file"
            style={{ display: 'none' }}
            accept="image/jpg, image/jpeg, image/png"
          />
          <Button
            content="Upload avatar"
            uppercase
            backgroundColor="green"
            textColor="white"
            bold
            isLoading={false}
            disabled={false}
            noLockIcon
            onClick={() => {
              // @ts-ignore
              avatarInput.current.click();
            }}
          />
          {uploadedAvatar && (
            <AvatarConfig
              image={uploadedAvatar}
              onComplete={(source: any) => {
                setAvatar(source);
                setUploadedAvatar('');
                // @ts-ignore
                avatarInput.current.value = '';
                handleAvatarUpload(source);
              }}
              onClose={() => {
                setAvatar('');
                setUploadedAvatar('');
                // @ts-ignore
                avatarInput.current.value = '';
              }}
            />
          )}
        </div>
      </div>

      <div className="mb-7">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          {careers && careers.length > 0 && (
            <>
              <Select
                defaultValue={data?.applied_for?.uid}
                error={errors.applied_for?.message}
                name="applied_for"
                placeholder="Select Applied for*"
                register={register}
              >
                <option value="" hidden label="Select Applied for*" />
                {careers.length ? (
                  careers.map((skill: ICareerItem, index) => (
                    <option key={index} value={skill.uid}>
                      {skill.title}
                    </option>
                  ))
                ) : (
                  <option disabled label="Loading..." />
                )}
              </Select>

              <Select
                defaultValue={data?.applied_for?.years}
                error={errors.yearsOfExp?.message}
                name="yearsOfExp"
                placeholder="Years of experience*"
                register={register}
              >
                <option value="" hidden label="Total years of experience*" />
                {EXPERIENCE_MAP.map(({ time, value }) => (
                  <option key={value} value={value}>
                    {time}
                  </option>
                ))}
              </Select>
            </>
          )}

          <Input
            onChange={onNameChange}
            inputRef={nameRef}
            error={errors.name?.message}
            defaultValue={data?.name}
            name="name"
            placeholder="Name*"
            required
          />
          <Input
            onChange={onPersonalEmailChange}
            inputRef={personalEmailRef}
            error={errors.personal_email?.message}
            defaultValue={data?.personal_email}
            name="personal_email"
            placeholder="E-mail*"
            required
          />
          <Input
            onChange={onPhoneChange}
            inputRef={phoneRef}
            error={errors.phone?.message}
            defaultValue={data?.phone}
            name="phone"
            placeholder="Phone"
          />

          <h2 className="mb-2 mt-3 uppercase font-bold text-gray-400 tracking-wide">Location</h2>
          <Autocomplete
            data={countries.map((country) => ({
              value: country,
              label: country,
            }))}
            value={data?.country}
            error={errors.country?.message}
            onChange={(value: string) => setValue('country', value, { shouldValidate: true })}
            label="Country*"
            innerBackgroundColor="white"
            noOuterPadding
          />
          <Input
            onChange={onCityChange}
            inputRef={cityRef}
            error={errors.city?.message}
            defaultValue={data?.city}
            name="city"
            placeholder="City"
          />

          <h2 className="mb-2 mt-3 uppercase font-bold text-gray-400 tracking-wide">Social</h2>
          <Input
            onChange={onLinkedinChange}
            inputRef={linkedinRef}
            error={errors.linked_in?.message}
            defaultValue={data?.linked_in}
            name="linked_in"
            placeholder="Linkedin"
          />
          <Input
            onChange={onGithubChange}
            inputRef={githubRef}
            error={errors.github?.message}
            defaultValue={data?.github}
            name="github"
            placeholder="Github"
          />
          <div
            {...getRootProps({
              className: `w-full border cursor-pointer border-gray-300 rounded mb-2 px-3 py-2 ${
                errors.hasResume?.message ? 'border-red-500 border-2' : ''
              }`,
            })}
          >
            <input {...getInputProps()} />
            <p className="text-gray-500">Upload Resume*</p>
          </div>
          <p>{files}</p>
          <p className="text-xs mb-1 mt-2 pl-1 text-red-500 overflow-hidden transition-all">
            {errors.hasResume?.message}
          </p>
        </form>
      </div>
    </div>
  );
});
