import { FC, useCallback, useMemo } from 'react';

import { IBillItem } from '../../interfaces/bills';
import { numToCurrency } from '../../utils';

type TBillRowProps = {
  bill: IBillItem;
};

export const BillRow: FC<TBillRowProps> = (props: TBillRowProps) => {
  const {
    bill: { created_at: createdAt, pdf, total, state_literal: status },
  } = props;

  const totalCurrency = numToCurrency(total);

  const ClickableWrapper: FC = useCallback(
    ({ children }) => (
      <a
        className="relative grid grid-cols-4 p-1 last:border-b-0 border-b border-gray-300 roll-over-effect cursor-pointer"
        href={pdf}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    ),
    [pdf]
  );

  const StaticWrapper: FC = ({ children }) => (
    <div className="relative grid grid-cols-4 p-1 last:border-b-0 border-b border-gray-300 roll-over-effect">
      {children}
    </div>
  );

  const Wrapper = useMemo(
    () => (pdf.length ? ClickableWrapper : StaticWrapper),
    [ClickableWrapper, pdf.length]
  );

  return (
    <Wrapper>
      <div className="flex items-center">{createdAt.toLocaleDateString()}</div>
      <div className="flex items-center">{totalCurrency}</div>
      <div className="flex items-center font-bold text-blue-500">{status}</div>
      <div className="flex items-center font-bold">View PDF</div>
    </Wrapper>
  );
};
