import React, { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@upstackhq/component-library';
import { observer } from 'mobx-react';
import { Select } from '../../common/Select/Select';
import { IDeveloperData } from '../../interfaces/developers';
import { DEFAULT_DAYS_UNAVAILABLE, DAYS_UNAVAILABLE } from '../../interfaces/user';

type props = {
  data?: IDeveloperData;
  nextStep?: boolean;
  onComplete?: Function;
  onInvalid?: () => void;
};
export const AvailabilitySection: FC<props> = observer((props) => {
  const { nextStep, onInvalid, onComplete, data } = props;
  const validationSchema = yup.object().shape({
    bio: yup.string(),
    rate: yup.number(),
    available: yup.number().required('Available is required').typeError('Available is required'),
    days_unavailable: yup.number().when('available', {
      is: 0,
      then: yup
        .number()
        .required('Not available period is required')
        .typeError('Not available period is required'),
    }),
    availability: yup
      .number()
      .required('Work hours are required')
      .typeError('Work hours are required'),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const available = watch('available');

  useEffect(() => {
    if (nextStep) {
      handleSubmit(onSubmitHandler, onInvalid)();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  const onSubmitHandler = (payload: IDeveloperData) => {
    if (!onComplete) return;

    const daysUnavailable = getValues('days_unavailable');
    const daysUnavailableNumber = +daysUnavailable;
    let available_from: Date | string = '';
    const shouldHaveDate =
      !isNaN(daysUnavailableNumber) &&
      daysUnavailableNumber !== DEFAULT_DAYS_UNAVAILABLE.days &&
      +available !== 1;

    if (shouldHaveDate) {
      const date = new Date();
      date.setDate(date.getDate() + daysUnavailableNumber);
      available_from = date.toISOString();
    }

    onComplete({
      ...payload,
      available_from,
      days_unavailable: shouldHaveDate ? daysUnavailableNumber : DEFAULT_DAYS_UNAVAILABLE.days,
    });
  };

  const { onChange: onRateChange, ref: rateRef } = register('rate');

  return (
    <div>
      <div className="mb-7">
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Select
            defaultValue={data?.available ?? 1}
            error={errors.available?.message}
            name="available"
            placeholder="Select Available*"
            register={register}
            required
          >
            <option value="" hidden label="Select Available*" />
            <option value={1} label="Available now" />
            <option value={0} label="Not available now" />
          </Select>

          {(+available === 0 || (data?.available === 0 && typeof available === 'undefined')) && (
            <Select
              defaultValue={data?.days_unavailable}
              error={errors.days_unavailable?.message}
              name="days_unavailable"
              placeholder="Select Not Available Period*"
              register={register}
              required
            >
              <option value="" hidden label="Select Not Available Period*" />
              {[...DAYS_UNAVAILABLE].map(([days, dayObject]) => {
                return (
                  <option key={`days-${days}`} value={dayObject?.days} label={dayObject?.label} />
                );
              })}
            </Select>
          )}

          <Select
            defaultValue={data?.availability}
            error={errors.availability?.message}
            name="availability"
            placeholder="Select Work hours*"
            register={register}
            required
          >
            <option value="" hidden label="Select Work hours*" />
            <option value={40} label="Full-Time" />
            <option value={20} label="Part-Time" />
          </Select>
          <Input
            onChange={onRateChange}
            inputRef={rateRef}
            type="number"
            error={errors.phone?.message}
            defaultValue={data?.rate}
            name="rate"
            placeholder="Enter desired rate US$*"
          />
        </form>
      </div>
    </div>
  );
});
