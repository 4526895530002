import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, InfoBox } from '@upstackhq/component-library';
import { EducationForm } from './Forms/EducationForm';
import { EducationInfoBox } from './InfoBoxes/EducationInfoBox';
import { IEducation } from '../../interfaces/developers';

type props = {
  data?: IEducation[];
  onComplete?: Function;
  onInvalid?: () => void;
  nextStep?: boolean;
  onUpdate?: Function;
};
export const EducationSection: FC<props> = observer((props) => {
  const { data, onComplete, nextStep, onUpdate } = props;
  const [newEducations, setNewEducations] = useState<{ key: number }[]>([]);
  const [education, setEducation] = useState<IEducation[]>([]);

  useEffect((): void => {
    if (data?.length) {
      setEducation(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextStep) {
      if (onComplete) {
        onComplete(education);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep]);

  const onAddNewEducation = () => {
    setNewEducations([
      ...newEducations,
      { key: newEducations.length ? newEducations[newEducations.length - 1].key + 1 : 1 },
    ]);
  };

  const onRemoveEducation = (index: number) => {
    setNewEducations(newEducations.filter(({ key: k }) => k !== index));
    setEducation(education.filter((education: IEducation, i: number) => i !== index));
    onUpdate && onUpdate(education.filter((education: IEducation, i: number) => i !== index));
  };
  return (
    <div>
      {education.length === 0 && (
        <InfoBox
          title="Enter education details"
          description="You can add multiple education details. Start by clicking on the add new education button below, or Skip button to go to the next section."
          type="warning"
        />
      )}
      <div className="flex justify-end mb-3">
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewEducation}
        />
      </div>

      {education.map((edu: IEducation, index: number) => (
        <EducationInfoBox
          index={index}
          key={index}
          education={edu}
          onRemove={onRemoveEducation}
          onEdit={(data: IEducation, index: number) => {
            const updated = education.map((education: IEducation, i: number) => {
              if (i === index) {
                return data;
              }
              return education;
            });
            setEducation(updated);
            onUpdate && onUpdate(updated);
          }}
        />
      ))}
      {/**/}
      {newEducations.map(({ key }) => (
        <EducationForm
          key={key}
          onClose={() => setNewEducations(newEducations.filter(({ key: k }) => k !== key))}
          onComplete={(data: IEducation) => {
            setNewEducations(newEducations.filter(({ key: k }) => k !== key));
            setEducation([...education, data]);
            onUpdate && onUpdate([...education, data]);
          }}
        />
      ))}
    </div>
  );
});
