import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Pagination } from '@upstackhq/component-library';
import { Error } from '../components/Notifications/Error';
import { Row as TimesheetRow } from '../components/Timesheet/Row';
import { useRootStore } from '../providers/RootStoreProvider';
import { NewTimesheetForm } from '../components/Timesheet/NewTimesheetForm';

import '../utils/css/timesheets.css';

import {
  emptyTimesheetJob,
  emptyTimesheetObject,
  ITimesheetsJob,
  ITimesheetsObject,
  NULL_JOB_ID,
} from '../interfaces/timesheets';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';

const TIMESHEETS_PER_PAGE = 20;

export const TimesheetsPage: FC = observer(() => {
  const history = useHistory();
  const {
    timesheetsStore: {
      getTimesheets,
      jobs,
      paginationInfo,
      pendingTimesheets,
      postTimesheets,
      timesheets,
      timesheetsError,
    },
    myDevsStore: { selectedDev },
  } = useRootStore();

  const [editTimesheet, setEditTimesheet] = useState<ITimesheetsObject>(emptyTimesheetObject);
  const [timesheetIsPending, setTimesheetIsPending] = useState<boolean>(false);
  const [selectedJob, setSelectedJob] = useState<ITimesheetsJob>(emptyTimesheetJob);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowHighlightDay, setRowHighlightDay] = useState<string>('');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const isFirst = currentPage === 1;
  const isLast = currentPage === paginationInfo?.pages;

  const hasMoreThanOneJob = jobs && jobs.length > 1;

  const prevPayload = useRef('');

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedDev]);

  useEffect(() => {
    if (!selectedDev?.uid.length) return;

    callTimesheetsData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDev, currentPage]);

  useEffect(() => {
    setTimesheetIsPending(true);
  }, [selectedJob]);

  useEffect(() => {
    if (!selectedJob || selectedJob.id === NULL_JOB_ID) return;
    if (jobs.length === 1) return;

    callTimesheetsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedJob, timesheets]);

  useEffect(() => {
    if (!jobs?.length) return;
    if (selectedJob && selectedJob.id === NULL_JOB_ID) {
      setSelectedJob(jobs[0]);
    }
  }, [jobs, selectedJob]);

  useEffect(() => {
    if (pendingTimesheets?.length) {
      setEditTimesheet(pendingTimesheets[0]);
      setTimesheetIsPending(true);
    } else {
      setEditTimesheet(emptyTimesheetObject);
      setTimesheetIsPending(false);
    }
  }, [pendingTimesheets]);

  useEffect(() => {
    setIsLoading(false);
  }, [timesheets]);

  const selectJob = (id: number) => {
    const currentJob = jobs.find((job: ITimesheetsJob) => job.id === id) ?? selectedJob;

    setSelectedJob(currentJob);
  };

  const buildForm = (data: ITimesheetsObject) => {
    setEditTimesheet(data);
    setTimesheetIsPending(false);
  };

  const closeForm = () => {
    if (pendingTimesheets.length) {
      setEditTimesheet(pendingTimesheets[0]);
      setTimesheetIsPending(true);
    } else {
      setEditTimesheet(emptyTimesheetObject);
      setTimesheetIsPending(false);
    }
  };

  const callTimesheetsData = (resetJob: boolean = false) => {
    setIsLoading(true);
    const uid = selectedDev?.uid ?? '';

    const payload =
      selectedJob && selectedJob.id !== NULL_JOB_ID && !resetJob
        ? {
            developer_uid: uid,
            job_id: selectedJob.id,
            per_page: TIMESHEETS_PER_PAGE,
            page: currentPage,
          }
        : {
            developer_uid: uid,
            per_page: TIMESHEETS_PER_PAGE,
            page: currentPage,
          };
    const payloadJson = JSON.stringify(payload);

    if (prevPayload.current === payloadJson) return;

    prevPayload.current = payloadJson;
    getTimesheets(payload);
  };

  const handlePageChange = (newPage: number) => {
    setIsLoading(true);
    setCurrentPage(newPage);
  };

  const TimesheetContainer: FC = useCallback(
    (props) => (
      <main className="mx-4 py-0 sm:py-4 my-0 sm:my-4">
        <h1 className="font-bold text-4xl sm:text-5xl mb-4">Timesheets</h1>
        <DeveloperSelector selected={selectedDev?.uid} />

        {props.children}
      </main>
    ),
    [selectedDev]
  );

  if (!selectedDev?.uid.length) return <TimesheetContainer />;

  const timesheetRows = timesheets?.map((data: ITimesheetsObject) => (
    <TimesheetRow
      key={data.job_day}
      buildForm={() => buildForm(data)}
      data={data}
      highlight={data.job_day === rowHighlightDay}
    />
  ));

  if (timesheetsError) {
    return (
      <Error
        title="Timesheet Error"
        onClick={() => history.push('/')}
        mainMessage={timesheetsError}
        subMessage="Please try again later"
      />
    );
  }

  return (
    <TimesheetContainer>
      {hasMoreThanOneJob && (
        <div className="my-5">
          <span className="mr-4 tracking-wide font-bold text-lg">Job</span>

          <select
            className="font-bold tracking-wide text-gray-500 lg:max-w-min border-gray-300 rounded"
            onChange={(e) => selectJob(Number(e.currentTarget.value))}
          >
            {jobs.length ? (
              jobs.map((job: ITimesheetsJob) => (
                <option value={job.id} key={job.id}>
                  {job.company_name}
                </option>
              ))
            ) : (
              <option defaultValue="">Loading...</option>
            )}
          </select>
        </div>
      )}

      <section className="xl:w-10/12 2xl:w-8/12">
        <h2 className="font-bold text-2xl sm:text-3xl mb-4">
          {selectedJob.id !== NULL_JOB_ID
            ? `${selectedJob.title} @ ${selectedJob.company_name}`
            : !jobs.length
            ? ''
            : 'Loading...'}
        </h2>

        {editTimesheet.job_id !== NULL_JOB_ID && (
          <NewTimesheetForm
            cancel={closeForm}
            developerUid={selectedDev?.uid ?? ''}
            isLoading={isLoading}
            isPending={timesheetIsPending}
            postTimesheets={postTimesheets}
            setRowHighlightDay={setRowHighlightDay}
            timesheet={editTimesheet}
          />
        )}

        <section className="max-w-2xl">
          <div
            className={`border-solid grid relative w-full text-left grid-cols-3 lg:grid-cols-4 border border-gray-300 rounded-lg
              ${!timesheets?.length ? 'h-80' : ''}
              ${isLoading ? 'h-80' : ''}
            `}
          >
            <div className="grid border-solid border-b border-gray-600 col-span-full grid-rows-1 grid-cols-7 lg:grid-cols-12">
              <div className="p-2 col-span-2 font-bold text-xl">Date</div>
              <div className="p-2 col-span-2 font-bold text-xl">Hours</div>
              <div className="p-2 col-span-5 font-bold text-xl hidden lg:block">Details</div>
              <div className="p-2 col-span-3 font-bold text-xl">Invoice</div>
            </div>

            {isLoading ? (
              <div className="animate-pulse text-2xl font-bold bg-opacity-50 bg-blue-50 flex items-center justify-center h-30 absolute bottom-0 left-0 right-0 top-10">
                Loading...
              </div>
            ) : timesheets?.length ? (
              timesheetRows
            ) : (
              <div className="text-2xl font-bold flex items-center justify-center h-30 absolute bottom-0 left-0 right-0 top-10">
                No timesheets found
              </div>
            )}
          </div>
        </section>

        {paginationInfo?.pages > 1 && (
          <Pagination
            isFirst={isFirst}
            isLast={isLast}
            currentPage={currentPage}
            totalPages={paginationInfo?.pages}
            handleChangePage={handlePageChange}
          />
        )}
      </section>
    </TimesheetContainer>
  );
});
