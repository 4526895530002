import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Datepicker, Input } from '@upstackhq/component-library';
import { toast } from 'react-toastify';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { IProfileEducationItem, IUpdateEducationRequest } from '../../../interfaces/profile';

type props = {
  education?: IProfileEducationItem;
  onClose: Function;
  onComplete?: Function;
};
export const EducationForm: FC<props> = observer(({ education, onClose, onComplete }) => {
  const {
    profileStore: { updateEducationSection, removeEducation, loading, saving },
  } = useRootStore();
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [current, setCurrent] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const validationSchema = yup.object().shape({
    degree: yup.string().required('Degree is required'),
    institution: yup.string().required('Institution is required'),
    location: yup.string().required('Location is required'),
    start_date: yup
      .date()
      .required('Start date is required')
      .max(new Date(), 'Start date cannot be in the future!')
      .nullable(),
    end_date: yup
      .date()
      .nullable()
      .when('current', {
        is: false,
        then: yup
          .date()
          .default(undefined)
          .required('End date is required')
          .min(yup.ref('start_date'), 'End date cannot be before start date!')
          .nullable(),
      }),
    current: yup.boolean().default(false),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { ref: currentRef, onChange: onCurrentChange } = register('current');

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (!current) return;

    setValue('end_date', null, { shouldDirty: true });
    setEndDate(undefined);
  }, [current, setValue]);

  useEffect(() => {
    if (!education) return;

    if (education.current) {
      setCurrent(true);
    }

    if (education.start_date) {
      setValue('start_date', new Date(education.start_date * 1000), { shouldDirty: false });
      setStartDate(new Date(education.start_date * 1000));
    }

    if (education.end_date) {
      setValue('end_date', new Date(education.start_date * 1000), { shouldDirty: false });
      setEndDate(new Date(education.end_date * 1000));
    }
  }, [education, setValue]);

  const onSubmitHandler = (payload: IUpdateEducationRequest) => {
    updateEducationSection({ ...payload, uid: education?.uid }).then(() => {
      toast.success('Profile successfully updated.');
      if (onComplete) {
        onComplete();
      }
    });
  };

  const handleCurrentChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrent(!current);
    onCurrentChange(e);
  };

  const { onChange: onDegreeChange, ref: degreeRef } = register('degree');
  const { onChange: onInstitutionChange, ref: institutionRef } = register('institution');
  const { onChange: onLocationChange, ref: locationRef } = register('location');

  return (
    <div className="p-3 mb-p5 border-2 border-blue-100 bg-gray-100 rounded-md" ref={scrollRef}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Input
          onChange={onDegreeChange}
          inputRef={degreeRef}
          name="degree"
          label="Degree"
          defaultValue={education?.degree}
          error={errors.degree?.message}
        />
        <Input
          onChange={onInstitutionChange}
          inputRef={institutionRef}
          name="institution"
          label="Institution"
          defaultValue={education?.institution}
          error={errors.institution?.message}
        />
        <Input
          onChange={onLocationChange}
          inputRef={locationRef}
          name="location"
          label="Location"
          defaultValue={education?.location}
          error={errors.location?.message}
        />
        <Datepicker
          name="start_date"
          monthYear
          label="Start date"
          onChange={(date: Date) => {
            setStartDate(
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
            );
            setValue(
              'start_date',
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
              { shouldValidate: true }
            );
          }}
          value={startDate}
          error={errors.start_date?.message}
        />
        <Datepicker
          name="end_date"
          monthYear
          disabled={current}
          label="End date"
          onChange={(date: Date) => {
            setEndDate(
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
            );
            setValue(
              'end_date',
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
              { shouldValidate: true }
            );
          }}
          value={endDate}
          error={errors.end_date?.message}
        />
        <Checkbox
          checkboxRef={currentRef}
          defaultChecked={education?.current}
          error={errors.current?.message}
          label="Current"
          name="current"
          onChange={handleCurrentChange}
        />
        <div className="flex justify-between mt-5">
          <div>
            {education?.uid && (
              <Button
                type="button"
                content="Remove"
                customClasses="pl-0 pr-0"
                backgroundColor="transparent"
                textColor="red"
                link
                onClick={() => removeEducation({ uid: education.uid })}
              />
            )}
          </div>
          <div>
            <Button
              type="button"
              content="Cancel"
              onClick={() => onClose()}
              customClasses="mr-2"
              backgroundColor="transparent"
              textColor="red"
              link
              bold
            />
            <Button
              type="submit"
              content="Save"
              disabled={loading || saving.education}
              isLoading={saving.education}
              noLockIcon
              uppercase
              backgroundColor="green"
              textColor="white"
              bold
            />
          </div>
        </div>
      </form>
    </div>
  );
});
