import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../constants';
import {
  emptyServiceRedirect,
  IGetAvailablePayoutMethodsResponse,
  IGetCurrentPayoutMethodsResponse,
  IGetRedirectResponse,
} from '../interfaces/payout-methods';

const SERVICE_REDIRECT_REQUEST_MAP: { [key: string]: string } = {
  PAYONEER: `${API_URL}/agency/payout-methods/setup/payoneer/get-redirection-url`,
};

const payoutMethodsService = {
  getAvailablePayoutMethods: async () => {
    try {
      const res: AxiosResponse<IGetAvailablePayoutMethodsResponse> = await axios.get(
        `${API_URL}/agency/payout-methods/available`
      );

      return res.data;
    } catch (e) {
      throw new Error('Could not retreive available payment methods');
    }
  },

  getCurrentPayoutMethods: async () => {
    try {
      const res: AxiosResponse<IGetCurrentPayoutMethodsResponse> = await axios.get(
        `${API_URL}/agency/payout-methods`
      );

      return res.data;
    } catch (e) {
      throw new Error('Could not retreive current payment methods');
    }
  },

  getRedirect: async (service: string) => {
    if (Object.keys(SERVICE_REDIRECT_REQUEST_MAP).indexOf(service) < 0) {
      return emptyServiceRedirect;
    }

    try {
      const res: AxiosResponse<IGetRedirectResponse> = await axios.get(
        SERVICE_REDIRECT_REQUEST_MAP[service]
      );

      return { id: service, ...res.data };
    } catch (e: any) {
      console.error(e?.message);
      throw new Error('Could not retreive current payment methods redirects');
    }
  },
};

export default payoutMethodsService;
