import { FC } from 'react';

type TProps = {
  count: number;
};

export const TimePeriodLoadingRows: FC<TProps> = (props: TProps) => {
  const { count } = props;

  return (
    <>
      {Array.from(Array(count).keys()).map((item) => (
        <div
          key={item}
          className="grid grid-cols-4 sm:grid-cols-8 p-1 last:border-b-0 border-b border-gray-300"
        >
          <div className="animate-pulse rounded-full bg-gray-300 mr-8 h-4 col-span-2 sm:col-span-3" />
          <div className="animate-pulse rounded-full bg-gray-300 mr-8 h-4 hidden sm:flex items-center" />
          <div className="animate-pulse rounded-full bg-gray-300 mr-8 h-4 hidden sm:flex items-center" />
          <div className="animate-pulse rounded-full bg-gray-300 mr-8 h-4 flex items-center" />
          <div className="animate-pulse rounded-full bg-gray-300 mr-8 h-4 flex items-center" />
        </div>
      ))}
    </>
  );
};
