import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@upstackhq/component-library';
import { ExperienceForm } from './Forms/ExperienceForm';
import { ExperienceInfoBox } from './InfoBoxes/ExperienceInfoBox';
import { useRootStore } from '../../providers/RootStoreProvider';

export const ExperienceSection: FC = observer(() => {
  const {
    profileStore: {
      profileData: { experience },
    },
  } = useRootStore();
  const [newExperiences, setNewExperiences] = useState<{ key: number }[]>([]);

  const onAddNewExperience = () => {
    setNewExperiences([
      ...newExperiences,
      { key: newExperiences.length ? newExperiences[newExperiences.length - 1].key + 1 : 1 },
    ]);
  };
  return (
    <div>
      <div className="flex justify-between mb-3">
        <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Work Experience</h2>
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewExperience}
        />
      </div>

      {experience.map((experience) => (
        <ExperienceInfoBox key={experience.uid} experience={experience} />
      ))}

      {newExperiences.map(({ key }) => (
        <ExperienceForm
          key={key}
          onClose={() => setNewExperiences(newExperiences.filter(({ key: k }) => k !== key))}
          onComplete={() => setNewExperiences(newExperiences.filter(({ key: k }) => k !== key))}
        />
      ))}
    </div>
  );
});
