import * as yup from 'yup';

import { CodeIcon, XIcon } from '@heroicons/react/outline';
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Tag,
  Button,
  Autocomplete,
  Textarea,
  Checkbox,
  Datepicker,
  Dropdown,
  Input,
} from '@upstackhq/component-library';
import {
  IProfileExperienceItem,
  IUpdateExperienceFormPayload,
  IUpdateExperiencePayload,
} from '../../../interfaces/profile';
import { ISkillItem } from '../../../interfaces/selector';
import { countries, linkRegex } from '../../../constants';
import { useRootStore } from '../../../providers/RootStoreProvider';
import { uniqBy } from '../../../utils';

type props = {
  experience?: IProfileExperienceItem;
  onClose: Function;
  onComplete?: Function;
};
export const ExperienceForm: FC<props> = observer(({ experience, onClose, onComplete }) => {
  const {
    selectorStore: { fetchSkills, skills },
    profileStore: { updateExperienceSection, removeExperience, loading, saving },
  } = useRootStore();
  const [selectedSkills, setSelectedSkills] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [currentRole, setCurrentRole] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  const validationSchema = yup.object().shape({
    company: yup.string().required('Company is required'),
    url: yup
      .string()
      .required('URL is required')
      .matches(linkRegex, 'Enter a valid URL! (Example: https://www.upstack.co)'),
    location: yup.string().required('Location is required'),
    title: yup.string().required('Title is required'),
    start_date: yup
      .date()
      .required('Start date is required')
      .max(new Date(), 'Start date cannot be in the future!')
      .nullable(),
    end_date: yup
      .date()
      .nullable()
      .when('current_role', {
        is: false,
        then: yup
          .date()
          .default(undefined)
          .required('End date is required')
          .min(yup.ref('start_date'), 'End date cannot be before start date!')
          .nullable(),
      }),
    current_role: yup.boolean().default(false),
    highlight1: yup.string().required('Highlight 1 is required'),
    skills: yup
      .array()
      .default([])
      .min(1, 'At least 1 skill is required')
      .of(yup.string().required('Skills are required')),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const { onChange: onHighlight1Change, ref: highlight1Ref } = register('highlight1');
  const { onChange: onHighlight2Change, ref: highlight2Ref } = register('highlight2');
  const { onChange: onHighlight3Change, ref: highlight3Ref } = register('highlight3');
  const { onChange: onCurrentRoleChange, ref: currentRoleRef } = register('current_role');

  useEffect((): void => {
    if (!skills.length) {
      fetchSkills();
    }

    register('skills');
    scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [fetchSkills, register, skills.length]);

  useEffect(() => {
    if (!experience) return;

    if (experience.skills) {
      setSelectedSkills(experience.skills);
      setValue('skills', experience.skills);
    }

    if (experience.location) {
      setValue('location', experience.location);
    }

    if (experience.current_role) {
      setCurrentRole(true);
    }

    if (experience.start_date) {
      setValue('start_date', new Date(experience.start_date * 1000), {
        shouldDirty: false,
      });
      setStartDate(new Date(experience.start_date * 1000));
    }

    if (experience.end_date) {
      setValue('end_date', new Date(experience.start_date * 1000), {
        shouldDirty: false,
      });
      setEndDate(new Date(experience.end_date * 1000));
    }
  }, [experience, setValue]);

  useEffect(() => {
    if (!currentRole) return;

    setValue('end_date', null, { shouldDirty: true });
    setEndDate(undefined);
  }, [currentRole, setValue]);

  const onSubmitHandler = (payload: IUpdateExperienceFormPayload) => {
    const updatePayload = serializeFormPayload(payload);

    updateExperienceSection({ ...updatePayload, uid: experience?.uid }).then(() => {
      toast.success('Profile successfully updated.');
      if (onComplete) {
        onComplete();
      }
    });
  };

  const serializeFormPayload = (payload: IUpdateExperienceFormPayload) => {
    const highlights = [payload.highlight1, payload.highlight2, payload.highlight3];
    const newPayload = { ...payload, highlights };

    delete newPayload.highlight1;
    delete newPayload.highlight2;
    delete newPayload.highlight3;

    return newPayload as IUpdateExperiencePayload;
  };

  const handleCountrySelect = (country: string) => {
    setValue('location', country, { shouldValidate: true });
  };

  const handleSkillDeselect = (skill: string) => () => {
    setSelectedSkills(selectedSkills.filter((s) => s !== skill));
    setValue(
      'skills',
      selectedSkills.filter((s) => s !== skill)
    );
  };

  const renderSkills = () => {
    const skills = selectedSkills.map((skill) => {
      return (
        <span className="mb-1" key={skill}>
          <Tag
            textColor="blue"
            backgroundColor="blue"
            content={skill}
            bold
            uppercase
            onClick={handleSkillDeselect(skill)}
            rightIcon={<XIcon className="w-4 h-4" />}
          />
        </span>
      );
    });
    if (!selectedSkills.length) {
      return;
    }

    return <div className=" mb-1 flex flex-row flex-wrap items-center">{skills}</div>;
  };

  const handleCurrentRoleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCurrentRole(!currentRole);
    onCurrentRoleChange(e);
  };

  const uniqSkills = uniqBy(
    skills
      .filter(
        (skill: ISkillItem) =>
          !selectedSkills.map((selectedSkill: string) => selectedSkill).includes(skill.name)
      )
      .map((skill) => ({ title: skill.name })),
    'title'
  );

  const handleSkillsChange = (skill: { title: string }) => {
    setSelectedSkills([...selectedSkills, skill.title]);
    setValue('skills', [...selectedSkills, skill.title], { shouldValidate: true });
  };
  const { onChange: onCompanyChange, ref: companyRef } = register('company');
  const { onChange: onUrlChange, ref: urlRef } = register('url');
  const { onChange: onTitleChange, ref: titleRef } = register('title');

  return (
    <div className="p-3 mb-p5 border-2 border-blue-100 bg-gray-100 rounded-md" ref={scrollRef}>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <Input
          onChange={onCompanyChange}
          inputRef={companyRef}
          name="company"
          label="Company"
          defaultValue={experience?.company}
          error={errors.company?.message}
        />
        <Input
          onChange={onUrlChange}
          inputRef={urlRef}
          name="url"
          label="URL"
          defaultValue={experience?.url}
          error={errors.url?.message}
        />
        <Autocomplete
          data={countries.map((country) => ({
            value: country,
            label: country,
          }))}
          error={errors.location?.message}
          onChange={handleCountrySelect}
          label="Location"
          innerBackgroundColor="white"
          noOuterPadding
          value={experience?.location}
        />
        <Input
          onChange={onTitleChange}
          inputRef={titleRef}
          name="title"
          label="Title"
          defaultValue={experience?.title}
          error={errors.title?.message}
        />
        <Datepicker
          name="start_date"
          monthYear
          label="Start date"
          onChange={(date: Date) => {
            setStartDate(
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
            );
            setValue(
              'start_date',
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
              { shouldValidate: true }
            );
          }}
          value={startDate}
          error={errors.start_date?.message}
        />
        <Datepicker
          name="end_date"
          disabled={currentRole}
          monthYear
          label="End date"
          onChange={(date: Date) => {
            setEndDate(
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
            );
            setValue(
              'end_date',
              new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
              { shouldValidate: true }
            );
          }}
          value={endDate}
          error={errors.end_date?.message}
        />
        <Checkbox
          checkboxRef={currentRoleRef}
          defaultChecked={experience?.current_role}
          error={errors.currentRole?.message}
          label="This is my current role"
          name="current_role"
          onChange={handleCurrentRoleChange}
        />
        <Textarea
          name="highlight1"
          defaultValue={experience?.highlight1}
          rows={7}
          label="Highlight #1"
          onChange={onHighlight1Change}
          textareaRef={highlight1Ref}
          error={errors.highlight1?.message}
        />
        <Textarea
          name="highlight2"
          defaultValue={experience?.highlight2}
          rows={7}
          label="Highlight #2"
          onChange={onHighlight2Change}
          textareaRef={highlight2Ref}
          error={errors.highlight2?.message}
        />
        <Textarea
          name="highlight3"
          defaultValue={experience?.highlight3}
          rows={7}
          label="Highlight #3"
          onChange={onHighlight3Change}
          textareaRef={highlight3Ref}
          error={errors.highlight3?.message}
        />
        <div className="flex flex-col mb-2 w-full md:w-auto">
          <label className="font-bold mb-2 inline-block">Skills</label>
          {renderSkills()}
          <Dropdown
            icon={<CodeIcon className="w-6 h-6 text-blue-400" />}
            items={uniqSkills}
            customClasses={`w-full bg-white ${errors.skills?.message ? 'mb-0' : 'mb-4'}`}
            disableLocalSelection
            onChange={handleSkillsChange}
          />
          <p
            className={`text-xs mt-2 pl-1 mb-4 text-red-500 overflow-hidden transition-all ${
              errors.skills?.message ? 'max-h-10' : 'max-h-0'
            }`}
          >
            {errors.skills?.message}
          </p>
        </div>
        <div className="flex justify-between mt-5">
          <div>
            {experience?.uid && (
              <Button
                type="button"
                content="Remove"
                customClasses="pl-0 pr-0"
                backgroundColor="transparent"
                textColor="red"
                link
                onClick={() => removeExperience({ uid: experience.uid })}
              />
            )}
          </div>
          <div>
            <Button
              type="button"
              content="Cancel"
              onClick={() => onClose()}
              customClasses="mr-2"
              backgroundColor="transparent"
              textColor="red"
              link
              bold
            />
            <Button
              type="submit"
              content="Save"
              disabled={loading || saving.experience}
              isLoading={saving.experience}
              noLockIcon
              uppercase
              backgroundColor="green"
              textColor="white"
              bold
            />
          </div>
        </div>
      </form>
    </div>
  );
});
