import { action, makeAutoObservable, runInAction } from 'mobx';
import {
  ITimesheetsGetPayload,
  ITimesheetsJob,
  defaultTimesheetsResponse,
  ITimesheetsPayloadUpload,
  ITimesheetsObject,
} from '../interfaces/timesheets';
import { RootStore } from './index';
import TimesheetsService from '../api/timesheets';
import { IPagination } from '../interfaces/general';

export default class TimesheetsStore {
  root: RootStore;

  jobs: ITimesheetsJob[] = [];

  pendingTimesheets: ITimesheetsObject[] = [];

  timesheets: ITimesheetsObject[] = [];

  timesheetsError: string = '';

  paginationInfo: IPagination = defaultTimesheetsResponse.pagination;

  constructor(rs: RootStore) {
    this.root = rs;

    makeAutoObservable(this, {
      getTimesheets: action.bound,
      postTimesheets: action.bound,
      setTimesheetsError: action.bound,
    });
  }

  resetData() {
    this.timesheets = [];
    this.pendingTimesheets = [];
    this.jobs = [];
    this.paginationInfo = defaultTimesheetsResponse.pagination;
  }

  async getTimesheets(payload: ITimesheetsGetPayload) {
    try {
      const response = await TimesheetsService.getTimesheets(payload);

      runInAction(() => {
        const {
          jobs,
          pagination,
          pending_time_sheets: pendingTimesheets,
          time_sheets: timesheets,
        } = response;

        this.resetData();

        this.timesheets = timesheets ?? [];
        this.pendingTimesheets = pendingTimesheets ?? [];
        this.jobs = jobs;
        this.paginationInfo = pagination ?? defaultTimesheetsResponse.pagination;
      });
    } catch (e: any) {
      runInAction(() => {
        this.timesheetsError = e?.message;
      });
    }
  }

  async postTimesheets(payload: ITimesheetsPayloadUpload) {
    try {
      const response = await TimesheetsService.postTimesheets(payload);

      runInAction(() => {
        const { pending_time_sheets: pendingTimesheets, time_sheet: timesheet } = response;

        let newTimesheets = this.timesheets.map((sheet) => {
          if (sheet.job_day !== timesheet.job_day) return sheet;
          if (sheet.job_id !== timesheet.job_id) return sheet;

          return timesheet;
        });

        const searchableTimesheets = newTimesheets.map(timesheetHash);
        const timesheetId = timesheetHash(timesheet);

        if (searchableTimesheets.indexOf(timesheetId) < 0) {
          newTimesheets = [timesheet, ...newTimesheets];
        }

        this.timesheets = newTimesheets;
        this.pendingTimesheets = pendingTimesheets;
      });
    } catch (e: any) {
      runInAction(() => {
        this.timesheetsError = e?.message;
      });
    }
  }

  setTimesheetsError(newMessage: string) {
    this.timesheetsError = newMessage;
  }
}

const timesheetHash = (timesheet: ITimesheetsObject) => `${timesheet.job_day}-${timesheet.job_id}`;
