import { Transition } from '@headlessui/react';
import { FC } from 'react';
import { ImInfo } from 'react-icons/im';
import { ITimePeriodItem } from '../../interfaces/bills';
import { numToCurrency } from '../../utils';
import { Tooltip } from '../Tooltip/Tooltip';

const STATUS_TOOLTIP_MAP: { [key: string]: string } = {
  Pending: `This period is not yet sent to the client.
              You will be able to Add it to the Bill once
              it is paid by the client`,
  'Sent to client': `This period is sent to the client.
                     You will be able to add it to the bill
                     once it is paid by the client`,
  'Ready to bill': `This period has been paid by the client.
           You will be able to add it to the bill
           once the next most recent time period has
           been added to the bill`,
};

type TTimePeriodRowProps = {
  addToBill?(): void;
  isFirst?: boolean;
  isLast?: boolean;
  remove?(): void;
  timePeriod: ITimePeriodItem;
  visible: boolean;
};

export const TimePeriodRow: FC<TTimePeriodRowProps> = (props: TTimePeriodRowProps) => {
  const {
    addToBill,
    isFirst = false,
    isLast = false,
    remove,
    timePeriod: { canBill, hours, rate, status, total, worked },
    visible,
  } = props;

  const handleClick = () => {
    if (addToBill) {
      addToBill();
    }

    if (remove) {
      remove();
    }
  };

  const showButton = (canBill && isLast) || (remove && isFirst);

  return (
    <Transition
      className="grid grid-cols-4 sm:grid-cols-8 p-1 last:border-b-0 border-b border-gray-300"
      show={visible}
      enter="transition-all duration-500"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-all duration-500"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <div className="col-span-2 sm:col-span-3" dangerouslySetInnerHTML={{ __html: worked }} />
      <div className="hidden sm:flex items-center">{hours}</div>
      <div className="hidden sm:flex items-center">{rate}</div>
      <div className="flex items-center">{numToCurrency(total)}</div>
      <span className="inline-flex flex-wrap items-center sm:col-span-2">
        {showButton ? (
          <>
            <br className="sm:hidden" />
            <button
              className={`hover:underline font-bold
                  ${addToBill ? 'text-blue-500' : remove ? 'text-red-500' : ''}
                `}
              onClick={handleClick}
            >
              {addToBill ? 'Add to bill' : remove ? 'Remove' : ''}
            </button>
          </>
        ) : remove ? null : (
          <span className="inline-flex items-center font-bold mr-2">
            {status}
            <span className="ml-1 cursor-pointer">
              <Tooltip
                tooltipBackground="bg-gray-800 opacity-100"
                tooltipPadding="p-4"
                tooltipText={STATUS_TOOLTIP_MAP[status]}
              >
                <ImInfo />
              </Tooltip>
            </span>
          </span>
        )}
      </span>
    </Transition>
  );
};
