import React, { FC } from 'react';

export interface LoadingProps {
  title?: string;
}

// TODO: Make thing look better in any component, maybe full screen coverage or full component coverage
export const Loading: FC<LoadingProps> = (props) => (
  <div className="h-full py-6 flex flex-col justify-center content-center sm:py-12 items-center">
    <svg
      className="animate-spin mx-auto h-20 w-20 text-blue-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    <p className="text-xl text-gray-400 mt-3">Hang on...</p>
    <p className="text-gray-500 mt-3">
      Loading
      {props.title}
    </p>
  </div>
);
