import { FieldValues, UseFormRegister } from 'react-hook-form';
import React, { ChangeEvent, FC, RefObject } from 'react';

type props = {
  name: string;
  placeholder?: string;
  onChange?: Function;
  error?: string;
  value?: string;
  defaultValue?: string | number;
  label?: string;
  required?: boolean;
  customClasses?: string;
  ref?: RefObject<any>;
  register?: UseFormRegister<FieldValues>;
};
export const Select: FC<props> = (props) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    if (props.onChange) {
      props.onChange(e.target.value);
    }
  };

  return (
    <div
      className={`flex flex-col mb-2 w-full md:w-auto ${
        props.customClasses ? props.customClasses : ''
      }`}
    >
      {props.label && (
        <label className="font-bold mb-2 inline-block">
          {props.label} {props.required ? '*' : ''}
        </label>
      )}
      <select
        className={`w-full text-gray-800 cursor-pointer border border-solid border-gray-300 rounded pb-3 pt-3 shadow-sm ${
          props.error ? 'border-red-500 border-2' : ''
        }`}
        onChange={handleChange}
        value={props.value}
        ref={props.ref}
        defaultValue={props.defaultValue}
        {...(props.register
          ? {
              ...props.register(props.name, {
                onChange: handleChange,
              }),
            }
          : '')}
      >
        {props.placeholder && <option disabled>{props.placeholder}</option>}
        {props.children}
      </select>
      <p className="-m-1 text-xs mb-1 mt-2 pl-1 text-red-500 overflow-hidden transition-all">
        {props.error}
      </p>
    </div>
  );
};
