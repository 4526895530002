import { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@upstackhq/component-library';

type Props = {
  currentStep: number;
  steps: Step[];
  onNext?: () => void;
  onPrevious?: () => void;
  onSkip?: () => void;
  onComplete?: () => void;
  finalStepButtonText?: string;
  saving?: boolean;
};

type Step = {
  title: string;
  component: ReactElement;
  required: boolean;
  updateContent: Function;
  data: any;
};
export const WizardBottomNavigation: FC<Props> = observer((props) => {
  const {
    currentStep,
    steps,
    onPrevious,
    onComplete,
    onNext,
    onSkip,
    finalStepButtonText,
    saving,
  } = props;
  return (
    <div className="absolute -bottom-20 left-0 right-0 py-5">
      <div className="max-w-3xl mx-auto">
        <div className="flex justify-between">
          <div className="w-1/2">
            {currentStep !== 1 && (
              <Button
                type="submit"
                content="Previous step"
                link
                backgroundColor="white"
                textColor="gray"
                bold
                customClasses="py-2 px-5 border text-gray-600"
                noLockIcon
                onClick={onPrevious}
                disabled={saving}
              />
            )}
          </div>

          <div className="w-1/2 text-right">
            {!steps[currentStep - 1]?.required &&
              currentStep !== steps.length &&
              (!steps[currentStep - 1]?.data || steps[currentStep - 1]?.data?.length === 0) && (
                <Button
                  type="submit"
                  content="Skip"
                  backgroundColor="white"
                  textColor="blue"
                  link
                  bold
                  customClasses="mx-1 py-2 px-5"
                  disabled={saving}
                  onClick={onSkip}
                />
              )}
            {currentStep !== steps.length && (
              <Button
                type="submit"
                content="Next"
                backgroundColor="green"
                textColor="white"
                bold
                customClasses="mx-1 py-2 px-5"
                noLockIcon
                isLoading={saving}
                disabled={
                  !steps[currentStep - 1]?.data ||
                  steps[currentStep - 1]?.data?.length === 0 ||
                  saving
                }
                onClick={onNext}
              />
            )}
            {currentStep === steps.length && (
              <Button
                type="submit"
                isLoading={saving}
                disabled={saving}
                content={finalStepButtonText || 'Complete'}
                backgroundColor="green"
                textColor="white"
                bold
                customClasses="mx-1 py-2 px-5"
                noLockIcon
                onClick={onComplete}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});
