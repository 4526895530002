import * as React from 'react';
import { Redirect, Route, RouteProps } from 'react-router';

export interface IPrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  authenticationPath: string;
}

export class PrivateRoute extends Route<IPrivateRouteProps> {
  public render() {
    let redirectPath: string = '';
    if (!this.props.isAuthenticated) {
      redirectPath = this.props.authenticationPath;
    }
    const currentLocation = `${this.props?.location?.pathname}${this.props?.location?.search}`;

    if (redirectPath) {
      return (
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: redirectPath,
                state: { from: currentLocation },
              }}
            />
          )}
        />
      );
    }
    return <Route {...this.props} />;
  }
}
