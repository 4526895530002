import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';
import { IAddDeveloperRequestPayload, IExperienceItem } from '../interfaces/developers';

const developerService = {
  addDeveloper: async (payload: IAddDeveloperRequestPayload) => {
    const { employment, ...remainingPayload } = payload;
    const employmentPayload = employment.map((employmentItem) =>
      serializeEmploymentPayload(employmentItem)
    );
    const updatedPayload = { employment: employmentPayload, ...remainingPayload };
    try {
      const res: AxiosResponse<any> = await axios
        .post(`${API_URL}/agency/developers/add`, updatedPayload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

const serializeEmploymentPayload = (employment: IExperienceItem) => {
  const { highlight1, highlight2, highlight3, ...remainingPayload } = employment;
  const highlights: [string, string, string] = [highlight1, highlight2, highlight3];
  return { ...remainingPayload, highlights };
};

export default developerService;
