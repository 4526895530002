import { FC, ReactElement } from 'react';
import { observer } from 'mobx-react';

import { Select } from '../../common/Select/Select';
import { TimePeriodLoadingRows } from './TimePeriodLoadingRows';
import { useRootStore } from '../../providers/RootStoreProvider';

type TProps = {
  error: string;
  loading: boolean;
  retry: Function;
  rows: ReactElement[];
};

export const TimePeriods: FC<TProps> = observer((props: TProps) => {
  const { error, loading, retry, rows } = props;

  const {
    billsStore: { jobs, selectJob, selectedJob },
  } = useRootStore();

  const handleJobSelect = (uid: string) => {
    selectJob(uid);
  };

  const title = (
    <h3 className="flex flex-col mb-2 text-2xl font-bold">
      Time Periods
      {loading ? (
        <div className="flex h-5 items-center space-x-2">
          <div className="animate-pulse rounded-full bg-gray-300 h-5 w-32" />
          <span>@</span>
          <div className="animate-pulse rounded-full bg-gray-300 h-4 w-32" />
        </div>
      ) : selectedJob?.job_uid.length ? (
        <small>
          {selectedJob.title} @{selectedJob.company_name}
        </small>
      ) : null}
    </h3>
  );

  const timePeriods = (
    <section className="w-full flex flex-col mb-8">
      {title}
      <div className="w-full grid border border-gray-300 rounded">
        <div className="grid grid-cols-4 sm:grid-cols-8 p-1 text-lg font-bold border-b border-gray-400">
          <div className="col-span-2 sm:col-span-3">Period</div>
          <div className="hidden sm:block">Hours</div>
          <div className="hidden sm:block">Rate</div>
          <div className="">Total</div>
          <div className="sm:col-span-2">Status</div>
        </div>
        {loading ? (
          <TimePeriodLoadingRows count={3} />
        ) : error.length ? (
          <div className="p-3 inline-flex font-bold bg-red-50 items-center justify-center">
            {error}
          </div>
        ) : rows.length ? (
          rows
        ) : (
          <div className="p-3 inline-flex font-bold bg-gray-100 items-center justify-center">
            No time periods to display
          </div>
        )}
      </div>
      {!loading && error.length ? (
        <button className="bg-transparent text-red-600 font-bold self-end" onClick={() => retry()}>
          Retry
        </button>
      ) : null}
    </section>
  );

  if (jobs.length <= 1) {
    return timePeriods;
  }

  return (
    <>
      <div className="flex items-center max-w-xs mb-6">
        <span className="mr-4 mb-2 tracking-wide font-bold text-lg">Job</span>
        <Select
          defaultValue={selectedJob.job_uid}
          name="time-period-job-select"
          onChange={handleJobSelect}
        >
          {jobs.map((job) => (
            <option key={job.job_uid} value={job.job_uid}>
              {job.company_name}
            </option>
          ))}
        </Select>
      </div>

      {selectedJob.job_uid.length ? timePeriods : null}
    </>
  );
});
