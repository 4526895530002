import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../constants';
import {
  IGetProfileDataResponse,
  IRemoveEducationRequest,
  IRemoveExperienceRequest,
  IRemoveShowcaseRequest,
  IUpdateAboutSectionRequest,
  IUpdateAboutSectionResponse,
  IUpdateAboutSocialSectionResponse,
  IUpdateAvatarRequest,
  IUpdateEducationRequest,
  IUpdateEducationResponse,
  IUpdateExperiencePayload,
  IUpdateExperienceResponse,
  IUpdateShowcaseRequest,
  IUpdateShowcaseResponse,
  IUpdateSkillsSectionRequest,
  IUpdateSkillsSectionResponse,
  IUpdateSocialSectionRequest,
  IUpdateWorkSectionRequest,
  IUpdateWorkSectionResponse,
} from '../interfaces/profile';

const profileService = {
  getProfileData: async (developerUid: string) => {
    try {
      const res: AxiosResponse<IGetProfileDataResponse> = await axios
        .get(`${API_URL}/agency/profile?developer_uid=${developerUid}`)
        .catch((e) => {
          throw new Error('Could not retrieve profile data');
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileAvatar: async (developerUid: string, payload: IUpdateAvatarRequest) => {
    try {
      const res: AxiosResponse = await axios
        .post(`${API_URL}/agency/profile/update/avatar?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileAboutSection: async (developerUid: string, payload: IUpdateAboutSectionRequest) => {
    try {
      const res: AxiosResponse<IUpdateAboutSectionResponse> = await axios
        .post(`${API_URL}/agency/profile/update/about?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileWorkSection: async (developerUid: string, payload: IUpdateWorkSectionRequest) => {
    try {
      const res: AxiosResponse<IUpdateWorkSectionResponse> = await axios
        .post(`${API_URL}/agency/profile/update/work?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileSocialSection: async (
    developerUid: string,
    payload: IUpdateSocialSectionRequest
  ) => {
    try {
      const res: AxiosResponse<IUpdateAboutSocialSectionResponse> = await axios
        .post(`${API_URL}/agency/profile/update/social?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileSkillsSection: async (
    developerUid: string,
    payload: IUpdateSkillsSectionRequest
  ) => {
    try {
      const res: AxiosResponse<IUpdateSkillsSectionResponse> = await axios
        .post(`${API_URL}/agency/profile/update/skills?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileExperienceSection: async (
    developerUid: string,
    payload: IUpdateExperiencePayload
  ) => {
    try {
      const res: AxiosResponse<IUpdateExperienceResponse> = await axios
        .post(`${API_URL}/agency/profile/update/experience?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  removeProfileExperience: async (developerUid: string, payload: IRemoveExperienceRequest) => {
    try {
      const res: AxiosResponse = await axios
        .post(`${API_URL}/agency/profile/remove/experience?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileEducationSection: async (developerUid: string, payload: IUpdateEducationRequest) => {
    try {
      const res: AxiosResponse<IUpdateEducationResponse> = await axios
        .post(`${API_URL}/agency/profile/update/education?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  removeProfileEducation: async (developerUid: string, payload: IRemoveEducationRequest) => {
    try {
      const res: AxiosResponse = await axios
        .post(`${API_URL}/agency/profile/remove/education?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  updateProfileShowcaseSection: async (developerUid: string, payload: IUpdateShowcaseRequest) => {
    try {
      const res: AxiosResponse<IUpdateShowcaseResponse> = await axios
        .post(`${API_URL}/agency/profile/update/showcase?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  removeProfileShowcase: async (developerUid: string, payload: IRemoveShowcaseRequest) => {
    try {
      const res: AxiosResponse = await axios
        .post(`${API_URL}/agency/profile/remove/showcase?developer_uid=${developerUid}`, payload)
        .catch((e) => {
          throw new Error(e.response.data.error.message);
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
};

export default profileService;
