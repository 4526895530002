import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { InfoBox } from '@upstackhq/component-library';
import { useRootStore } from '../../providers/RootStoreProvider';
import { SkillForm } from './Forms/SkillForm';
import { ISkill } from '../../interfaces/developers';
import { ISkillItem } from '../../interfaces/selector';

type props = {
  developerPositionId: string;
  data: ISkill[];
  onComplete?: Function;
  onInvalid?: () => void;
  nextStep?: boolean;
  onUpdate?: Function;
};

const emptySkill = { years: 0 } as ISkill;

export const SkillsSection: FC<props> = observer((props) => {
  const { data, developerPositionId, onComplete, onInvalid, nextStep, onUpdate } = props;
  const [profileSkills, setProfileSkills] = useState<ISkill[]>([]);
  const {
    selectorStore: { fetchSkills, fetchCareers, skills, jobOpenings },
  } = useRootStore();

  useEffect((): void => {
    fetchSkills();
    fetchCareers();
  }, [fetchSkills, fetchCareers]);

  useEffect(() => {
    if (!jobOpenings.length) {
      return;
    }

    const developerPositionSkill = jobOpenings.find(
      (job) => job.uid === developerPositionId
    )?.skill;
    const defaultDeveloperSkill = { name: developerPositionSkill, years: 0 } as ISkill;

    setProfileSkills(
      !data.some((d) => d.name === defaultDeveloperSkill.name)
        ? [defaultDeveloperSkill, emptySkill]
        : [...data, emptySkill]
    );
  }, [jobOpenings]);

  useEffect((): void => {
    if (data?.length) {
      setProfileSkills(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextStep) {
      if (profileSkills.length === 0) {
        if (onInvalid) {
          onInvalid();
        }
      } else if (onComplete) {
        const selectedSkills = profileSkills.filter((skill) => skill.name && skill.years);
        onUpdate?.(selectedSkills);
        onComplete(selectedSkills);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextStep, profileSkills]);

  const onSkillRemoved = (index: number) => {
    const newSkills = [...profileSkills];
    newSkills.splice(index, 1);
    setProfileSkills(newSkills);
    if (onUpdate) {
      onUpdate(newSkills);
    }
  };

  const handleEditSkill = (index: number, changes: Partial<ISkill>) => {
    const newSkills: ISkill[] = profileSkills.map((skill, skillIndex) =>
      skillIndex === index ? { ...skill, ...changes } : skill
    );

    if (newSkills.length - 1 === index) {
      newSkills.push(emptySkill);
    }

    setProfileSkills(newSkills);
    onUpdate?.(newSkills);
  };

  return (
    <div>
      <p className="mb-5 text-sm">Tell us developers top software development skills *</p>
      {profileSkills.length === 0 && (
        <InfoBox
          title="Enter skills"
          description="Please share developer top skills and how many years they have worked in them? Eg. Swift 4yrs, React 5yrs. Most developers enter 5-10 skills here"
          type="warning"
        />
      )}
      {profileSkills.map((skill, index) => (
        <SkillForm
          key={skill.name}
          onRemove={onSkillRemoved}
          skills={skills.filter(
            (skill: ISkillItem) =>
              !profileSkills.map((profileSkill: ISkill) => profileSkill.name).includes(skill.name)
          )}
          index={index}
          data={skill}
          onChange={handleEditSkill}
        />
      ))}
    </div>
  );
});
