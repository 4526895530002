import axios, { AxiosResponse } from 'axios';
import { API_URL } from '../constants';

const utilsService = {
  uploadMedia: async (formData: FormData) => {
    try {
      const res: AxiosResponse = await axios.post(`${API_URL}/utils/upload-media`, formData);
      return res.data;
    } catch (e) {
      throw new Error('Media upload failed!');
    }
  },
};

export default utilsService;
