import UserStore from './user';
import UtilityStore from './utils';
import MenuStore from './menu';
import JobStore from './job';
import SelectorStore from './selector';
import TimesheetsStore from './timesheets';
import BillsStore from './bills';
import MyDevsStore from './mydevs';
import BadgesStore from './badges';
import ProfileStore from './profile';
import PayoutMethodsStore from './payout-methods';

export class RootStore {
  userStore: UserStore;

  utilityStore: UtilityStore;

  menuStore: MenuStore;

  jobStore: JobStore;

  selectorStore: SelectorStore;

  timesheetsStore: TimesheetsStore;

  billsStore: BillsStore;

  myDevsStore: MyDevsStore;

  badgesStore: BadgesStore;

  profileStore: ProfileStore;

  payoutMethodsStore: PayoutMethodsStore;

  constructor() {
    this.utilityStore = new UtilityStore(this);
    this.menuStore = new MenuStore(this);
    this.jobStore = new JobStore(this);
    this.selectorStore = new SelectorStore(this);
    this.userStore = new UserStore(this);
    this.timesheetsStore = new TimesheetsStore(this);
    this.billsStore = new BillsStore(this);
    this.myDevsStore = new MyDevsStore(this);
    this.badgesStore = new BadgesStore(this);
    this.profileStore = new ProfileStore(this);
    this.payoutMethodsStore = new PayoutMethodsStore(this);
  }
}
