import { action, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import {
  IBadgeItem,
  IMentionableUser,
  IScorecardItem,
  ISkillItem,
  ITalentManager,
} from '../interfaces/selector';
import { ICareerItem } from '../interfaces/careers';
import selectorService from '../api/selector';

export default class SelectorStore {
  root: RootStore;

  talentManagers: ITalentManager[] = [];

  skills: ISkillItem[] = [];

  scorecards: IScorecardItem[] = [];

  mentionableUsers: IMentionableUser[] = [];

  jobOpenings: ICareerItem[] = [];

  error: string = '';

  badges: IBadgeItem[] = [];

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchTalentManagers: action.bound,
      fetchScorecards: action.bound,
      fetchSkills: action.bound,
      fetchMentionableUsers: action.bound,
      fetchBadges: action.bound,
      fetchCareers: action.bound,
    });
  }

  async fetchTalentManagers() {
    const res = await selectorService.fetchTalentManagers();
    runInAction(() => {
      this.talentManagers = res;
    });
  }

  async fetchSkills() {
    const res = await selectorService.fetchSkills();
    runInAction(() => {
      this.skills = res.skills;
    });
  }

  async fetchCareers() {
    const res = await selectorService.careers();
    runInAction(() => {
      this.jobOpenings = res.job_openings;
    });
  }

  async fetchMentionableUsers() {
    const res = await selectorService.fetchMentionableUsers('');

    runInAction(() => {
      this.mentionableUsers = getUniqueMentionableUsers(res.users);
    });
  }

  async fetchBadges() {
    const res = await selectorService.fetchBadges();
    runInAction(() => {
      this.badges = res.badges;
    });
  }

  async fetchScorecards() {
    try {
      const res = await selectorService.fetchScorecards();
      runInAction(() => {
        this.scorecards = res.score_cards;
      });
    } catch (e: any) {
      runInAction(() => {
        this.error = e?.message || 'An unknown error occured fetching scorecards';
      });
    }
  }
}

const getUniqueMentionableUsers = (users: IMentionableUser[]) => {
  const usernames: { [key: string]: number } = {};
  const uniqUsers: IMentionableUser[] = [];

  users.forEach((user: any) => {
    if (usernames[user.username]) return;

    usernames[user.username] = 1;
    uniqUsers.push(user);
  });

  return uniqUsers;
};
