import { defaultGeneralError, IGeneralError } from './general';

export const ACTIVE_STATUS = 'ACTIVE';
export const PENDING_STATUS = 'ACTIVE';
const statusKeys = [ACTIVE_STATUS, PENDING_STATUS];

export type PayoutStatus = typeof statusKeys[number];

export interface IGetAvailablePayoutMethodsResponse {
  error: IGeneralError;
  payout_methods: IPayoutMethod[];
}

export interface IGetCurrentPayoutMethodsResponse {
  error: IGeneralError;
  payout_methods: IPayoutMethod[];
}

export interface IGetRedirectResponse {
  error: IGeneralError;
  redirect_to: string;
}

export interface IPayoutMethod {
  id: string;
  name: string;
  status: PayoutStatus;
}

export interface IPayoutMethodErrors {
  availablePayoutMethods: string;
  currentPayoutMethods: string;
}

export interface IServiceRedirect extends IGetRedirectResponse {
  id: string;
}

export const defaultPayoutMethodErrors: IPayoutMethodErrors = {
  availablePayoutMethods: '',
  currentPayoutMethods: '',
};

export const emptyServiceRedirect: IServiceRedirect = {
  error: defaultGeneralError,
  id: '',
  redirect_to: '',
};
