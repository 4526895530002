import { action, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import { emptyJobItem, IGetJobsRequest, IJobItem } from '../interfaces/job';
import jobsService from '../api/job';

export default class JobStore {
  root: RootStore;

  jobsList: IJobItem[] = [];

  totalJobs: number = 0;

  hiddenJobs: number = 0;

  isLoadingMore: boolean = false;

  hasMoreResults: boolean = true;

  page: number = 1;

  perPage: number = 10;

  preVettingOpenUid: string | null = null;

  job: IJobItem = emptyJobItem;

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchJobs: action.bound,
      setIsLoadingMore: action.bound,
      setHasMoreResults: action.bound,
      setPage: action.bound,
      setPerPage: action.bound,
      setPreVettingOpenUid: action.bound,
      fetchSingleJob: action.bound,
    });
  }

  async fetchJobs(payload: IGetJobsRequest, freshBatch: boolean) {
    const res = await jobsService.getJobs(payload);
    runInAction(() => {
      if (freshBatch) {
        this.jobsList = [];
        this.totalJobs = 0;
        this.hiddenJobs = 0;
        this.hasMoreResults = true;
      }
      if (res.jobs.length < payload.per_page) {
        this.hasMoreResults = false;
      }

      !freshBatch ? this.jobsList.push(...res.jobs) : (this.jobsList = res.jobs);
      this.totalJobs = res.total;
      this.hiddenJobs = res.hidden;
    });
  }

  async fetchSingleJob(uid: string, developer_uid: string) {
    const res = await jobsService.getSingleJob(uid, developer_uid);
    runInAction(() => {
      if (res?.job) {
        this.job = res.job;
      }
    });
  }

  setIsLoadingMore(newState: boolean) {
    this.isLoadingMore = newState;
  }

  setHasMoreResults(newState: boolean) {
    this.hasMoreResults = newState;
  }

  setPage(newPage: number) {
    this.page = newPage;
  }

  setPerPage(newPerPage: number) {
    this.perPage = newPerPage;
  }

  setPreVettingOpenUid(uid: string | null) {
    this.preVettingOpenUid = uid;
  }
}
