import { FC, useState } from 'react';
import { observer } from 'mobx-react';
import { Button } from '@upstackhq/component-library';
import { EducationForm } from './Forms/EducationForm';
import { EducationInfoBox } from './InfoBoxes/EducationInfoBox';
import { useRootStore } from '../../providers/RootStoreProvider';

export const EducationSection: FC = observer(() => {
  const {
    profileStore: {
      profileData: { education },
    },
  } = useRootStore();
  const [newEducations, setNewEducations] = useState<{ key: number }[]>([]);

  const onAddNewEducation = () => {
    setNewEducations([
      ...newEducations,
      { key: newEducations.length ? newEducations[newEducations.length - 1].key + 1 : 1 },
    ]);
  };
  return (
    <div>
      <div className="flex justify-between mb-3">
        <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Education</h2>
        <Button
          content="Add new"
          backgroundColor="white"
          textColor="blue"
          link
          customClasses="pl-0 pr-0"
          onClick={onAddNewEducation}
        />
      </div>

      {education.map((education) => (
        <EducationInfoBox key={education.uid} education={education} />
      ))}

      {newEducations.map(({ key }) => (
        <EducationForm
          key={key}
          onClose={() => setNewEducations(newEducations.filter(({ key: k }) => k !== key))}
          onComplete={() => setNewEducations(newEducations.filter(({ key: k }) => k !== key))}
        />
      ))}
    </div>
  );
});
