import { FC, ReactNode, useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';

type props = {
  children: ReactNode;
  tooltipText?: string;
  tooltipPadding?: string;
  tooltipBackground?: string;
  tooltipAction?: Function;
};

export const Tooltip: FC<props> = (props) => {
  const { children, tooltipText, tooltipPadding, tooltipBackground, tooltipAction } = props;

  const tipRef = useRef(null);
  const childRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    // @ts-ignore
    createPopper(childRef.current, tipRef.current, {
      placement: 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, tooltipAction ? 0 : 5],
          },
        },
      ],
    });
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleAction = () => {
    if (tooltipAction) {
      tooltipAction();
    }
  };

  const tooltipTextClassName = `
    ${showTooltip && tooltipText ? '' : 'hidden'}
    ${tooltipAction ? 'cursor-pointer' : 'cursor-default'}
    ${tooltipBackground || 'opacity-80 bg-gray-500'}
    ${tooltipPadding || 'px-2 py-1'}
    absolute text-sm text-white text-bold rounded flex flex-wrap break-words z-50
    max-w-sm
  `;

  return (
    <div
      onPointerEnter={handleMouseEnter}
      onPointerLeave={handleMouseLeave}
      className="inline-block"
    >
      <div className={tooltipTextClassName} ref={tipRef} onClick={handleAction}>
        {tooltipText}
      </div>
      <div ref={childRef}>{children}</div>
    </div>
  );
};
