import React, { FC } from 'react';

type TNoResults = {
  title?: string;
  message?: string;
};
export const NoResults: FC<TNoResults> = (props) => (
  <div className="w-full h-64 flex flex-col items-center justify-center">
    <p className="text-4xl bold text-gray-400 mb-4">
      {props.title ? props.title : 'Nothing found!'}
    </p>
    <p className="text-5xl text-gray-200 text-center mb-5">¯\_(ツ)_/¯</p>
    <p className="text-gray-800 text-center">
      {props.message ? (
        props.message
      ) : (
        <>
          We couldn't find anything to match your search. <br />
          Please select developer or adjusting your filters...
        </>
      )}
    </p>
  </div>
);
