import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Transition } from '@headlessui/react';
import { Button } from '@upstackhq/component-library';
import { useRootStore } from '../providers/RootStoreProvider';
import { TimePeriodRow } from '../components/Bills/TimePeriodRow';
import { IBillItem, ITimePeriodItem } from '../interfaces/bills';
import { BillRow } from '../components/Bills/BillRow';
import { numToCurrency } from '../utils';
import { BillsTable } from '../components/Bills/BillsTable';
import { TimePeriods } from '../components/Bills/TimePeriods';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';

type TQueueTotal = {
  totalBill: number;
  hours: number;
};
const emptyQueueTotal = { totalBill: 0, hours: 0 };

export const BillsPage: FC = observer(() => {
  const [queueTotal, setQueueTotal] = useState<TQueueTotal>(emptyQueueTotal);
  const [timePeriodQueue, setTimePeriodQueue] = useState<ITimePeriodItem[]>([]);
  const [allBills, setAllBills] = useState<IBillItem[]>([]);

  const {
    billsStore: {
      errors,
      fetchBills,
      fetchingBills,
      fetchTimePeriods,
      pagination,
      pendingBills,
      submitting,
      submitBill,
      submittedBills,
      timePeriods,
      timePeriodsLoading,
    },
    myDevsStore: { selectedDev },
  } = useRootStore();

  useEffect(() => {
    if (!selectedDev) return;

    const devUid = selectedDev.uid;
    if (!devUid.length) return;

    fetchTimePeriods(devUid);
    fetchBills(devUid);
    setTimePeriodQueue([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDev?.uid]);

  useEffect(() => {
    const bills = [...submittedBills, ...pendingBills].sort(sortByDate).reverse();

    setAllBills(bills);
  }, [submittedBills, pendingBills]);

  useEffect(() => {
    if (!timePeriodQueue.length) return;

    setQueueTotal({
      hours: timePeriodQueue.map((period) => period.hours).reduce((prev, curr) => prev + curr),
      totalBill: timePeriodQueue.map((period) => period.total).reduce((prev, curr) => prev + curr),
    });
  }, [timePeriodQueue]);

  const handleFetchBills = (page?: number) => {
    const uid = selectedDev?.uid;
    if (!uid?.length) return;

    fetchBills(uid, page);
  };

  const handleSubmit = async () => {
    const uid = selectedDev?.uid;
    if (!uid?.length) return;

    const queuedPeriodIds = timePeriods
      .filter((timePeriod) => isTimePeriodQueued(timePeriod))
      .map((timePeriod) => timePeriod.uid);

    await submitBill({ time_periods_uuids: queuedPeriodIds, developer_uid: uid });
    if (!errors.submit.length) {
      setTimePeriodQueue([]);
    }
  };

  const retryFetchTimePeriods = () => {
    const uid = selectedDev?.uid;
    if (!uid?.length) return;

    fetchTimePeriods(uid);
  };

  const retryFetchBills = () => {
    const uid = selectedDev?.uid;
    if (!uid?.length) return;

    fetchBills(uid);
  };

  const sortByDate = <T extends { created_at: Date }>(a: T, b: T) =>
    a.created_at > b.created_at ? 1 : -1;

  const addToBill = (period: ITimePeriodItem) => {
    const newPeriods = [period, ...timePeriodQueue];
    setTimePeriodQueue(newPeriods);
  };

  const removeQueuedPeriod = (uid: string) => {
    const newPeriods = timePeriodQueue.filter((timePeriod) => timePeriod.uid !== uid);

    setTimePeriodQueue(newPeriods);
  };

  const isTimePeriodQueued = (timePeriod: ITimePeriodItem) => {
    const queuedIds = timePeriodQueue.map((t) => t.uid);
    return queuedIds.indexOf(timePeriod.uid) > -1;
  };

  const timePeriodRows = timePeriods?.map((timePeriod, index) => {
    const pendingTimePeriods = timePeriods?.filter((timePeriod) => !isTimePeriodQueued(timePeriod));

    return (
      <TimePeriodRow
        key={timePeriod.uid}
        addToBill={() => addToBill(timePeriod)}
        isLast={pendingTimePeriods.length === index + 1}
        timePeriod={timePeriod}
        visible={!isTimePeriodQueued(timePeriod)}
      />
    );
  });

  const latestQueuedPeriod = timePeriods
    ?.filter((timePeriod) => isTimePeriodQueued(timePeriod))
    .map((timePeriod) => timePeriod.uid)[0];

  const queuedRows = timePeriods?.map((timePeriod) => (
    <TimePeriodRow
      key={timePeriod.uid}
      isFirst={timePeriod.uid === latestQueuedPeriod}
      remove={() => removeQueuedPeriod(timePeriod.uid)}
      timePeriod={timePeriod}
      visible={isTimePeriodQueued(timePeriod)}
    />
  ));

  const billsRows = allBills?.map((bill) => <BillRow key={bill.uid} bill={bill} />);

  const BillsContainer: FC = useCallback(
    (props) => (
      <main className="max-w-3xl w-full text-sm pb-14">
        <header className="mb-4">
          <h2 className="text-3xl font-bold mb-5">Bills</h2>
          <p className="text-black">Your place to create bills and get paid 💰</p>
        </header>

        <DeveloperSelector selected={selectedDev?.uid} />

        {props.children}
      </main>
    ),
    [selectedDev?.uid]
  );

  if (!selectedDev?.uid.length) return <BillsContainer />;

  return (
    <BillsContainer>
      <TimePeriods
        error={errors.fetchTimePeriods}
        loading={timePeriodsLoading}
        retry={retryFetchTimePeriods}
        rows={timePeriodRows}
      />

      <Transition
        className="w-full animate-height mb-8"
        show={!!timePeriodQueue.length}
        enter="transition-all duration-500"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-all duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <h4 className="mb-2 text-xl font-bold">Create a bill</h4>
        <div className="mb-2 w-full grid border border-gray-300 rounded">
          <div className="grid grid-cols-4 sm:grid-cols-8 p-1 text-lg font-bold border-b border-gray-400">
            <div className="col-span-2 sm:col-span-3">Period</div>
            <div className="hidden sm:block">Hours</div>
            <div className="hidden sm:block">Rate</div>
            <div className="">Total</div>
            <div className="sm:col-span-2" />
          </div>

          {queuedRows}

          <div className="p-1 border-t-2 border-black bg-gray-100 text-lg font-bold grid grid-cols-4 sm:grid-cols-8">
            <span className="sm:col-span-2">Total</span>
            <span className="hidden sm:inline sm:col-start-4">{queueTotal?.hours}</span>
            <span className="col-start-3 sm:col-start-6">
              {numToCurrency(queueTotal?.totalBill)}
            </span>
          </div>
        </div>
        <div className="flex w-full">
          <Button
            backgroundColor="green"
            content="Submit my bill"
            customClasses="justify-center"
            isLoading={submitting}
            onClick={handleSubmit}
            textColor="white"
          />
          {errors.submit.length ? (
            <div className="inline-flex font-bold text-red-600 self-center ml-4">{`Error: ${errors.submit}`}</div>
          ) : null}
        </div>
      </Transition>

      <BillsTable
        error={errors.fetchBills}
        fetchBills={handleFetchBills}
        handleRetry={retryFetchBills}
        loading={fetchingBills}
        pagination={pagination}
        rows={billsRows}
      />
    </BillsContainer>
  );
});
