import { FC, MouseEvent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Transition } from '@headlessui/react';
import { CalendlyEventListener } from 'react-calendly';
import { Tag, Button } from '@upstackhq/component-library';
import {
  IBadgeTagItem,
  IDeveloperBadgeItem,
  IStartDeveloperBadgePayload,
} from '../../interfaces/badge';
import { colorMap } from '../../constants';
import { useRootStore } from '../../providers/RootStoreProvider';
import { Loading } from '../Notifications/Loading';

export interface IBadgeProps {
  data: IDeveloperBadgeItem;
  videoAskQuestionUid?: string;
  videoAskContactUid?: string;
}

export const Badge: FC<IBadgeProps> = observer((props) => {
  const {
    userStore: {
      authData: { name, email },
    },
    badgesStore: { setStartedBadgeUid, startedBadgeUid, startDeveloperBadge },
  } = useRootStore();
  const badgeData = props.data ?? {};
  const [showData, setShowData] = useState<boolean>(false);
  const [dataEmbedLoading, setDataEmbeddedLoading] = useState(false);
  const isMobile = window.innerWidth <= 800;
  const badgeRef = useRef<HTMLDivElement>(null);

  const handleGetStarted = () => (e: MouseEvent) => {
    e.preventDefault();
    setStartedBadgeUid(badgeData.uid);
  };

  useEffect(() => {
    startedBadgeUid && startedBadgeUid === badgeData.uid ? setShowData(true) : setShowData(false);
  }, [badgeData.uid, startedBadgeUid]);

  useEffect(() => {
    showData ? setDataEmbeddedLoading(true) : setDataEmbeddedLoading(false);
  }, [showData]);

  useEffect(() => {
    const canStartBadge =
      badgeData.uid === startedBadgeUid && props.videoAskContactUid && props.videoAskQuestionUid;

    if (!canStartBadge) return;

    startBadge({
      video_ask_question_id: props.videoAskQuestionUid,
      video_ask_contact_id: props.videoAskContactUid,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [badgeData.uid, props.videoAskContactUid, props.videoAskQuestionUid, startedBadgeUid]);

  const startBadge = (payload: IStartDeveloperBadgePayload) => {
    startDeveloperBadge({
      badge_uid: startedBadgeUid,
      calendly_event_id: payload.calendly_event_id,
      video_ask_question_id: payload.video_ask_question_id,
      video_ask_contact_id: payload.video_ask_contact_id,
    }).then((response) => {
      if (response) {
        badgeData.is_pending = true;
        badgeData.button_text = 'Pending review';
        setStartedBadgeUid(null);
      }
    });
  };

  const renderData = () => {
    let render = null;
    if (badgeData.data) {
      switch (badgeData.type) {
        case 1:
          render = (
            <CalendlyEventListener
              onEventScheduled={(e) => {
                startBadge({
                  calendly_event_id: e.data.payload.event.uri.replace(
                    'https://api.calendly.com/scheduled_events/',
                    ''
                  ),
                });
              }}
            >
              <iframe
                title="Calendar"
                onLoad={() => setDataEmbeddedLoading(false)}
                frameBorder="0"
                className="lg:-mt-10 mt-5 min-w-full relative transition-height ease-in"
                src={`${badgeData.data}?hide_event_type_details=${
                  isMobile ? '1' : '0'
                }&hide_landing_page_details=1&hide_gdpr_banner=1&embed_type=Inline&embed_domain=${
                  window.location.hostname
                }&name=${name}&email=${email}`}
                style={{
                  minHeight: dataEmbedLoading ? 0 : isMobile ? '600px' : '750px',
                  opacity: dataEmbedLoading ? 0 : 1,
                }}
              />
            </CalendlyEventListener>
          );
          break;
        case 2:
          render = (
            <iframe
              title="VideoAsk "
              src={badgeData.data}
              allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              width="100%"
              height="400px"
              style={{ border: 'none' }}
              onLoad={() => setDataEmbeddedLoading(false)}
              className="mt-4 mb-4 sm:ml-0 sm:mr-0 md:ml-4 md:mr-4 min-w-full relative transition-height ease-in rounded-xl"
            />
          );
      }

      return (
        <>
          <p className="text-base uppercase font-bold text-green-600 mb-2 text-center">
            {badgeData.type === 1
              ? `Please select a date and time for your ${badgeData.title}`
              : 'Please record a short video telling us more about yourself'}
          </p>
          {dataEmbedLoading && <Loading />}
          {render}
        </>
      );
    }
  };
  return (
    <div className="w-full transition-all duration-500 flex" ref={badgeRef}>
      <div className="border border-gray-100 rounded-md p-4 mb-4 mr-2 flex-1 items-stretch">
        <div className="flex flex-1 flex-col md:flex-row">
          {badgeData.icon && (
            <div className="mr-5 mb-3 md:mb-0 justify-center mb:justify-start flex">
              <img alt={badgeData.title} className="mr-3 w-24 h-24" src={badgeData.icon} />
            </div>
          )}
          <div className="flex flex-col flex-1">
            <h3 className="mb-2 flex">
              <strong>{badgeData.title}</strong>
            </h3>
            {badgeData.content && (
              <p className="mb-2 text-sm w-full md:w-10/12">{badgeData.content}</p>
            )}
            {(badgeData.tags.length || badgeData.skills.length > 0) && (
              <div className="flex flex-row mb-4 flex-wrap">
                {badgeData.tags.map((item: IBadgeTagItem, index) => {
                  return (
                    <Tag
                      textColor={colorMap(item.key)}
                      backgroundColor={colorMap(item.key)}
                      key={index}
                      content={item.name}
                      bold
                      uppercase
                    />
                  );
                })}
                {badgeData.skills.map((item, index) => {
                  return (
                    <Tag
                      textColor="blue"
                      backgroundColor="blue"
                      key={index}
                      content={item}
                      bold
                      uppercase
                    />
                  );
                })}
              </div>
            )}
            <Transition
              show={!badgeData.is_completed && !showData}
              enter="transition-all ease-in duration-150"
              enterFrom="max-h-0 opacity-0"
              enterTo="max-h-96 opacity-100"
              leave="transition-all ease-out duration-200"
              leaveFrom="max-h-96 opacity-100"
              leaveTo="max-h-0 opacity-0"
            >
              <Button
                content={badgeData.button_text || 'Get Started'}
                backgroundColor="green"
                disabled={!badgeData.can_start || badgeData.is_pending}
                textColor="white"
                onClick={handleGetStarted()}
              />
            </Transition>
          </div>
        </div>
        <Transition
          show={showData && badgeData.can_start && !badgeData.is_pending && !badgeData.is_completed}
          enter="transition-all ease-in duration-150"
          enterFrom="max-h-0 opacity-0"
          enterTo="max-h-96 opacity-100"
          leave="transition-all ease-out duration-500"
          leaveFrom="max-h-96 opacity-100"
          leaveTo="max-h-0 opacity-0"
          beforeLeave={() => badgeRef?.current?.scrollIntoView({ behavior: 'smooth' })}
          className="overflow-hidden"
        >
          <div className="w-full flex flex-col">{renderData()}</div>
          <div className="flex flex-row items-center mt-3">
            <Button
              content="Cancel"
              backgroundColor="white"
              textColor="red"
              onClick={() => setStartedBadgeUid(null)}
              link
              customClasses="ml-3 text-sm"
            />
          </div>
        </Transition>
      </div>
    </div>
  );
});
