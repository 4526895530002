import { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Button } from '@upstackhq/component-library';
import { useRootStore } from '../providers/RootStoreProvider';
import { NewDevRow } from '../components/MyDevs/NewDevRow';
import { WorkingDevRow } from '../components/MyDevs/WorkingDevRow';
import { WorkingDevsTable } from '../components/MyDevs/WorkingDevsTable';
import { NewDevsTable } from '../components/MyDevs/NewDevsTable';

export const MyDevsPage: FC = observer(() => {
  const history = useHistory();
  const location: { pathname: string; search: string; state: { added_dev_uid: string } } =
    useLocation();
  const {
    myDevsStore: {
      errors,
      fetchNewDevs,
      fetchWorkingDevs,
      fetchingNewDevs,
      fetchingWorkingDevs,
      newDevs,
      workingDevs,
      paginationNew,
      paginationWorking,
      setSelectedDev,
    },
    payoutMethodsStore: { fetchCurrentPayoutMethods, currentPayoutMethods },
  } = useRootStore();

  useEffect(() => {
    fetchNewDevs();
    fetchWorkingDevs();
    fetchCurrentPayoutMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openForm = () => {
    history.push('/add-developer');
  };

  const handleFetchNewDevs = (page?: number) => {
    fetchNewDevs(page);
  };

  const handleFetchWorkingDevs = (page?: number) => {
    fetchWorkingDevs(page);
  };

  const newDevsRows = newDevs.map((dev) => (
    <NewDevRow
      key={dev.uid}
      developer={dev}
      highlight={location.state && location.state.added_dev_uid === dev.uid}
      selectDev={() => setSelectedDev(dev.uid)}
    />
  ));

  const workingDevsRows = workingDevs.map((dev) => (
    <WorkingDevRow developer={dev} key={dev.uid} selectDev={() => setSelectedDev(dev.uid)} />
  ));

  return (
    <main className="max-w-3xl w-full text-sm pb-14">
      <header className="mb-8">
        <h2 className="text-3xl font-bold mb-5">My Devs</h2>
        <p className="text-black">A place for all your devs to live and thrive! ⛸️</p>
      </header>
      <NewDevsTable
        error={errors.fetchDevs}
        fetchDevs={handleFetchNewDevs}
        loading={fetchingNewDevs}
        pagination={paginationNew}
        rows={newDevsRows}
      />
      <Button
        backgroundColor="green"
        content="Add New Developer"
        customClasses="justify-center"
        onClick={openForm}
        textColor="white"
      />
      <WorkingDevsTable
        error={errors.fetchDevs}
        fetchDevs={handleFetchWorkingDevs}
        loading={fetchingWorkingDevs}
        pagination={paginationWorking}
        rows={workingDevsRows}
      />
    </main>
  );
});
