import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';
import {
  defaultSelectorBadgesResponse,
  defaultSelectorSkillsResponse,
  ISelectorBadgesResponse,
  ISelectorSkillsResponse,
} from '../interfaces/selector';
import { ICareersResponse, defaultCareerResponse } from '../interfaces/careers';

const selectorService = {
  fetchSkills: async () => {
    try {
      const res: AxiosResponse<ISelectorSkillsResponse> = await axios.get(
        `${API_URL}/selectors/v2/skills`
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultSelectorSkillsResponse;
    }
  },

  careers: async () => {
    try {
      const res: AxiosResponse<ICareersResponse> = await axios.get(`${API_URL}/careers`);

      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultCareerResponse;
    }
  },

  fetchBadges: async () => {
    try {
      const res: AxiosResponse<ISelectorBadgesResponse> = await axios.get(
        `${API_URL}/selectors/badges?category=1`
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultSelectorBadgesResponse;
    }
  },

  countryCode: async () => {
    try {
      const res: AxiosResponse = await axios.get('https://api.upstack.co/selectors/country-code');

      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultSelectorBadgesResponse;
    }
  },

  fetchMentionableUsers: async (search: string) => {
    const reqUrl = `${API_URL}/selectors/mentionable-users${
      search ? `?text_search=${search}` : ''
    }`;
    try {
      const res: AxiosResponse = await axios.get(reqUrl);
      return res.data;
    } catch (e: any) {
      const msg = e?.message.length ? e.message : 'Could not get mentionable users';
      throw new Error(msg);
    }
  },

  fetchScorecards: async () => {
    try {
      const res: AxiosResponse = await axios.get(`${API_URL}/selectors/score-cards`);
      return res.data;
    } catch (e: any) {
      const msg = e?.message.length ? e.message : 'Could not get available scorecards';
      throw new Error(msg);
    }
  },

  fetchTalentManagers: async () => {
    try {
      const res: AxiosResponse = await axios.get(`${API_URL}/selectors/talent-managers`);

      if (res.data.error.has_error) {
        throw new Error(res.data.error.message);
      }

      return res.data.talent_managers;
    } catch (e: any) {
      const msg = e?.message.length ? e.message : 'Could not get available scorecards';
      throw new Error(msg);
    }
  },
};

export default selectorService;
