import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import {
  defaultAuthResponse,
  defaultAvailability,
  IAuthRequest,
  IAuthResponse,
  IAvailability,
  IForgotPasswordRequest,
  IResetPasswordRequest,
  TAvailabilityStatus,
} from '../interfaces/user';

import { STORAGE_NAME } from '../constants';
import { RootStore } from './index';
import userService from '../api/user';

export default class UserStore {
  root: RootStore;

  authData: IAuthResponse = defaultAuthResponse;

  availability: IAvailability = defaultAvailability;

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      isAuthenticated: computed,
      updateAvailability: action.bound,
      performAuth: action.bound,
      performForgotPassword: action.bound,
      logout: action.bound,
      finishResetPassword: action.bound,
      fetchAvailability: action.bound,
      impersonate: action.bound,
    });
    const cookieUser: string | null = localStorage.getItem(STORAGE_NAME);
    let cookieAuthData: IAuthResponse = defaultAuthResponse;
    if (cookieUser) {
      cookieAuthData = JSON.parse(cookieUser);
      this.authData = cookieAuthData;
    }
  }

  get isAuthenticated() {
    return this.authData.token !== '' && this.authData.refresh_token !== '';
    // && this.authData.name !== '' &&
    // this.authData.avatar !== '' &&
    // this.authData.role !== '' &&
    // this.authData.section !== ''
  }

  // Send username and password and attempt to authenticate
  async performAuth(payload: IAuthRequest) {
    this.authData = await userService.performAuth(payload);
    localStorage.setItem(STORAGE_NAME, JSON.stringify(this.authData));
    return this.authData;
  }

  // Send username (email) to forgot password
  async performForgotPassword(payload: IForgotPasswordRequest) {
    await userService.performForgotPassword(payload);
  }

  // Send password reset call
  async finishResetPassword(payload: IResetPasswordRequest) {
    await userService.finishResetPassword(payload);
  }

  logout() {
    this.authData = defaultAuthResponse;
    this.root.menuStore.menuItems = [];
    localStorage.clear();
  }

  // Send token to authenticate as impersonating user
  async impersonate(token: string) {
    this.authData = await userService.impersonate(token);
    localStorage.setItem(STORAGE_NAME, JSON.stringify(this.authData));
  }

  async fetchAvailability() {
    this.availability.loading = true;

    try {
      const res = await userService.fetchAvailability();

      if (!res.error.has_error) {
        runInAction(() => {
          this.authData.available = res.availability;
          this.availability.fetched = true;
          this.availability.loading = false;
        });
      } else {
        runInAction(() => {
          this.availability.loading = false;
        });

        throw new Error(res.error.message);
      }
    } catch (e) {}
  }

  async updateAvailability(availability: TAvailabilityStatus) {
    const payload = { availability };
    this.availability.loading = true;

    try {
      const res = await userService.updateAvailability(payload);

      if (!res.error.has_error) {
        runInAction(() => {
          this.authData.available = availability;
          this.availability.loading = false;
        });
      } else {
        runInAction(() => {
          this.availability.loading = false;
        });

        throw new Error(res.error.message);
      }
    } catch (e: any) {
      console.error(e?.message);
    }
  }
}
