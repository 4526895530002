import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '@upstackhq/component-library';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { useRootStore } from '../../providers/RootStoreProvider';
import { Select } from '../../common/Select/Select';

type FormPayload = {
  available: string;
  availability: string;
  rate: string;
};
export const WorkSection: FC = observer(() => {
  const {
    profileStore: {
      profileData: { work },
      updateWorkSection,
      saving,
      loading,
    },
  } = useRootStore();
  const validationSchema = yup.object().shape({
    availability: yup.string().required('Work hours are required'),
    available: yup.string().required('Available for work is required'),
    rate: yup.number(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHandler = (payload: FormPayload) => {
    const castPayload = {
      rate: parseFloat(payload.rate),
      available: parseInt(payload.available),
      availability: parseInt(payload.availability),
    };
    updateWorkSection(castPayload).then(() => {
      toast.success('Profile successfully updated.');
    });
  };

  const { onChange: onRateChange, ref: rateRef } = register('rate');

  return (
    <div>
      <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Availability</h2>
      {/* Profile image */}
      <div className="mb-7">
        {work && (
          <form onSubmit={handleSubmit(onSubmitHandler)}>
            <Input
              onChange={onRateChange}
              inputRef={rateRef}
              disabled
              error={errors.rate?.message}
              defaultValue={work?.rate}
              name="rate"
              label="Developer rate"
              required
            />
            <Select
              defaultValue={work?.available}
              error={errors.available?.message}
              name="available"
              placeholder="Select Available*"
              label="Available"
              register={register}
              required
            >
              <option value="" hidden label="Select Available*" />
              <option value={1} label="Available now" />
              <option value={0} label="Not available now" />
            </Select>

            <Select
              defaultValue={work?.availability}
              error={errors.availability?.message}
              name="availability"
              placeholder="Select Work hours*"
              label="Work hours"
              register={register}
              required
            >
              <option value="" hidden label="Select Work hours*" />
              <option value={0} disabled label="Not Available" />
              <option value={40} label="Full-Time" />
              <option value={20} label="Part-Time" />
            </Select>
            <div className="flex justify-end mt-5">
              <Button
                type="submit"
                content="Save"
                uppercase
                backgroundColor="green"
                textColor="white"
                bold
                isLoading={saving.work}
                noLockIcon
                disabled={loading || saving.work}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
});
