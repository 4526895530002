export interface IGeneralError {
  has_error: boolean;
  code: number;
  message: string;
}

export interface IDefaultEmptyResponse {
  error: IGeneralError;
}

/** * Empties ** */
export const defaultGeneralError: IGeneralError = {
  has_error: false,
  code: 200,
  message: '',
};
export const defaultEmptyResponse: IDefaultEmptyResponse = {
  error: defaultGeneralError,
};

export interface IPagination {
  pages: number;
  total_results: number;
}
