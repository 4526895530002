import { FC, ReactElement, useEffect, useState } from 'react';
import { observer } from 'mobx-react';

type Props = {
  currentStep: number;
  steps?: {
    title: string;
    component: ReactElement;
    required: boolean;
    updateContent: Function;
    data: any;
  }[];
};
export const WizardHeader: FC<Props> = observer((props) => {
  const [step, setStep] = useState<ReactElement | null>(null);
  const { currentStep, steps = [] } = props;

  useEffect(() => {
    if (!steps?.length) return;

    const step = steps[currentStep - 1];
    if (!step) return;

    const stepEl = (
      <div className="text-lg font-bold text-gray-700 leading-tight">{step.title}</div>
    );

    setStep(stepEl);
  }, [currentStep, steps]);

  return (
    <div className="border-b-2 py-4">
      <div className="uppercase tracking-wide text-xs font-bold text-gray-500 mb-1 leading-tight">
        Step {currentStep} of {steps.length}
      </div>
      <div className="flex flex-col md:flex-row md:items-center md:justify-between">
        <div className="flex-1">{step}</div>

        <div className="flex items-center md:w-64">
          <div className="w-full bg-gray-100 rounded-full mr-2">
            <div
              className="rounded-full bg-green-500 text-xs leading-none h-2 text-center text-white"
              style={{ width: `${(currentStep / steps.length) * 100}%` }}
            />
          </div>
          <div className="text-xs w-10 text-gray-600">
            {((currentStep / steps.length) * 100).toFixed(0)}%
          </div>
        </div>
      </div>
    </div>
  );
});
