import { ChangeEvent, FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Checkbox, Datepicker, Input } from '@upstackhq/component-library';
import { Select } from '../../../common/Select/Select';
import { countries } from '../../../constants';
import { IEducation } from '../../../interfaces/developers';

type props = {
  education?: IEducation;
  onClose: Function;
  onComplete?: Function;
  onRemove?: Function;
  index?: number;
};
export const EducationForm: FC<props> = observer(
  ({ education, onClose, onComplete, onRemove, index }) => {
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();
    const [current, setCurrent] = useState<boolean>(false);
    const scrollRef = useRef<HTMLDivElement>(null);

    const validationSchema = yup.object().shape({
      institution: yup.string().required('Institution is required'),
      location: yup.string().required('Location is required'),
      // from: yup.date().required("Start date is required").max(new Date(), "Start date cannot be in the future!").nullable(),
      until: yup
        .date()
        .nullable()
        .when('current', {
          is: false,
          then: yup
            .date()
            .default(undefined)
            .required('End date is required')
            .min(yup.ref('from'), 'End date cannot be before start date!')
            .nullable(),
        }),
      current: yup.boolean().default(false),
      description: yup.string().required('Description is required'),
    });

    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      getValues,
    } = useForm({
      resolver: yupResolver(validationSchema),
    });

    const { ref: currentRef, onChange: onCurrentChange } = register('current');

    useEffect(() => {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
      if (!current) return;

      setValue('until', null, { shouldDirty: true });
      setEndDate(undefined);
    }, [current, setValue]);

    useEffect(() => {
      if (!education) return;

      if (education.current) {
        setCurrent(true);
      }

      if (education.from) {
        const startDate = new Date(
          typeof education?.from === 'number' ? education?.from * 1000 : education?.from
        );
        setValue('from', startDate, { shouldDirty: false });
        setStartDate(startDate);
      }

      if (education.until) {
        const endDate = new Date(
          typeof education?.until === 'number' ? education?.until * 1000 : education?.until
        );
        setValue('until', endDate, { shouldDirty: false });
        setEndDate(endDate);
      }
    }, [education, setValue]);

    const onSubmitHandler = (payload: IEducation) => {
      payload.until = payload.current ? null : getValues('until');
      if (onComplete) {
        onComplete(payload);
      }
    };

    const handleCurrentChange = (e: ChangeEvent<HTMLInputElement>) => {
      setCurrent(!current);
      onCurrentChange(e);
    };

    const { onChange: onDescriptionChange, ref: descriptionRef } = register('description');
    const { onChange: onInstitutionChange, ref: institutionRef } = register('institution');

    return (
      <div className="p-3 mb-p5 border-2 border-blue-100 bg-gray-100 rounded-md" ref={scrollRef}>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            onChange={onDescriptionChange}
            inputRef={descriptionRef}
            name="description"
            label="Degree"
            defaultValue={education?.description}
            error={errors.description?.message}
          />
          <Input
            onChange={onInstitutionChange}
            inputRef={institutionRef}
            name="institution"
            label="Institution"
            defaultValue={education?.institution}
            error={errors.institution?.message}
          />
          <Select
            defaultValue={education?.location}
            error={errors.location?.message}
            name="location"
            placeholder="Select Location*"
            register={register}
            label="Location"
            required
          >
            <option value="" hidden label="Select Location*" />
            {countries.map((country: string) => (
              <option key={country} value={country}>
                {country}
              </option>
            ))}
          </Select>
          <Datepicker
            name="from"
            monthYear
            label="Start date"
            onChange={(date: Date) => {
              setStartDate(
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
              );
              setValue(
                'from',
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
                { shouldValidate: true }
              );
            }}
            value={startDate}
            error={errors.from?.message}
          />
          <Datepicker
            name="until"
            monthYear
            disabled={current}
            label="End date"
            onChange={(date: Date) => {
              setEndDate(
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
              );
              setValue(
                'until',
                new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0)),
                { shouldValidate: true }
              );
            }}
            value={endDate}
            error={errors.until?.message}
          />
          <Checkbox
            checkboxRef={currentRef}
            defaultChecked={education?.current}
            error={errors.current?.message}
            label="Current"
            name="current"
            onChange={handleCurrentChange}
          />
          <div className="flex justify-between mt-5">
            <div>
              {index !== undefined && (
                <Button
                  type="button"
                  content="Remove"
                  customClasses="pl-0 pr-0"
                  backgroundColor="transparent"
                  textColor="red"
                  link
                  onClick={() => {
                    if (onRemove) {
                      onRemove(index);
                    }
                  }}
                />
              )}
            </div>
            <div>
              <Button
                type="button"
                content="Cancel"
                onClick={() => onClose()}
                customClasses="mr-2"
                backgroundColor="transparent"
                textColor="red"
                link
                bold
              />
              <Button
                type="submit"
                content="Save"
                noLockIcon
                uppercase
                backgroundColor="green"
                textColor="white"
                bold
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
);
