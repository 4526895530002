import { action, computed, makeAutoObservable, runInAction } from 'mobx';
import { RootStore } from './index';
import {
  IBillItem,
  IBillErrors,
  ISubmitBillRequest,
  defaultBillErrors,
  ICustomInvoiceResponseItem,
  IBillsPagination,
  defaultBillPagination,
  defaultGetBillsRequest,
  ITimePeriodJob,
  defaultSelectedJob,
  TJobTimePeriodMap,
  ITimePeriodItem,
  IGetBillsRequest,
} from '../interfaces/bills';
import billsService from '../api/bills';

const SUBMITTED_BILL_STATES = [1, 2];

export default class BillsStore {
  root: RootStore;

  customInvoiceItems: Map<number, ICustomInvoiceResponseItem> = new Map();

  fetchingBills: boolean = false;

  errors: IBillErrors = defaultBillErrors;

  jobs: ITimePeriodJob[] = [];

  jobTimePeriodMap: TJobTimePeriodMap = new Map();

  pagination: IBillsPagination = defaultBillPagination;

  pendingBills: IBillItem[] = [];

  selectedJob: ITimePeriodJob = defaultSelectedJob;

  submitting: boolean = false;

  submittedBills: IBillItem[] = [];

  timePeriodsLoading: boolean = false;

  constructor(rs: RootStore) {
    this.root = rs;
    makeAutoObservable(this, {
      fetchBills: action.bound,
      fetchTimePeriods: action.bound,
      selectJob: action.bound,
      submitBill: action.bound,
      timePeriods: computed,
    });
  }

  async fetchBills(developerUid: string, page: number = 1) {
    const payload: IGetBillsRequest = {
      ...defaultGetBillsRequest,
      ...{ page },
      developer_uid: developerUid,
    };
    const originalPage = this.pagination.currentPage;

    this.fetchingBills = true;
    this.pagination.currentPage = page;

    try {
      const res = await billsService.getBills(payload);

      runInAction(() => {
        this.pendingBills = res.list.filter((bill) => bill.state === 0);

        this.submittedBills = res.list.filter(
          (bill) => SUBMITTED_BILL_STATES.indexOf(bill.state) > -1
        );

        this.pagination.totalPages = res.pagination.pages;

        this.fetchingBills = false;
        this.errors.fetchBills = '';
      });
    } catch (e: any) {
      runInAction(() => {
        this.pagination.currentPage = originalPage;
        this.pendingBills = [];
        this.submittedBills = [];
        this.fetchingBills = false;
        this.errors.fetchBills = e?.message;
      });
    }
  }

  selectJob(uid: string) {
    const job = this.jobs.find(({ job_uid }) => job_uid === uid);
    this.selectedJob = job || defaultSelectedJob;
  }

  async fetchTimePeriods(developerUid: string) {
    this.timePeriodsLoading = true;
    try {
      const res = await billsService.getTimePeriods(developerUid);
      runInAction(() => {
        this.jobTimePeriodMap = res.jobTimePeriodMap;
        this.jobs = res.jobs;
        this.selectedJob = this.jobs[0];
        this.timePeriodsLoading = false;
        this.errors.fetchTimePeriods = '';
        this.customInvoiceItems = res.custom_items;
      });
    } catch (e: any) {
      runInAction(() => {
        this.timePeriodsLoading = false;
        this.errors.fetchTimePeriods = e?.message;
        this.customInvoiceItems = new Map();
      });
    }
  }

  get timePeriods(): ITimePeriodItem[] {
    if (this.jobs.length === 1) {
      return this.jobTimePeriodMap.get(this.jobs[0].job_uid) || [];
    }

    return this.jobTimePeriodMap.get(this.selectedJob?.job_uid) || [];
  }

  async submitBill(payload: ISubmitBillRequest) {
    this.submitting = true;

    try {
      await billsService.submitBill(payload);
      await Promise.all([
        this.fetchBills(payload.developer_uid),
        this.fetchTimePeriods(payload.developer_uid),
      ]);

      runInAction(() => {
        this.submitting = false;
        this.errors.submit = '';
      });
    } catch (e: any) {
      runInAction(() => {
        this.submitting = false;
        this.errors.submit = e?.message;
      });
    }
  }
}
