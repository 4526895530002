import { Link, useHistory, useParams } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/solid';
import React, { FormEvent, useEffect, useState } from 'react';
import PasswordStrengthBar, { PasswordFeedback } from 'react-password-strength-bar';
import dev from '../assets/img/password-reset.svg';
import logo from '../assets/img/upstack_logo.png';
import { Error } from '../components/Notifications/Error';
import { useRootStore } from '../providers/RootStoreProvider';
import { IResetPasswordRequest } from '../interfaces/user';

type TQueryParams = {
  token: string;
};

export function ResetPasswordPage() {
  const params: TQueryParams = useParams();
  const history = useHistory();
  const [newPassword, setNewPassword] = useState<string>('');
  const [checkPassword, setCheckPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>('');
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [passwordHint, setPasswordHint] = useState<string | undefined>('');
  const [passwordMatches, setPasswordMatches] = useState<boolean>(false);
  const [passwordScore, setPasswordScore] = useState<number>(0);
  const [verifyError, setVerifyError] = useState<string>('');

  const {
    userStore: { finishResetPassword },
  } = useRootStore();

  useEffect(() => {
    if (!checkPassword.length || !newPassword.length || passwordMatches) {
      setVerifyError('');
      return;
    }

    setVerifyError('The passwords do not match');
  }, [passwordMatches, newPassword, checkPassword]);

  useEffect(() => {
    setPasswordMatches(newPassword === checkPassword);
  }, [newPassword, checkPassword]);

  useEffect(() => {
    const canReset = passwordScore >= 4 && !verifyError.length && passwordMatches;

    setIsDisabled(!canReset);
  }, [verifyError, passwordScore, passwordMatches]);

  const readScore = (score: number, feedback: PasswordFeedback) => {
    setPasswordScore(score);
    setPasswordHint(feedback.warning);
  };
  const finalResetPassword = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (newPassword === '' || checkPassword === '') {
      setErrMessage('Both fields are required');
      setIsError(true);
      return;
    }
    if (newPassword !== checkPassword) {
      setErrMessage('Passwords do not match!');
      setIsError(true);
      return;
    }
    setIsLoading(true);
    const payload: IResetPasswordRequest = {
      section: 'developer',
      new_password: newPassword,
      reset_token: params.token,
    };
    finishResetPassword(payload)
      .then((res) => {
        setIsLoading(false);
        history.push('/login');
      })
      .catch(() => {
        setIsLoading(false);
        setIsError(true);
      });
  };

  return (
    <div className="min-h-screen bg-white flex">
      <div className="hidden lg:block relative w-0 flex-1 flex-col align-center">
        <img className="h-screen mx-auto" src={dev} alt="Upstack Developer Portal" />
      </div>
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        <div className="mx-auto w-full max-w-sm lg:w-96">
          <div>
            <img className="h-12 w-auto" src={logo} alt="Upstack Developer Portal" />
            <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Password Reset</h2>
            <p className="mt-2 text-sm text-gray-600">
              Finish your password reset or{' '}
              <Link to="login" className="font-medium text-blue-500 hover:text-blue-600">
                Login
              </Link>
            </p>
          </div>
          <div className="mt-8">
            <div className="mt-6">
              <form action="#" method="POST" onSubmit={(e) => finalResetPassword(e)}>
                <div className="space-y-1 relative">
                  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                    New Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      required
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className={`
                        appearance-none block w-full px-3 py-2 border border-gray-300
                        rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500
                        focus:border-blue-500 sm:text-sm
                      `}
                    />
                    <PasswordStrengthBar
                      password={newPassword}
                      onChangeScore={(score, feedback) => readScore(score, feedback)}
                    />
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="check-password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Verify Password
                  </label>
                  <div className="mt-1">
                    <input
                      id="check-password"
                      name="check-password"
                      type="password"
                      required
                      value={checkPassword}
                      onChange={(e) => setCheckPassword(e.target.value)}
                      className={`
                        appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400
                        focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm
                        ${verifyError.length ? 'border-red-600' : ''}
                      `}
                    />
                  </div>
                </div>
                <span
                  className={`block text-sm text-gray-500 mt-3 ${
                    passwordHint?.length ? 'visible' : 'invisible'
                  }`}
                >
                  {passwordHint}
                </span>
                <span
                  className={`block text-sm text-red-600 mt-3 ${
                    verifyError.length ? 'visible' : 'invisible'
                  }`}
                >
                  {verifyError}
                </span>
                <div className="mt-5">
                  <button
                    type="submit"
                    disabled={isDisabled}
                    className={`
                      w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium
                      focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400
                      ${
                        isDisabled
                          ? 'bg-gray-200 cursor-not-allowed text-black hover:bg-gray-500'
                          : 'bg-blue-500 text-white hover:bg-blue-700'
                      }`}
                  >
                    {!isLoading && <LockClosedIcon className="h-5 w-5" aria-hidden="true" />}
                    {isLoading && (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                    )}
                    Reset Password
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {isError && (
        <Error
          title="Password reset error"
          mainMessage="An error occurred during the password reset!"
          subMessage={errMessage}
          onClick={(e) => setIsError(false)}
        />
      )}
    </div>
  );
}
