import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import {
  defaultAuthResponse,
  IAuthRequest,
  IAuthResponse,
  IAvailabilityRequest,
  IAvailabilityResponse,
  IForgotPasswordRequest,
  IResetPasswordRequest,
} from '../interfaces/user';
import { API_URL } from '../constants';
import { defaultEmptyResponse, IDefaultEmptyResponse } from '../interfaces/general';

const userService = {
  updateAvailability: async (payload: IAvailabilityRequest) => {
    try {
      const res: AxiosResponse<IAvailabilityResponse> = await axios.post(
        `${API_URL}/developer/v2/update-availability`,
        payload
      );

      return res.data;
    } catch (e) {
      throw e;
    }
  },

  performAuth: async (payload: IAuthRequest) => {
    try {
      const res: AxiosResponse<IAuthResponse> = await axios
        .post(`${API_URL}/auth`, payload)
        .catch((e) => {
          throw e.response.data.error.message;
        });
      return res.data;
    } catch (e) {
      throw e;
    }
  },
  performForgotPassword: async (payload: IForgotPasswordRequest) => {
    try {
      const res: AxiosResponse<IDefaultEmptyResponse> = await axios.post(
        `${API_URL}/forgot-password`,
        payload
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultEmptyResponse;
    }
  },

  fetchAvailability: async () => {
    try {
      const res: AxiosResponse = await axios.get(`${API_URL}/developer/v2/availability`);
      return res.data;
    } catch (e) {
      throw e;
    }
  },

  finishResetPassword: async (payload: IResetPasswordRequest) => {
    try {
      const res: AxiosResponse<IDefaultEmptyResponse> = await axios.post(
        `${API_URL}/reset-password`,
        payload
      );
      return res.data;
    } catch (e) {
      // TODO: Error handling
      return defaultEmptyResponse;
    }
  },
  impersonate: async (token: string) => {
    try {
      const res: AxiosResponse<IAuthResponse> = await axios.post(`${API_URL}/auth/otp`, { token });
      return res.data;
    } catch (e) {
      toast.error('Error impersonating developer!');
      return defaultAuthResponse;
    }
  },
};

export default userService;
