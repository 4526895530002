import { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { toast } from 'react-toastify';
import { Button, InfoBox } from '@upstackhq/component-library';
import { useRootStore } from '../../providers/RootStoreProvider';
import { SkillForm } from './Forms/SkillForm';
import { ISkillItem } from '../../interfaces/selector';

type SkillData = {
  name: string;
  years: number;
};

const BLANK_SKILL = { name: '', years: 1 } as SkillData;

export const SkillsSection: FC = observer(() => {
  const [profileSkills, setProfileSkills] = useState<SkillData[]>([]);
  const {
    selectorStore: { fetchSkills, skills },
    profileStore: { profileData, updateSkillsSection, saving, loading },
  } = useRootStore();

  useEffect((): void => {
    fetchSkills();
  }, [fetchSkills]);

  useEffect((): void => {
    if (!profileData.skills?.length) return;

    setProfileSkills([...profileData.skills, BLANK_SKILL]);
  }, [profileData]);

  const hasBlank = useCallback((skills: SkillData[]) => {
    return skills.find(({ name }) => name === BLANK_SKILL.name) !== undefined;
  }, []);

  const onSkillAdded = (index: number) => (skillData: SkillData) => {
    const newSkills = [...profileSkills];
    newSkills[index] = skillData;

    if (!hasBlank(newSkills)) newSkills.push(BLANK_SKILL);

    setProfileSkills(newSkills);
  };

  const onSkillRemoved = (index: number) => () => {
    const newSkills = [...profileSkills];
    newSkills.splice(index, 1);
    setProfileSkills(newSkills);
  };

  const onSubmitHandler = () => {
    const skills = profileSkills.filter(({ name }) => name !== BLANK_SKILL.name);

    updateSkillsSection({ skills }).then(() => {
      toast.success('Profile successfully updated.');
    });
  };

  return (
    <div>
      <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Skills</h2>

      <p className="mb-5 text-sm">Tell us your top software development skills *</p>
      {profileSkills.map((skill, index) => (
        <SkillForm
          key={skill.name ? skill.name : `blank-${index}`}
          onFinish={onSkillAdded(index)}
          onRemove={onSkillRemoved(index)}
          skills={skills.filter(
            (skill: ISkillItem) =>
              !profileSkills
                .map((profileSkill: SkillData) => profileSkill.name)
                .includes(skill.name)
          )}
          clean={skill.name === BLANK_SKILL.name}
          data={skill}
        />
      ))}
      {profileSkills.length === 0 && (
        <InfoBox
          title="Enter your skills"
          description="Please share your top skills and how many years you have worked in them? Eg. Swift 4yrs, React 5yrs. Most developers enter 5-10 skills here"
          type="warning"
        />
      )}
      <div className="flex justify-end mt-5">
        <Button
          content="Save"
          uppercase
          backgroundColor="green"
          textColor="white"
          bold
          onClick={onSubmitHandler}
          disabled={loading || saving.skills}
          isLoading={saving.skills}
          noLockIcon
        />
      </div>
    </div>
  );
});
