import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useRootStore } from '../providers/RootStoreProvider';
import { Loading } from '../components/Notifications/Loading';

export function LogoutPage() {
  const { userStore } = useRootStore();
  const [moveAway, setMoveAway] = useState<boolean>(false);
  useEffect(() => {
    setTimeout(() => {
      userStore.logout();
      setMoveAway(true);
    }, 1000);
  }, [moveAway, userStore]);
  return (
    <>
      {moveAway && <Redirect to="/login" />}
      <Loading />
    </>
  );
}
