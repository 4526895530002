import React, { FC, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Button } from '@upstackhq/component-library';
import { ExperienceForm } from '../Forms/ExperienceForm';
import { IEducation, IExperienceItem } from '../../../interfaces/developers';

type props = {
  experience: IExperienceItem;
  onRemove?: Function;
  onEdit?: Function;
  index: number;
};
export const ExperienceInfoBox: FC<props> = ({ experience, index, onEdit, onRemove }) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [editExperience, setEditExperience] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (editExperience) {
    return (
      <div className="mb-5">
        <ExperienceForm
          index={index}
          experience={experience}
          onClose={() => {
            setEditExperience(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onComplete={(data: IEducation) => {
            if (onEdit) {
              onEdit(data, index);
            }
            setEditExperience(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onRemove={(index: number) => {
            if (onRemove) {
              onRemove(index);
            }
          }}
        />
      </div>
    );
  }
  return (
    <div
      className="p-3 border-2 border-blue-100 rounded-md mb-5 flex flex-col md:flex-row"
      ref={scrollRef}
    >
      {(experience?.url || experience?.logo) && (
        <div className="w-14 mr-0 md:mr-3 mt-0 md:mt-3 mb-2 md:mb-0">
          <img
            alt={experience?.role}
            className="object-contain"
            src={
              experience?.logo
                ? experience.logo
                : `//logo.clearbit.com/${experience?.url.replace('/(^\\w+:|^)\\/\\//', '')}?size=80`
            }
          />
        </div>
      )}
      <div className="flex flex-1 flex-col">
        <div className="flex justify-between items-center">
          <h4 className="font-bold text-lg">{experience?.role}</h4>
          <Button
            content="Edit"
            backgroundColor="white"
            textColor="gray"
            link
            customClasses="pb-0 pt-0 pl-0 pr-0"
            onClick={() => {
              setEditExperience(true);
            }}
          />
        </div>
        <p className="text-sm pb-2">
          {experience?.company} •{experience?.location}
        </p>
        <p className="text-blue-muted text-sm pb-2">
          {format(
            new Date(
              typeof experience?.from === 'number' ? experience?.from * 1000 : experience?.from
            ),
            'MMM yyyy'
          )}{' '}
          to{' '}
          {experience?.until
            ? format(
                new Date(
                  typeof experience?.until === 'number'
                    ? experience?.until * 1000
                    : experience?.until
                ),
                'MMM yyyy'
              )
            : 'Present'}
        </p>
        <p
          className={`text-sm pb-2 transition-all ease-linear overflow-hidden ${
            showMore ? 'overflow-y-auto overflow-x-hidden max-h-64' : 'max-h-20'
          }`}
        >
          {showMore || (experience?.highlight1 && experience?.highlight1?.length < 137)
            ? experience?.highlight1
            : `${experience?.highlight1?.substr(
                0,
                experience?.highlight1.lastIndexOf(' ', 137)
              )}...`}
          {experience?.highlight1 && experience?.highlight1?.length > 137 && (
            <span className="cursor-pointer ml-1 font-bold" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Less' : 'More'}
            </span>
          )}
        </p>
        <p className="text-sm font-bold">{experience?.skills?.join(', ')}</p>
      </div>
    </div>
  );
};
