import { defaultGeneralError, IGeneralError } from './general';

export interface ITalentManager {
  id: number;
  name: string;
}

export interface ISelectorSkillsResponse {
  error: IGeneralError;
  skills: ISkillItem[];
}

export interface ISelectorBadgesResponse {
  error: IGeneralError;
  badges: IBadgeItem[];
}

export interface ISkillItem {
  id: number;
  name: string;
  stub: string;
}

export interface IScorecardItem {
  clean_title: string;
  id: number;
  title: string;
  type: number;
}

export interface IMentionableUser {
  email: string;
  id: number;
  name: string;
  role: string;
  uid: string;
  username: string;
}

export interface IBadgeItem {
  title: string;
  uid: string;
}

/** * Empties ** */
export const defaultSelectorSkillsResponse = {
  error: defaultGeneralError,
  skills: [],
};

export const defaultSelectorBadgesResponse = {
  error: defaultGeneralError,
  badges: [],
};
