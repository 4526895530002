import React, { FC, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Button } from '@upstackhq/component-library';
import { ExperienceForm } from '../Forms/ExperienceForm';

type props = {
  experience: {
    uid: string;
    company: string;
    logo: string;
    url: string;
    location: string;
    title: string;
    start_date: number;
    end_date: number;
    highlight1: string;
    highlight2: string;
    highlight3: string;
    skills: string[];
    current_role: boolean;
  };
};
export const ExperienceInfoBox: FC<props> = (props) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [editExperience, setEditExperience] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (editExperience) {
    return (
      <div className="mb-5">
        <ExperienceForm
          experience={props.experience}
          onClose={() => {
            setEditExperience(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onComplete={() => {
            setEditExperience(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
        />
      </div>
    );
  }
  return (
    <div
      className="p-3 border-2 border-blue-100 rounded-md mb-5 flex flex-col md:flex-row"
      ref={scrollRef}
    >
      {(props.experience?.url || props.experience?.logo) && (
        <div className="w-14 mr-0 md:mr-3 mt-0 md:mt-3 mb-2 md:mb-0">
          <img
            alt={props.experience?.title}
            className="object-contain"
            src={
              props.experience?.logo
                ? props.experience.logo
                : `//logo.clearbit.com/${props.experience?.url.replace(
                    '/(^\\w+:|^)\\/\\//',
                    ''
                  )}?size=80`
            }
          />
        </div>
      )}
      <div className="flex flex-1 flex-col">
        <div className="flex justify-between items-center">
          <h4 className="font-bold text-lg">{props.experience?.title}</h4>
          <Button
            content="Edit"
            backgroundColor="white"
            textColor="gray"
            link
            customClasses="pb-0 pt-0 pl-0 pr-0"
            onClick={() => {
              setEditExperience(true);
            }}
          />
        </div>
        <p className="text-sm pb-2">
          {props.experience?.company} • {props.experience?.location}
        </p>
        <p className="text-blue-muted text-sm pb-2">
          {format(new Date(props.experience?.start_date * 1000), 'MMM yyyy')} to{' '}
          {props.experience?.end_date && !props.experience?.current_role
            ? format(new Date(props.experience?.end_date * 1000), 'MMM yyyy')
            : 'Present'}
        </p>
        <p
          className={`text-sm pb-2 transition-all ease-linear overflow-hidden ${
            showMore ? 'overflow-y-auto overflow-x-hidden max-h-64' : 'max-h-20'
          }`}
        >
          {showMore || props.experience?.highlight1?.length < 137
            ? props.experience?.highlight1
            : `${props.experience?.highlight1?.substr(
                0,
                props.experience?.highlight1.lastIndexOf(' ', 137)
              )}...`}
          {props.experience?.highlight1?.length > 137 && (
            <span className="cursor-pointer ml-1 font-bold" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Less' : 'More'}
            </span>
          )}
        </p>
        <p className="text-sm font-bold">{props.experience?.skills?.join(', ')}</p>
      </div>
    </div>
  );
};
