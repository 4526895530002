import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import { useRootStore } from '../providers/RootStoreProvider';
import { Job } from '../components/Job/Job';
import { NoResults } from '../components/Notifications/NoResults';
import { LoadingItems } from '../components/Notifications/LoadingItems';
import { useQuery } from '../utils';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';

export const JobPage: FC = observer(() => {
  const {
    jobStore: { job, fetchSingleJob },
    myDevsStore: { selectedDev },
  } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [videoAskContactUid, setVideoAskContactUid] = useState<string>('');
  const [videoAskQuestionUid, setVideoAskQuestionUid] = useState<string>('');

  const { uid, developerUid } = useParams<{ uid: string; developerUid: string }>();
  const query = useQuery();

  useEffect(() => {
    const isVideoAskMessage = (message: any) =>
      message.origin === 'https://www.videoask.com' &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith('videoask_');

    window.addEventListener('message', (message) => {
      if (!isVideoAskMessage(message)) {
        return;
      }

      if (message.data?.type === 'videoask_question_submitted') {
        setVideoAskContactUid(message.data?.contactId);
        setVideoAskQuestionUid(message.data?.questionId);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedDev) {
      fetchSingleJob(uid, selectedDev?.uid).then(() => {
        setIsLoading(false);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDev]);

  return (
    <div className="w-full">
      <h2 className="text-3xl text-bold mb-5">Job Details</h2>
      <DeveloperSelector selected={developerUid} />
      {isLoading && <LoadingItems count={1} />}
      {!isLoading && !job?.uid && (
        <NoResults message={"We couldn't find the job you're looking for!"} />
      )}
      {job?.uid && (
        <div>
          <Job
            videoAskContactUid={videoAskContactUid}
            videoAskQuestionUid={videoAskQuestionUid}
            data={job}
            singleJobVersion
            interested={query.get('interested') !== 'false'}
          />
        </div>
      )}
    </div>
  );
});
