import { FC, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { observer } from 'mobx-react';
import { AiOutlineLoading } from 'react-icons/ai';
import { useRootStore } from '../../providers/RootStoreProvider';
import {
  DEFAULT_DAYS_UNAVAILABLE,
  DAYS_UNAVAILABLE,
  TAvailabilityStatus,
} from '../../interfaces/user';
import { IDevItem } from '../../interfaces/mydevs';

const AVAILABILITY_MAP: { [key: string]: string } = {
  1: '✅ Available',
  0: '🚫 Not Available',
};

const options = [1, 0];

type TDeveloperAvailability = {
  developer: IDevItem;
};

export const DeveloperAvailability: FC<TDeveloperAvailability> = observer(
  (props: TDeveloperAvailability) => {
    const {
      myDevsStore: { updateAvailability, availabiltyUpdatedDevs },
    } = useRootStore();
    const { developer } = props;

    const handleClick = (option: TAvailabilityStatus) => () => {
      updateAvailability(
        {
          availability: option,
          days_unavailable: DEFAULT_DAYS_UNAVAILABLE.days,
          available_from: '',
        },
        developer.uid
      );
    };

    const Loading = () => (
      <>
        <span className="animate-spin">
          <AiOutlineLoading />
        </span>
        <span>Updating...</span>
      </>
    );

    const menuItems = options.map((option) => (
      <Menu.Item key={option}>
        {({ active }) => (
          <button
            type="button"
            className={`
            py-1 px-2 rounded text-left w-full
            ${active && 'bg-blue-300'}
          `}
            onClick={handleClick(option as TAvailabilityStatus)}
          >
            {AVAILABILITY_MAP[option]}
          </button>
        )}
      </Menu.Item>
    ));

    const handleNotAvailableOptionClick = (days: number) => () => {
      let date: string | Date = '';
      if (days !== DEFAULT_DAYS_UNAVAILABLE.days) {
        date = new Date();
        date.setDate(date.getDate() + days);
      }

      updateAvailability(
        {
          availability: developer.available ?? 0,
          available_from: date instanceof Date ? date.toISOString() : '',
          days_unavailable: days,
        },
        developer.uid
      );
    };

    const notAvailableOptions = [...DAYS_UNAVAILABLE].map(([days, dayObject]) => (
      <Menu.Item key={`days-${days}`}>
        {({ active }) => (
          <button
            type="button"
            className={`
            py-1 px-2 rounded text-left w-full
            ${active ? 'bg-blue-300' : ''}
          `}
            onClick={handleNotAvailableOptionClick(days)}
          >
            {dayObject?.label}
          </button>
        )}
      </Menu.Item>
    ));

    return (
      <div>
        <Menu as="div" className="relative text-center w-full px-2">
          {({ open }) => (
            <>
              <Menu.Button className="inline-flex items-center bg-white rounded p-1 hover:bg-gray-50 w-full justify-between">
                {availabiltyUpdatedDevs.includes(developer.uid) ? (
                  <Loading />
                ) : (
                  AVAILABILITY_MAP[developer.available ?? 0]
                )}
                <span className="text-xl ml-4">{open ? <FiChevronDown /> : <FiChevronUp />}</span>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`
                flex flex-col
                absolute top-full right-2 left-2
                my-2 border rounded bg-white z-20
              `}
                >
                  {menuItems}
                </Menu.Items>
              </Transition>
            </>
          )}
        </Menu>

        {developer.available === 0 && (
          <>
            <span className="px-2 mt-3 inline-block text-small font-mediun text-black">
              Will be available in
            </span>
            <Menu as="div" className="relative text-center w-full px-2">
              {({ open }) => (
                <>
                  <Menu.Button className="inline-flex items-center bg-white rounded p-1 hover:bg-gray-50 w-full justify-between">
                    {availabiltyUpdatedDevs.includes(developer.uid) ? (
                      <Loading />
                    ) : (
                      <>📅 {DAYS_UNAVAILABLE.get(developer.days_unavailable ?? 0)?.label}</>
                    )}
                    <span className="text-xl ml-4">
                      {open ? <FiChevronDown /> : <FiChevronUp />}
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      className={`
                    flex flex-col
                    absolute top-full right-2 left-2
                    my-2 border rounded bg-white z-20
                  `}
                    >
                      {notAvailableOptions}
                    </Menu.Items>
                  </Transition>
                </>
              )}
            </Menu>
          </>
        )}
      </div>
    );
  }
);
