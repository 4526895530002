import { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { FULL_TIME, PART_TIME, IDevItem } from '../../interfaces/mydevs';

type TNewDevRowProps = {
  developer: IDevItem;
  selectDev: () => void;
};

export const WorkingDevRow: FC<TNewDevRowProps> = (props: TNewDevRowProps) => {
  const history = useHistory();
  const {
    developer: { name, availability, rate, job_name, job_status },
    selectDev,
  } = props;

  const availableTracker = (availability: string) => {
    if (availability === FULL_TIME) return 'Full-time';
    if (availability === PART_TIME) return 'Part-time';

    return '-';
  };

  const sendToMyBills = () => {
    selectDev();
    history.push('/bills');
  };

  const sendToProfile = () => {
    selectDev();
    history.push('/profile');
  };

  const getJobStatus = () => {
    return (
      <div className="font-bold text-blue-500 cursor-pointer" onClick={sendToMyBills}>
        <span>{job_status.substring(0, 8)}</span>
        <span className="text-xs">{job_status.substring(8, job_status.length)}</span>
      </div>
    );
  };

  return (
    <div className="grid grid-cols-4 sm:grid-cols-12 p-1 border-b border-gray-200 py-2 hover:bg-blue-100">
      <div
        className="col-span-2 sm:col-span-4 align-middle ml-4 text-blue-500 cursor-pointer"
        onClick={sendToProfile}
      >
        {name}
      </div>
      <div className="sm:col-span-2">{availableTracker(availability)}</div>
      <div className="sm:col-span-1">${rate}</div>
      <div className="sm:col-span-2">{job_name}</div>
      <div className="sm:col-span-3">
        <span>{getJobStatus()}</span>
      </div>
    </div>
  );
};
