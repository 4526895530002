export interface IAddDeveloperRequestPayload {
  user_data: IUserData;
  developer_data: IDeveloperData;
  skills: ISkill[];
  employment: IExperienceItem[];
  education: IEducation[];
  portfolio: IPortfolio[];
}

export interface IUserData {
  name: string;
  email: string;
  personal_email: string;
  password: string;
  skype: string;
  phone: string;
  linked_in: string;
  github: string;
  country: string;
  city: string;
  photo: string;
  resume: File | null;
  resume_name: string | null;
  resume_url: string | null;
  applied_for: {
    title: string;
    uid: string;
    years: string;
  };
}

export interface IDeveloperData {
  rate: string;
  bio: string;
  available?: number | string;
  availability?: number | string;
  available_from: string;
  days_unavailable?: number | string;
}

export interface ISkill {
  name: string;
  years: number;
}

export interface IExperience {
  company: string;
  logo: string;
  url: string;
  location: string;
  role: string;
  from: number;
  until: number;
  description: string;
  skills: string[];
  current_role?: boolean;
}

export interface IExperiencePayload extends IExperience {
  highlights: string[];
}
export interface IExperienceItem extends IExperience {
  highlight1: string;
  highlight2: string;
  highlight3: string;
}

export interface IEducation {
  institution: string;
  location: string;
  from: number;
  until: number;
  description: string;
  current?: boolean;
}

export interface IPortfolio {
  role: string;
  title: string;
  link: string;
  description: string;
  image: string;
}

export const emptyAddDeveloperPayload = {
  user_data: {
    name: '',
    email: '',
    password: '',
    personal_email: '',
    skype: '',
    linked_in: '',
    toptal: '',
    github: '',
    phone: '',
    country: '',
    city: '',
    photo: '',
    applied_for: { title: '', uid: '', years: '' },
    resume: null,
    resume_name: null,
    resume_url: null,
  },
  skills: [],
  developer_data: {
    bio: '',
    available: 1,
    days_unavailable: '',
    available_from: '',
    availability: '',
    rate: '',
  },
  education: [],
  employment: [],
  portfolio: [],
};
