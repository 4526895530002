import React, { FC, useRef, useState } from 'react';
import { Button } from '@upstackhq/component-library';
import { ShowcaseForm } from '../Forms/ShowcaseForm';

type props = {
  showcase: {
    uid: string;
    title: string;
    url: string;
    description: string;
    thumb: string;
    location: string;
  };
};
export const ShowcaseInfoBox: FC<props> = (props) => {
  const [showMore, setShowMore] = useState<boolean>(false);
  const [editShowcase, setEditShowcase] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (editShowcase) {
    return (
      <div className="mb-5">
        <ShowcaseForm
          showcase={props.showcase}
          onClose={() => {
            setEditShowcase(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onComplete={() => {
            setEditShowcase(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
        />
      </div>
    );
  }
  return (
    <div className="p-3 border-2 border-blue-100 rounded-md mb-5 flex" ref={scrollRef}>
      <div className="w-1/4 mr-5">
        <img
          className="object-cover h-44 w-full"
          src={props.showcase?.thumb}
          alt={props.showcase?.title}
        />
      </div>
      <div className="w-3/4">
        <div className="flex justify-between items-center pb-2">
          <h4 className="font-bold text-lg">{props.showcase?.title}</h4>
          <Button
            content="Edit"
            backgroundColor="white"
            textColor="gray"
            link
            customClasses="pb-0 pt-0 pl-0 pr-0"
            onClick={() => {
              setEditShowcase(true);
            }}
          />
        </div>
        <p
          className={`text-sm pb-2 transition-all ease-linear overflow-hidden ${
            showMore ? 'overflow-y-auto overflow-x-hidden max-h-64' : 'max-h-20'
          }`}
        >
          {showMore || props.showcase?.description.length < 137
            ? props.showcase?.description
            : `${props.showcase?.description?.substr(
                0,
                props.showcase?.description.lastIndexOf(' ', 137)
              )}...`}
          {props.showcase?.description.length > 137 && (
            <span className="cursor-pointer ml-1 font-bold" onClick={() => setShowMore(!showMore)}>
              {showMore ? 'Less' : 'More'}
            </span>
          )}
        </p>
      </div>
    </div>
  );
};
