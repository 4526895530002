import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useParams } from 'react-router-dom';
import { useRootStore } from '../providers/RootStoreProvider';
import { Loading } from '../components/Notifications/Loading';

export const Impersonate = () => {
  const { userStore } = useRootStore();
  const [moveAway, setMoveAway] = useState<boolean>(false);
  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (!token) return;

    userStore.impersonate(token).then(() => {
      setMoveAway(true);
    });
  }, [token, userStore]);

  return (
    <>
      {moveAway && <Redirect to="/" />}
      <Loading />
    </>
  );
};
