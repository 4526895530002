import { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useRootStore } from '../providers/RootStoreProvider';
import { NoResults } from '../components/Notifications/NoResults';
import { LoadingItems } from '../components/Notifications/LoadingItems';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';
import { Badge } from '../components/Badge/Badge';

type props = {
  type: string;
};
export const BadgesPage: FC<props> = observer((props) => {
  const {
    badgesStore: {
      fetchDeveloperBadges,
      developerAssignedBadges,
      developerAvailableBadges,
      setStartedBadgeUid,
    },
    myDevsStore: { selectedDev },
  } = useRootStore();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [videoAskContactUid, setVideoAskContactUid] = useState<string>('');
  const [videoAskQuestionUid, setVideoAskQuestionUid] = useState<string>('');

  useEffect(() => {
    if (!selectedDev) return;

    setIsLoading(true);
    setStartedBadgeUid(null);
    fetchDeveloperBadges(selectedDev?.uid).then(() => setIsLoading(false));

    const isVideoAskMessage = (message: any) =>
      message.origin === 'https://www.videoask.com' &&
      message.data &&
      message.data.type &&
      message.data.type.startsWith('videoask_');

    window.addEventListener('message', (message) => {
      if (!isVideoAskMessage(message)) {
        return;
      }

      if (message.data?.type === 'videoask_question_submitted') {
        setVideoAskContactUid(message.data?.contactId);
        setVideoAskQuestionUid(message.data?.questionId);
      }
    });
  }, [selectedDev, fetchDeveloperBadges, setStartedBadgeUid]);

  const renderBadges = () => {
    switch (props.type) {
      case 'assigned':
        return developerAssignedBadges.map((developerAssignedBadge) => (
          <Badge
            key={developerAssignedBadge.uid}
            data={developerAssignedBadge}
            videoAskQuestionUid={videoAskQuestionUid}
            videoAskContactUid={videoAskContactUid}
          />
        ));
      case 'available':
        return developerAvailableBadges.map((developerAvailableBadge) => (
          <Badge
            key={developerAvailableBadge.uid}
            data={developerAvailableBadge}
            videoAskQuestionUid={videoAskQuestionUid}
            videoAskContactUid={videoAskContactUid}
          />
        ));
      default:
        return developerAssignedBadges.map((developerAssignedBadge) => (
          <Badge
            key={developerAssignedBadge.uid}
            data={developerAssignedBadge}
            videoAskQuestionUid={videoAskQuestionUid}
            videoAskContactUid={videoAskContactUid}
          />
        ));
    }
  };

  return (
    <div className="w-full">
      <h2 className="text-3xl text-bold mb-5">Interviews</h2>

      <DeveloperSelector selected={selectedDev?.uid} />

      {isLoading && <LoadingItems count={3} />}

      {!isLoading && developerAssignedBadges.length === 0 && <NoResults />}

      {!isLoading && developerAssignedBadges.length > 0 && (
        <div id="developerBadges">
          <p className="mb-3 flex flex-row items-center">
            <small className="text-gray-500">
              Here is a list of all of the available developer&#039;s interviews.
            </small>
            &nbsp;
          </p>

          <div className="flex flex-wrap w-full">{renderBadges()}</div>
        </div>
      )}
    </div>
  );
});
