import React, { FC, useRef, useState } from 'react';
import { format } from 'date-fns';
import { Button } from '@upstackhq/component-library';
import { EducationForm } from '../Forms/EducationForm';
import { IEducation } from '../../../interfaces/developers';

type props = {
  education: IEducation;
  onRemove?: Function;
  onEdit?: Function;
  index: number;
};
export const EducationInfoBox: FC<props> = (props) => {
  const [editEducation, setEditEducation] = useState<boolean>(false);
  const scrollRef = useRef<HTMLDivElement>(null);

  if (editEducation) {
    return (
      <div className="mb-5">
        <EducationForm
          index={props.index}
          education={props.education}
          onClose={() => {
            setEditEducation(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onComplete={(data: IEducation) => {
            if (props.onEdit) {
              props.onEdit(data, props.index);
            }
            setEditEducation(false);
            setTimeout(() => {
              scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
            }, 100);
          }}
          onRemove={(index: number) => {
            if (props.onRemove) {
              props.onRemove(index);
            }
          }}
        />
      </div>
    );
  }

  return (
    <div className="p-3 border-2 border-blue-100 rounded-md mb-5" ref={scrollRef}>
      <div className="flex justify-between items-center">
        <h4 className="font-bold text-lg">{props.education?.description}</h4>
        <Button
          content="Edit"
          backgroundColor="white"
          textColor="gray"
          link
          customClasses="pb-0 pt-0 pl-0 pr-0"
          onClick={() => {
            setEditEducation(true);
          }}
        />
      </div>
      <p className="text-sm pb-2">
        {props.education?.institution} •{props.education?.location}
      </p>
      <p className="text-blue-muted text-sm pb-2">
        {props.education?.from
          ? format(
              new Date(
                typeof props.education?.from === 'number'
                  ? props.education?.from * 1000
                  : props.education?.from
              ),
              'MMM yyyy'
            )
          : 'Unknown'}{' '}
        to{' '}
        {props.education?.until
          ? format(
              new Date(
                typeof props.education?.until === 'number'
                  ? props.education?.until * 1000
                  : props.education?.until
              ),
              'MMM yyyy'
            )
          : 'Present'}
      </p>
    </div>
  );
};
