import { FC } from 'react';
import { observer } from 'mobx-react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Input } from '@upstackhq/component-library';
import { toast } from 'react-toastify';
import { useRootStore } from '../../providers/RootStoreProvider';
import { IUpdateSocialSectionRequest } from '../../interfaces/profile';

type props = {};
export const SocialSection: FC<props> = observer((props) => {
  const {
    profileStore: {
      profileData: { social },
      updateSocialSection,
      saving,
      loading,
    },
  } = useRootStore();

  const validationSchema = yup.object().shape({
    linked_in: yup.string().required('Linkedin is required'),
    github: yup.string(),
    twitter: yup.string(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmitHandler = (payload: IUpdateSocialSectionRequest) => {
    updateSocialSection(payload).then(() => {
      toast.success('Profile successfully updated.');
    });
  };

  const { onChange: onLinkedinChange, ref: linkedinRef } = register('linked_in');
  const { onChange: onGithubChange, ref: githubRef } = register('github');
  const { onChange: onTwitterChange, ref: twitterRef } = register('twitter');

  return (
    <div>
      <h2 className="mb-2 text-2xl sm:text-3xl font-bold tracking-wide">Social profiles</h2>
      {social && (
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Input
            onChange={onLinkedinChange}
            inputRef={linkedinRef}
            name="linked_in"
            label="Linkedin"
            required
            defaultValue={social?.linked_in}
            error={errors.linkedin?.message}
          />
          <Input
            onChange={onGithubChange}
            inputRef={githubRef}
            name="github"
            label="GitHub"
            defaultValue={social?.github}
            error={errors.github?.message}
          />
          <Input
            onChange={onTwitterChange}
            inputRef={twitterRef}
            name="twitter"
            label="Twitter"
            defaultValue={social?.twitter}
            error={errors.twitter?.message}
          />
          <div className="flex justify-end mt-5">
            <Button
              type="submit"
              content="Save"
              uppercase
              backgroundColor="green"
              textColor="white"
              bold
              isLoading={saving.social}
              disabled={loading || saving.social}
              noLockIcon
            />
          </div>
        </form>
      )}
    </div>
  );
});
