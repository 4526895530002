import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { CodeIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { Dropdown } from '@upstackhq/component-library';
import { Select } from '../../../common/Select/Select';
import { ISkillItem } from '../../../interfaces/selector';
import { uniqBy } from '../../../utils';

const MAX_YEARS = 20;

type props = {
  onFinish: Function;
  onRemove: Function;
  skills: ISkillItem[];
  data: SkillData;
  clean?: boolean;
};

type SkillData = {
  name: string;
  years: number;
};

export const SkillForm: FC<props> = ({ onRemove, onFinish, skills, data, clean }) => {
  const [skill, setSkill] = useState<string>('');
  const [years, setYears] = useState<number>(data.years);

  const prev = useRef({ skill, years });

  useEffect(() => {
    const skillName = skill || data.name;
    const { skill: prevSkill, years: prevYears } = prev.current;

    if (!skillName.length) return;
    if (prevSkill === skillName && prevYears === years) return;

    prev.current = { skill: skillName, years };
    onFinish({ name: skillName, years });
  }, [data, skill, years]);

  const handleSelectYear = (year: string) => {
    setYears(+year);
  };

  const handleSelectSkill = (skills: { title: string }[]) => {
    const skill = skills.length ? skills[0]?.title : '';
    setSkill(skill);
  };

  const selectedSkill = useMemo(() => {
    if (skill.length) return [{ title: skill }];
    if (data.name.length) return [{ title: data.name }];

    return [];
  }, [data, skill]);

  const yearOptions = [...Array(MAX_YEARS).keys()].map((i) => {
    const year = i + 1;
    const yearsText = `${year} year${year > 1 ? 's' : ''}`;

    return (
      <option key={yearsText} value={year}>
        {yearsText}
      </option>
    );
  });

  const uniqSkills = uniqBy(
    skills.map((skill) => ({ title: skill.name })),
    'title'
  );

  return (
    <div className="flex flex-row relative w-full">
      <div className="flex flex-col md:flex-row items-start w-full mb-5 md:mb-0">
        <Dropdown
          limit={1}
          icon={<CodeIcon className="w-6 h-6 text-blue-400" />}
          items={uniqSkills}
          customClasses="w-full mb-4 md-mb-0 md:w-8/12 mr-3"
          selected={selectedSkill}
          onChange={handleSelectSkill}
        />
        <Select
          name="experience"
          placeholder="Select years"
          customClasses="md:w-4/12"
          onChange={handleSelectYear}
          defaultValue={years}
        >
          {yearOptions}
        </Select>
      </div>

      <XIcon
        className={`${
          clean ? 'invisible' : ''
        } self-center ml-5 md:absolute md:-right-7 md:top-3.5 md:mt-1 text-gray-500 w-4 h-4 cursor-pointer`}
        onClick={() => {
          !clean && onRemove();
        }}
      />
    </div>
  );
};

SkillForm.defaultProps = { clean: false };
