import { FC } from 'react';
import { Pagination } from '@upstackhq/component-library';
import { DEVS_PER_PAGE, IDevsPagination } from '../../interfaces/mydevs';
import { WorkingDevLoadingRows } from './WorkingDevLoadingRows';

type TProps = {
  error: string;
  fetchDevs(page?: number): void;
  loading: boolean;
  pagination: IDevsPagination;
  rows: JSX.Element[];
};

export const WorkingDevsTable: FC<TProps> = (props: TProps) => {
  const { error, fetchDevs, loading, pagination, rows } = props;

  const isFirstPage = pagination.currentPage === 1;
  const isLastPage = pagination.currentPage === pagination.totalPages;

  const title = <h3 className="flex flex-col mb-2 text-2xl font-bold">Working Devs</h3>;

  return (
    <section className="w-full flex flex-col mt-8 mb-8">
      {title}
      <div className="w-full grid border border-gray-200 rounded">
        <div className="grid grid-cols-4 sm:grid-cols-12 p-1 text-lg font-bold border-b border-gray-200">
          <div className="col-span-2 sm:col-span-4 align-middle items-center ml-4">Name</div>
          <div className="sm:col-span-2">Type</div>
          <div className="sm:col-span-1">Rate</div>
          <div className="sm:col-span-2">Job Name</div>
          <div className="sm:col-span-3 ml-3">Status</div>
        </div>
        {loading ? (
          <WorkingDevLoadingRows count={DEVS_PER_PAGE} />
        ) : error.length ? (
          <div className="p-3 inline-flex font-bold bg-red-50 items-center justify-center">
            {error}
          </div>
        ) : rows.length ? (
          rows
        ) : (
          <div className="p-3 inline-flex font-bold bg-gray-100 items-center justify-center">
            No working developers to display
          </div>
        )}
      </div>
      {pagination.totalPages > 1 && (
        <Pagination
          isFirst={isFirstPage}
          isLast={isLastPage}
          currentPage={pagination.currentPage}
          totalPages={pagination.totalPages}
          handleChangePage={fetchDevs}
        />
      )}
    </section>
  );
};
