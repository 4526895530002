import React, { FC, ReactNode } from 'react';

type TLoadingItem = {
  count?: number;
};
export const LoadingItems: FC<TLoadingItem> = (props) => {
  const itemsCount: number = props.count ? props.count : 1;
  const itemsArray: ReactNode[] = [];
  for (let i = 0; i < itemsCount; i++) {
    itemsArray.push(
      <div key={`loading-item-${i}`} className="w-full border border-gray-100 rounded-md p-4 mb-4">
        <div className="animate-pulse flex space-x-4">
          <div className="rounded-full bg-gray-300 h-12 w-12" />
          <div className="flex-1 space-y-4 py-1">
            <div className="h-4 bg-gray-300 rounded w-3/4" />
            <div className="space-y-2">
              <div className="h-4 bg-gray-300 rounded" />
              <div className="h-4 bg-gray-300 rounded w-5/6" />
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div className="w-full flex flex-col">{itemsArray.map((item: ReactNode) => item)}</div>;
};
