import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { API_URL } from '../constants';
import {
  defaultGetDeveloperBadgesResponse,
  IGetDeveloperBadgesResponse,
  IStartDeveloperBadgePayload,
} from '../interfaces/badge';

const badgesService = {
  getDeveloperBadges: async (developerUid: string) => {
    try {
      const res: AxiosResponse<IGetDeveloperBadgesResponse> = await axios.get(
        `${API_URL}/agency/badges?developer_uid=${developerUid}`
      );

      return res.data;
    } catch (e: any) {
      const msg = e?.response?.data.error.message ?? e?.message ?? 'Unknown error';
      toast.error(`Error starting developer badge! ${msg}`);
      return defaultGetDeveloperBadgesResponse;
    }
  },
  startDeveloperBadge: async (payload: IStartDeveloperBadgePayload) => {
    try {
      const res: AxiosResponse = await axios.post(`${API_URL}/developer/v2/badges`, payload);
      return res.status === 200;
    } catch (e) {
      toast.error(`Error starting developer badge!`);
      return false;
    }
  },
};

export default badgesService;
