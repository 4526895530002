import axios, { AxiosResponse } from 'axios';

import { API_URL } from '../constants';
import {
  defaultTimesheetsResponse,
  ITimesheetsGetPayload,
  ITimesheetsJob,
  ITimesheetsPayloadUpload,
  ITimesheetsResponse,
  ITimesheetUpdateResponse,
} from '../interfaces/timesheets';
import { sortJobs } from '../utils';

class TimesheetsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'TimesheetsError';
  }
}

const timesheetsService = {
  getTimesheets: async (payload: ITimesheetsGetPayload) => {
    try {
      let res: AxiosResponse<ITimesheetsResponse> = await axios.post(
        `${API_URL}/agency/time-sheets`,
        payload
      );

      res = res ?? defaultTimesheetsResponse;
      const jobs = sortJobs(res.data.jobs) as ITimesheetsJob[];

      return { ...res.data, jobs };
    } catch (e: any) {
      if (e?.name === 'TimesheetsError') {
        throw new Error(e?.message);
      }
      throw new Error('Could not retrieve timesheet data');
    }
  },

  postTimesheets: async (payload: ITimesheetsPayloadUpload) => {
    try {
      const res: AxiosResponse<ITimesheetUpdateResponse> = await axios.post(
        `${API_URL}/agency/time-sheets/update`,
        payload
      );

      if (res.data.error?.message?.length) {
        throw new TimesheetsError(res.data.error.message);
      }

      return res.data;
    } catch (e: any) {
      if (e?.name === 'TimesheetsError') {
        throw new Error(e?.message);
      }

      throw new Error('Could not send timesheet data');
    }
  },
};

export default timesheetsService;
