import 'react-toastify/dist/ReactToastify.css';
import './main.css';

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import React from 'react';
import ReactDOM from 'react-dom';
import { ToastContainer } from 'react-toastify';
import { RootStoreProvider } from './providers/RootStoreProvider';
import App from './App';
import { publicRecaptchaKey } from './utils';

require('dotenv').config();

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey={publicRecaptchaKey} scriptProps={{ async: true }}>
      <RootStoreProvider>
        <App />
        <ToastContainer theme="colored" />
      </RootStoreProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
