import { GrStatusUnknown } from 'react-icons/all';
import FullTimeIcon from './assets/img/full-time.svg';
import PartTimeIcon from './assets/img/part-time.svg';
import TimezoneIcon from './assets/img/time-zone.svg';
import VCFundedIcon from './assets/img/vc-funded.svg';

export const API_URL =
  process.env.REACT_APP_HOST_TYPE === 'local'
    ? 'http://127.0.0.1:5000'
    : process.env.REACT_APP_HOST_TYPE === 'dev'
    ? 'https://dev-api.upstack.co'
    : 'https://api.upstack.co';

export const STORAGE_NAME = 'agencyPortal';
export const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ');
export const ROUTE_WRAPPER_ID = 'routeWrapper';
export const notAvailableCurrentlyAnswer = 'I am not available currently';

export const linkRegex =
  /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_/0-9\-#.]*)\??([a-z_/0-9\-#=&]*)/;
export const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const linkedInRegex =
  /^(https:\/\/)((www|\w\w)\.)?linkedin\.com\/((([\w]{2,3})?)|([^/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;

export const menuMap = (stub: string) => {
  switch (stub) {
    case 'job-board':
      return '🔥';
    // case 'job-board/interested': return '✅'
    // case 'job-board/not-interested': return '❌'
    case 'profile':
      return '🤓';
    case 'badges':
      return '📛';
    case 'university':
      return '🚌';
    case 'timesheets':
      return '⌛';
    case 'bills':
      return '🤑';
    case 'payments':
      return '💸';
    case 'contact-us':
      return '📟';
    case 'interviews':
      return '🖥';
    case 'partner-program':
      return '❤️';
    case 'my-devs':
      return '⚛️';
  }
};

export const colorMap = (key: string) => {
  switch (key) {
    case 'FULL_TIME':
    case 'ZOOM':
    case 'GGL':
    case 'VIDEO':
      return 'green';
    case 'PART_TIME':
    case '10MIN':
    case '30MIN':
    case '1HOUR':
      return 'yellow';
    case 'TIMEZONE':
    case 'COMM':
      return 'indigo';
    case 'VC_FUNDED':
      return 'yellow';
    default:
      return 'gray';
  }
};
export const iconMap = (key: string, classes: string = '') => {
  switch (key) {
    case 'FULL_TIME':
      return <img src={FullTimeIcon} className={classes} alt="Full Time" />;
    case 'PART_TIME':
      return <img src={PartTimeIcon} className={classes} alt="Part Time" />;
    case 'TIMEZONE':
      return <img src={TimezoneIcon} className={classes} alt="Timezone" />;
    case 'VC_FUNDED':
      return <img src={VCFundedIcon} className={classes} alt="VC Funded" />;
    default:
      return <GrStatusUnknown className={classes} />;
  }
};

export const countries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'Andorra',
  'Angola',
  'Antigua and Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas The',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bhutan',
  'Bolivia',
  'Bosnia and Herzegovina',
  'Botswana',
  'Brazil',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burma',
  'Burundi',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cape Verde',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Colombia',
  'Comoros',
  'Congo Democratic Republic of the',
  'Congo Republic of the',
  'Costa Rica',
  "Cote d'Ivoire",
  'Croatia',
  'Cuba',
  'Curacao',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'East Timor (see Timor-Leste)',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Gabon',
  'Gambia The',
  'Georgia',
  'Germany',
  'Ghana',
  'Greece',
  'Grenada',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea North',
  'Korea South',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Mauritania',
  'Mauritius',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Morocco',
  'Mozambique',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'Netherlands Antilles',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'North Korea',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestinian Territories',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Remote',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Kitts and Nevis',
  'Saint Lucia',
  'Saint Vincent and the Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome and Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad and Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Vietnam',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'United States of America',
];

export const statusMap: { name: string; value: number; description: string }[] = [
  { name: 'New', value: 0, description: 'Developer account has been created.' },
  {
    name: 'Ready',
    value: 1,
    description:
      'Developer profile is ready and will be matched with existing Jobs on Upstack platform ',
  },
  {
    name: 'Application Review',
    value: 2,
    description: 'Developer profile is being reviewed by an Upstack talent manager',
  },
  {
    name: 'Initial Interview',
    value: 3,
    description: 'Upstack talent manager has invited the developer for initial interview.',
  },
  {
    name: 'Coding Challenge',
    value: 4,
    description: 'Developer has been invited for coding challenge with an Upstack interviewer',
  },
  {
    name: 'Profile Creation',
    value: 5,
    description: 'Developer profile is being created on Upstack platform',
  },
  {
    name: 'Archived',
    value: 6,
    description: "Developer did not match Upstack's expectations and his account is archived",
  },
  { name: 'Agreement Offer', value: 7, description: '' },
  { name: 'Awaiting profile approval', value: 9, description: '' },
];
