import { defaultGeneralError, IGeneralError } from './general';

export interface IUserMe {
  name: string;
  avatar: string;
  token: string;
  refreshToken: string;
}

export type TAvailabilityStatus = 0 | 1;

export interface IAvailability {
  fetched: boolean;
  loading: boolean;
}

export interface IAvailabilityRequest {
  availability: TAvailabilityStatus;
}

export interface IAvailabilityResponse {
  error: IGeneralError;
}

export interface IAuthResponse {
  error: IGeneralError;
  otp_challenge: boolean;
  token: string;
  refresh_token: string;
  name: string;
  email: string;
  role: string;
  avatar: string;
  section: string;
  enrolled: boolean;
  from_otp: boolean;
  data: IOtpQrCode;
  badges: string[];
  available: TAvailabilityStatus;
  has_payout_method_set?: boolean;
}

export interface IOtpQrCode {
  qr_image: any;
}

export interface IAuthRequest {
  username: string;
  password: string;
  scope: string;
}

export interface IForgotPasswordRequest {
  username: string;
  section: string;
}

export interface IResetPasswordRequest {
  reset_token: string;
  new_password: string;
  section: string;
}

/** * Empties ** */
export const defaultAuthResponse: IAuthResponse = {
  error: defaultGeneralError,
  otp_challenge: false,
  token: '',
  refresh_token: '',
  name: '',
  email: '',
  role: '',
  avatar: '',
  section: '',
  enrolled: false,
  from_otp: false,
  data: {
    qr_image: [],
  },
  badges: [],
  available: 1,
};

export const defaultAvailability: IAvailability = {
  fetched: false,
  loading: false,
};

export const DEFAULT_DAYS_UNAVAILABLE = { label: '1 week', days: 7 };

export const DAYS_UNAVAILABLE = new Map<number, { label: string; days: number }>([
  [7, DEFAULT_DAYS_UNAVAILABLE],
  [14, { label: '2 weeks', days: 14 }],
  [30, { label: '1 month', days: 30 }],
  [90, { label: '3 months', days: 90 }],
  [180, { label: '6 months', days: 180 }],
  [360, { label: '12 months', days: 360 }],
  [-1, { label: 'Never', days: -1 }],
]);
