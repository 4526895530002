import { IGeneralError, IPagination } from './general';

export const BILLS_PER_PAGE = 10;

export interface IBillsPagination {
  currentPage: number;
  totalPages: number;
}

export interface ICustomInvoiceResponseItem {
  id: number;
  name: string;
}

export interface IGetTimePeriodsResponse {
  custom_items: ICustomInvoiceResponseItem[];
  error: IGeneralError;
  time_periods: IJobTimePeriodResponse[];
}

export interface IGetBillsRequest {
  state: number;
  sort_field: string;
  sort_direction: string;
  page: number;
  per_page: number;
  developer_uid: string;
}

export interface IGetBillsResponse {
  error: IGeneralError;
  list: IBillResponseItem[];
  pagination: IPagination;
}

export interface IInvoiceItem {
  amount: number;
  description: string;
  id: number;
}

export interface ISubmitBillRequest {
  bill_custom_line_items?: IInvoiceItem[];
  developer_uid: string;
  time_periods_uuids?: string[];
  referral_uid?: string;
}

export interface ISubmitBillResponse {
  error: IGeneralError;
}

export interface IBillErrors {
  fetchBills: string;
  fetchTimePeriods: string;
  submit: string;
}

interface IBillItemCommon {
  pdf: string;
  number: string;
  state: number;
  state_literal: string;
  total: number;
  uid: string;
}

export interface IBillResponseItem extends IBillItemCommon {
  created_at: number;
}

export interface IBillItem extends IBillItemCommon {
  created_at: Date;
}

export interface IJobTimePeriodResponse {
  company_name: string;
  job_uid: string;
  time_periods: ITimePeriodResponseItem[];
  title: string;
}

export interface ITimePeriodResponseItem {
  can_bill: boolean;
  quantity: number;
  rate: number;
  period_status: {
    label: string;
  };
  total: number;
  uid: string;
  worked: string;
}

export interface ITimePeriodItem {
  canBill: boolean;
  hours: number;
  rate: number;
  status: string;
  total: number;
  uid: string;
  worked: string;
}

export interface ITimePeriodJob {
  company_name: string;
  job_uid: string;
  title: string;
}

export type TJobTimePeriodMap = Map<string, ITimePeriodItem[]>;

export const defaultSelectedJob = {
  company_name: '',
  job_uid: '',
  title: '',
};

export const defaultBillErrors = {
  fetchBills: '',
  fetchTimePeriods: '',
  submit: '',
};

export const defaultBillPagination = {
  currentPage: 1,
  totalPages: 1,
};

export const defaultGetBillsRequest = {
  state: -1,
  sort_field: 'created_at',
  sort_direction: 'desc',
  page: 1,
  per_page: BILLS_PER_PAGE,
  developer_uid: 'uid',
};

export const defaultCustomInvoiceItem = {
  id: -1,
  name: '',
};
