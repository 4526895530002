import { FC } from 'react';
import { FiEdit as EditIcon } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { TInvoiceStatus } from '../../interfaces/timesheets';

type TProps = {
  buildForm(): void;
  status: TInvoiceStatus;
};

export const InvoiceStatus: FC<TProps> = (props: TProps) => {
  const { buildForm, status } = props;

  if (status === null) {
    return (
      <div className="flex flex-wrap justify-center items-center text-black">
        Submitted
        <button className="ml-1 sm:ml-5" onClick={buildForm}>
          <span className="sr-only">Edit Invoice</span>
          <span className="inline">
            <EditIcon className="text-gray-500 h-full w-5" />
          </span>
        </button>
      </div>
    );
  }

  return <Link to="/bills">{status}</Link>;
};
