import React, { FC, useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams, Link } from 'react-router-dom';
import { InfoBox } from '@upstackhq/component-library';
import { useRootStore } from '../providers/RootStoreProvider';
import { capitalize } from '../utils';
import { AboutSection } from '../components/Profile/AboutSection';
import { SocialSection } from '../components/Profile/SocialSection';
import { SkillsSection } from '../components/Profile/SkillsSection';
import { ExperienceSection } from '../components/Profile/ExperienceSection';
import { EducationSection } from '../components/Profile/EducationSection';
import { ShowcaseSection } from '../components/Profile/ShowcaseSection';
import { Error } from '../components/Notifications/Error';
import { Loading } from '../components/Notifications/Loading';
import { DeveloperSelector } from '../components/DeveloperSelector/DeveloperSelector';
import { WorkSection } from '../components/Profile/WorkSection';

const sections = [
  { stub: 'about', title: 'About me' },
  { stub: 'social', title: 'Social profiles' },
  { stub: 'experience', title: 'Work Experience' },
  { stub: 'skills', title: 'Skills' },
  { stub: 'education', title: 'Education' },
  { stub: 'showcase', title: 'Showcase' },
  { stub: 'availability', title: 'Availability' },
];
export const ProfilePage: FC = observer(() => {
  const [selectedSection, setSelectedSection] = useState<number>(0);
  const { section } = useParams<{ section: string; developerUid: string }>();
  const history = useHistory();
  const {
    profileStore: { profileData, fetchProfileData, errorMessage, resetErrorMessage, loading },
    myDevsStore: { selectedDev },
  } = useRootStore();
  const [warningSection, setWarningSection] = useState<string>('');

  useEffect(() => {
    if (selectedDev) {
      fetchProfileData(selectedDev?.uid);
      setSelectedSection(0);
    }
  }, [fetchProfileData, selectedDev]);

  const parseNotCompletedSections = useCallback(() => {
    setWarningSection('');
    if (
      !profileData.about ||
      !profileData.about.first_name ||
      !profileData.about.last_name ||
      !profileData.about.email ||
      !profileData.about.headline ||
      !profileData.about.city ||
      !profileData.about.country ||
      !profileData.about.avatar
    ) {
      setWarningSection('about');
      return;
    }
    if (!profileData.social || !profileData.social.linked_in) {
      setWarningSection('social');
      return;
    }
    if (!profileData.skills || !profileData.skills.length) {
      setWarningSection('skills');
      return;
    }
    if (!profileData.skills || !profileData.experience.length) {
      setWarningSection('experience');
      return;
    }
    if (!profileData.skills || !profileData.education.length) {
      setWarningSection('education');
      return;
    }
    if (!profileData.skills || !profileData.showcase.length) {
      setWarningSection('showcase');
    }
  }, [profileData]);

  useEffect(() => {
    parseNotCompletedSections();
  }, [parseNotCompletedSections, profileData]);

  useEffect(() => {
    if (!section) return;

    if (sections.find((s) => s.stub === section)) {
      setSelectedSection(sections.findIndex((s) => s.stub === section));
    } else {
      history.push('/profile');
    }
  }, [history, section]);

  useEffect(() => {
    if (history) {
      window.history.replaceState(null, '', `/profile/${sections[selectedSection]?.stub}`);
    }
  }, [history, selectedSection, selectedDev]);

  const renderTabs = () => {
    return (
      <div className="w-full mt-4 rounded overflow-x-scroll overflow-y-hidden md:overflow-visible">
        <ul id="tabs" className="inline-flex w-full px-1 pt-2">
          {sections.map((section, index: number) => (
            <li
              key={section.stub}
              className={`
                px-4 py-2 font-semibold text-gray-800 rounded-t opacity-50 hover:opacity-100 cursor-pointer transition-all
                ${selectedSection === index ? 'border-blue-400 border-b-4 -mb-px opacity-100' : ''}
              `}
              onClick={() => setSelectedSection(index)}
            >
              {capitalize(section?.stub)}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderSection = () => {
    switch (sections[selectedSection]?.stub) {
      case 'about':
        return <AboutSection />;
      case 'availability':
        return <WorkSection />;
      case 'social':
        return <SocialSection />;
      case 'skills':
        return <SkillsSection />;
      case 'experience':
        return <ExperienceSection />;
      case 'education':
        return <EducationSection />;
      case 'showcase':
        return <ShowcaseSection />;
    }
  };

  return (
    <>
      <div className="w-full md:w-full lg:w-2/3 xl:w-6/12 2xl:5/12 lg:max-w-xl">
        <h2 className="text-3xl text-bold mb-3">Developer Profile</h2>
        <p className="mb-3 text-sm">
          Setup your developers Upstack profile, and land them a dream job! For any questions,
          please be in touch with your Talent manager.
        </p>
        <DeveloperSelector />
        {renderTabs()}
        {!loading && (
          <section className="mt-5">
            {profileData?.needs_approval && (
              <InfoBox title="Profile awaiting approval" type="warning">
                <p className="mb-3">
                  Changes have been saved but are NOT yet live. The UpStack team will review the
                  changes and approve shortly
                </p>
              </InfoBox>
            )}
            {warningSection && (
              <InfoBox title="Complete developer profile" type="warning">
                <p className="mb-3">
                  Part of the UpStack process is to create a world-class profile for your developers
                </p>
                <p>
                  🔥 <b>NEXT STEP:</b>{' '}
                  <Link to={`/profile/${warningSection}`}>
                    Complete {sections.find((s) => s.stub === warningSection)?.title}
                  </Link>
                </p>
              </InfoBox>
            )}
            {renderSection()}
          </section>
        )}
        {loading && <Loading />}
      </div>

      {errorMessage && (
        <Error
          title="Whoops, something went wrong"
          onClick={resetErrorMessage}
          mainMessage={
            errorMessage !== 'Internal server error' && errorMessage !== 'Invalid operation!'
              ? errorMessage
              : 'Something went wrong updating profile!'
          }
          subMessage="Please try again later"
        />
      )}
    </>
  );
});
